import moment from 'moment';

export const getASAPTime = (): Date => {
  const now = moment();
  if (now.get('hours') >= 16) {
    now.add(1, 'days');
    now.set('hour', 14);
    now.set('minute', 0);
    now.set('second', 0);
    now.set('millisecond', 0);
  } else if (now.get('hours') < 11) {
    now.set('hour', 14);
    now.set('minute', 0);
    now.set('second', 0);
    now.set('millisecond', 0);
  } else {
    now.add(1, 'days');
    now.set('hour', 10);
    now.set('minute', 0);
    now.set('second', 0);
    now.set('millisecond', 0);
  }
  return now.toDate();
};

export const getDeliveryTimeString = (deliveryTime: Date): string => {
  const month = deliveryTime.toLocaleString('default', { month: 'short' });
  const date = deliveryTime.getDate();
  const hours = deliveryTime.getHours();
  const timeString = `${month} ${date}, ${hours % 12 ? hours % 12 : 12} ${
    hours >= 12 ? 'pm' : 'am'
  } - ${(hours + 2) % 12 ? (hours + 2) % 12 : 12} ${
    hours + 2 >= 12 ? 'pm' : 'am'
  }`;
  return timeString;
};
