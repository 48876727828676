import React from 'react';
import { VendorCard } from '@components';
import { IVendor } from '@models';

interface Props {
  vendors: IVendor[];
}

export const VendorLists: React.FC<Props> = ({ vendors }) => {
  return (
    <div className="section">
      <div className="section-title text-center mb-6">Local Favorites</div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 my-2">
        {vendors
          .filter((vendor) => vendor.productPhotos)
          .filter((vendor) => vendor.title !== 'The Petite Flower')
          .filter((vendor) => vendor.title !== 'Pape Flower Market ')
          .slice(0, 8)
          .map((vendor) => (
            <VendorCard
              key={vendor.id}
              title={vendor.title}
              productImage={
                vendor.productPhotos && vendor.productPhotos.length
                  ? vendor.productPhotos[0]
                  : undefined
              }
            />
          ))}
      </div>
    </div>
  );
};
