import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Alert, Button, Input } from '@components';
import { CartMenu } from '@containers';
import {
  addUnitNumber,
  setCardNote,
  setDeliveryInstructions,
  setReceiver,
  setSender,
  setSpecialInstrunctions,
} from '@actions/checkout';
import {
  selectUser,
  selectProfile,
  selectDeliveryAddress,
  selectSender,
  selectReceiver,
  selectDeliveryInstructions,
  selectCardNote,
  selectSpecialInstructions,
} from '@selectors';

export const Checkout: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const profile = useSelector(selectProfile);
  const deliveryAddress = useSelector(selectDeliveryAddress);
  const sender = useSelector(selectSender);
  const receiver = useSelector(selectReceiver);
  const deliveryInstructions = useSelector(selectDeliveryInstructions);
  const cardNote = useSelector(selectCardNote);
  const specialInstructions = useSelector(selectSpecialInstructions);

  const [continueAsGuest, setContinueAsGuest] = useState<boolean>(false);
  const [guestEmail, setGuestEmail] = useState<string>('');
  const [alertVisible, setAlertVisible] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      senderFirstName: '',
      senderLastName: '',
      senderEmail: '',
      senderPhoneNumber: '',
      receiverFirstName: '',
      receiverLastName: '',
      receiverPhoneNumber: '',
      recepientDeliveryAddress: '',
      recepientAddressUnitNumber: '',
      deliveryInstructions: '',
      cardNote: '',
      specialInstructions: '',
    },
    validationSchema: Yup.object({
      senderFirstName: Yup.string().required('First Name is required'),
      senderLastName: Yup.string().required('Last Name is required'),
      senderEmail: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
      senderPhoneNumber: Yup.string().required('Phone Number is required'),
      receiverFirstName: Yup.string().required('First Name is required'),
      receiverLastName: Yup.string().required('Last Name is required'),
      receiverPhoneNumber: Yup.string().required('Phone Number is required'),
      recepientDeliveryAddress: Yup.string().required(
        'Please set the delivery address'
      ),
    }),
    onSubmit: (values) => {
      dispatch(
        setSender({
          firstName: values.senderFirstName,
          lastName: values.senderLastName,
          email: values.senderEmail,
          phone: values.senderPhoneNumber,
          senderID: profile?.id || '',
        })
      );
      dispatch(
        setReceiver({
          firstName: values.receiverFirstName,
          lastName: values.receiverLastName,
          phone: values.receiverPhoneNumber,
        })
      );
      dispatch(setDeliveryInstructions(values.deliveryInstructions));
      dispatch(setSpecialInstrunctions(values.specialInstructions));
      dispatch(setCardNote(values.cardNote));
      dispatch(addUnitNumber(values.recepientAddressUnitNumber));
      navigate('/payment');
    },
  });

  useEffect(() => {
    if (sender) {
      formik.setFieldValue('senderFirstName', sender.firstName);
      formik.setFieldValue('senderLastName', sender.lastName);
      formik.setFieldValue('senderEmail', sender.email);
      formik.setFieldValue('senderPhoneNumber', sender.phone);
    } else {
      if (profile) {
        formik.setFieldValue('senderFirstName', profile.firstName);
        formik.setFieldValue('senderLastName', profile.lastName);
        formik.setFieldValue('senderEmail', profile.email);
        formik.setFieldValue('senderPhoneNumber', profile.phone);
      } else {
        if (continueAsGuest && guestEmail) {
          formik.setFieldValue('senderEmail', guestEmail);
        }
      }
    }
    if (receiver) {
      formik.setFieldValue('receiverFirstName', receiver.firstName);
      formik.setFieldValue('receiverLastName', receiver.lastName);
      formik.setFieldValue('receiverPhoneNumber', receiver.phone);
    }
    if (deliveryAddress) {
      formik.setFieldValue(
        'recepientDeliveryAddress',
        deliveryAddress.description
      );
    }
    if (deliveryAddress?.line2) {
      formik.setFieldValue('recepientAddressUnitNumber', deliveryAddress.line2);
    }
    if (deliveryInstructions) {
      formik.setFieldValue('deliveryInstructions', deliveryInstructions);
    }
    if (cardNote) {
      formik.setFieldValue('cardNote', cardNote);
    }
    if (specialInstructions) {
      formik.setFieldValue('specialInstructions', specialInstructions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sender,
    receiver,
    deliveryAddress,
    profile,
    guestEmail,
    continueAsGuest,
    deliveryInstructions,
    cardNote,
    specialInstructions,
  ]);

  const goToLogin = () => {
    navigate('/login');
  };

  const goToSignUp = () => {
    navigate('/register');
  };

  const renderDeliveryInfo = () => {
    return renderDeliveryForm();
  };

  const handleContinueAsGuest = () => {
    if (!guestEmail) {
      setAlertVisible(true);
      return;
    }
    setContinueAsGuest(true);
  };

  const handleGuestEmailChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setGuestEmail(event.target.value);
  };

  const handleCloseAlert = () => {
    setAlertVisible(false);
  };

  const renderLoginForm = () => {
    return (
      <>
        <div className="py-2">
          <Input
            id="email"
            fullWidth
            placeholder="Email Address"
            value={guestEmail}
            onChange={handleGuestEmailChange}
          />
        </div>
        <div className="py-2">
          <Button
            label="Continue as guest"
            type="button"
            fullWidth
            onClick={handleContinueAsGuest}
          />
        </div>
        <div className="font-sans font-semibold text-xl text-primary-90 text-center my-2 md:my-4">
          or
        </div>
        <div className="py-2">
          <Button label="Log In" type="button" fullWidth onClick={goToLogin} />
        </div>
        <Button label="Sign Up" type="button" fullWidth onClick={goToSignUp} />
        <Alert
          visible={alertVisible}
          onClose={handleCloseAlert}
          title="Please input Email"
          showOverlay={true}
          onClickOk={handleCloseAlert}
        />
      </>
    );
  };

  const renderGuestForm = () => {
    return renderDeliveryForm();
  };

  const renderDeliveryForm = () => {
    return (
      <form onSubmit={formik.handleSubmit}>
        <div className="my-2">
          <div className="font-sans text-lg md:text-xl font-semibold text-black-100 my-2">
            Sending From
          </div>
          <div className="flex flex-col sm:flex-row">
            <div className="flex-1">
              <Input
                id="senderFirstName"
                caption="First Name*"
                fullWidth
                value={formik.values.senderFirstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.senderFirstName &&
                  formik.errors.senderFirstName
                    ? formik.errors.senderFirstName
                    : null
                }
              />
            </div>
            <div className="flex-1 ml-0 sm:ml-4">
              <Input
                id="senderLastName"
                caption="Last Name*"
                fullWidth
                value={formik.values.senderLastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.senderLastName && formik.errors.senderLastName
                    ? formik.errors.senderLastName
                    : null
                }
              />
            </div>
          </div>
          <div className="flex flex-col sm:flex-row mt-0 sm:mt-1">
            <div className="flex-1">
              <Input
                id="senderEmail"
                caption="Email*"
                fullWidth
                value={formik.values.senderEmail}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.senderEmail && formik.errors.senderEmail
                    ? formik.errors.senderEmail
                    : null
                }
              />
            </div>
            <div className="flex-1 ml-0 sm:ml-4">
              <Input
                id="senderPhoneNumber"
                caption="Phone Number*"
                fullWidth
                value={formik.values.senderPhoneNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.senderPhoneNumber &&
                  formik.errors.senderPhoneNumber
                    ? formik.errors.senderPhoneNumber
                    : null
                }
              />
            </div>
          </div>
        </div>
        <div className="my-2">
          <div className="font-sans text-lg md:text-xl font-semibold text-black-100 my-2">
            Delivering To
          </div>
          <div className="flex flex-col sm:flex-row">
            <div className="flex-1">
              <Input
                id="receiverFirstName"
                caption="First Name*"
                fullWidth
                value={formik.values.receiverFirstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.receiverFirstName &&
                  formik.errors.receiverFirstName
                    ? formik.errors.receiverFirstName
                    : null
                }
              />
            </div>
            <div className="flex-1 ml-0 sm:ml-4">
              <Input
                id="receiverLastName"
                caption="Last Name*"
                fullWidth
                value={formik.values.receiverLastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.receiverLastName &&
                  formik.errors.receiverLastName
                    ? formik.errors.receiverLastName
                    : null
                }
              />
            </div>
          </div>
          <div className="mt-0 sm:mt-1">
            <Input
              id="receiverPhoneNumber"
              caption="Phone Number*"
              fullWidth
              value={formik.values.receiverPhoneNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.receiverPhoneNumber &&
                formik.errors.receiverPhoneNumber
                  ? formik.errors.receiverPhoneNumber
                  : null
              }
            />
          </div>
        </div>
        <div className="my-2">
          <div className="font-sans text-lg md:text-xl font-semibold text-black-100 my-2">
            Recipients Address
          </div>
          <Input
            id="recipientsAddress"
            caption="Delivery Address*"
            fullWidth
            placeholder="What you typed in before"
            value={formik.values.recepientDeliveryAddress}
            disabled
            error={
              formik.touched.recepientDeliveryAddress &&
              formik.errors.recepientDeliveryAddress
                ? formik.errors.recepientDeliveryAddress
                : null
            }
          />
          <div className="mt-1">
            <Input
              id="recepientAddressUnitNumber"
              caption="Unit Number"
              fullWidth
              value={formik.values.recepientAddressUnitNumber}
              onChange={formik.handleChange}
            />
          </div>
        </div>
        <div className="my-4">
          <div className="font-sans text-lg sm:text-xl font-semibold text-black-100 my-1">
            Delivery Instructions
          </div>
          <div className="font-sans text-sm text-primary-90 leading-4 my-1">
            Please let us know if there are any special delivery instructions
            you would like us to follow. The more detail, the better!
          </div>
          <textarea
            id="deliveryInstructions"
            value={formik.values.deliveryInstructions}
            onChange={formik.handleChange}
            className="w-full font-sans text-md text-black-90 outline-none border border-gray-500 px-4 py-2 rounded-lg"
          />
        </div>
        <div className="my-4">
          <div className="font-sans text-lg sm:text-xl font-semibold text-black-100 my-1">
            Card Note
          </div>
          <div className="font-sans text-sm text-primary-90 leading-4 my-1">
            Please try to keep it short and sweet! Don't forget to add the names
            of those who are sending the flowers.
          </div>
          <textarea
            id="cardNote"
            value={formik.values.cardNote}
            onChange={formik.handleChange}
            className="w-full font-sans text-md text-black-90 outline-none border border-gray-500 px-4 py-2 rounded-lg"
          />
        </div>
        <div className="my-4">
          <div className="font-sans text-lg sm:text-xl font-semibold text-black-100 my-1">
            Special Instructions
          </div>
          <div className="font-sans text-sm text-primary-90 leading-4 my-1">
            Please let the florist know here if there are any special requests
            that you have for the arrangement. They will try their best to
            accommodate. (ie colour preferences, flower preferences, wrapping,
            etc.)
          </div>
          <textarea
            id="specialInstructions"
            value={formik.values.specialInstructions}
            onChange={formik.handleChange}
            className="w-full font-sans text-md text-black-90 outline-none border border-gray-500 px-4 py-2 rounded-lg"
          />
        </div>
        <div className="px-8 my-8">
          <Button label="Continue to Payment" type="submit" fullWidth />
        </div>
      </form>
    );
  };

  return (
    <div className="section">
      <div className="max-w-screen-lg mx-auto">
        <div className="flex flex-col md:flex-row px-0 sm:px-8 md:px-0">
          <div className="flex-1">
            <div className="font-sans text-xl md:text-2xl font-semibold text-black-100 my-4 md:my-6">
              Checkout
            </div>
            {user
              ? renderDeliveryInfo()
              : continueAsGuest
              ? renderGuestForm()
              : renderLoginForm()}
          </div>
          <div className="flex-1 mt-10 md:mt-0 md:ml-12 lg:ml-24">
            <div className="font-sans text-xl md:text-2xl font-semibold text-black-100 my-4 md:my-6">
              Your Cart
            </div>
            <CartMenu showPaymentOptions />
          </div>
        </div>
      </div>
    </div>
  );
};
