import React, { useEffect, useMemo, useState } from 'react';
import firebase from 'firebase/compat';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FaApplePay } from 'react-icons/fa';
import { loadStripe, CanMakePaymentResult } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
// import { uuid } from 'uuidv4';
import { Button, Input, LoadingView } from '@components';
import { CartMenu } from '@containers';
import { setStripeCustomerId } from '@actions/auth';
import { config } from '@configs';
import {
  selectDeliveryAddress,
  selectProfile,
  selectReceiver,
  selectSender,
  selectCartItems,
  selectDeliveryTime,
  selectDeliveryInstructions,
  selectCardNote,
  selectSpecialInstructions,
  selectCartVendor,
} from '@selectors';
import {
  addNewPaymentSource,
  // chargeStripeCustomer,
  createStripeCustomer,
} from '@services/payment';
import { updateUserProfile } from '@services/auth';
import { WalletType } from '@models';
import { placeOrder } from '@services/order';
import { showSnackBar } from '@utils';
import { overrideCart, setCartVendor } from '@actions/cart';
import {
  fetchFromFirebaseFunctions,
  SEND_ORDER_EMAIL_TO_USER,
  SEND_ORDER_EMAIL_TO_VENDOR,
  SEND_ORDER_NOTIFICATION_TO_VENDOR,
} from '@services/functions';

const CARD_OPTIONS = {
  hidePostalCode: true,
};

const PaymentComponent: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const profile = useSelector(selectProfile);
  const sender = useSelector(selectSender);
  const receiver = useSelector(selectReceiver);
  const deliveryAddress = useSelector(selectDeliveryAddress);
  const deliveryTime = useSelector(selectDeliveryTime);
  const deliveryInstructions = useSelector(selectDeliveryInstructions);
  const cardNote = useSelector(selectCardNote);
  const specialInstructions = useSelector(selectSpecialInstructions);
  const cartVendor = useSelector(selectCartVendor);
  const cartItems = useSelector(selectCartItems);

  const [cardName, setCardName] = useState<string>('');
  const [cardError, setCardError] = useState<any>(null);
  const [cardComplete, setCardComplete] = useState<boolean>(false);
  const [walletType, setWalletType] = useState<string | null>(null);
  const [paymentRequest, setPaymentRequest] =
    useState<CanMakePaymentResult | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const subTotal = useMemo(() => {
    return cartItems.reduce(
      (prev, next) => prev + Number(next.product.price) * next.quantity,
      0
    );
  }, [cartItems]);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const pr = stripe.paymentRequest({
      country: 'CA',
      currency: 'cad',
      requestPayerName: true,
      requestPayerEmail: true,
      total: {
        label: 'Total Price',
        amount: Math.ceil(
          subTotal * (1 + config.ESTIMATED_TAX_RATE) + config.SERVICE_FEE * 100
        ),
      },
    });

    pr.canMakePayment().then((result) => {
      if (result) {
        setPaymentRequest(result);
        if (result.applePay) {
          setWalletType(WalletType.applePay);
        } else {
          setWalletType(WalletType.googlePay);
        }
      }
    });
  }, [stripe, subTotal]);

  useEffect(() => {
    if (sender) {
      setCardName(`${sender.firstName} ${sender.lastName}`);
    }
  }, [sender]);

  const stripeRequiredData = useMemo(() => {
    if (sender) {
      return {
        email: sender?.email,
        name: `${sender?.firstName} ${sender?.lastName}`,
      };
    } else {
      return null;
    }
  }, [sender]);

  const retrieveStripeCustomerId = async () => {
    if (profile?.stripeCustomerID) {
      return profile.stripeCustomerID;
    }
    if (sender?.email) {
      const response = await createStripeCustomer(sender.email);
      if (!response.success) {
        return null;
      }
      if (profile) {
        updateUserProfile(profile.id, { stripeCustomerID: response.data });
        dispatch(setStripeCustomerId(response.data));
      }
      return response.data;
    }
    return null;
  };

  const clearOrder = () => {
    dispatch(overrideCart([]));
    dispatch(setCartVendor(null));
  };

  const handlePayment = async () => {
    if (!stripe || !elements) {
      return;
    }

    if (cardError) {
      elements.getElement('card')?.focus();
      return;
    }

    if (!cardComplete) {
      return;
    }

    try {
      setLoading(true);
      const cardElement = elements.getElement(CardElement);

      if (!cardElement || !stripeRequiredData) {
        return;
      }

      const token = await stripe.createToken(cardElement);
      console.log('token ', token);
      if (token) {
        const stripeCustomerID = await retrieveStripeCustomerId();
        console.log('stripeCustomerID ', stripeCustomerID);
        const source = await addNewPaymentSource(
          stripeCustomerID,
          token.token?.id
        );
        if (!source.success) {
          return;
        }
        console.log('source ', source);
        const products = cartItems.map((item) => {
          const { id, name, photo, price } = item.product;
          return {
            id,
            name,
            photo,
            price,
            quantity: item.quantity,
          };
        });
        const data = {
          sender: profile ? { ...profile, ...sender } : sender,
          senderID: sender?.senderID || '',
          receiver,
          deliveryAddress,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          vendorID: cartVendor?.id,
          vendor: cartVendor,
          cardNote,
          specialInstructions,
          deliveryInstructions,
          products,
          deliveryTime,
          subTotal,
          serviceFee: config.SERVICE_FEE,
          estimatedTax: config.ESTIMATED_TAX_RATE * subTotal,
          totalPrice:
            subTotal * (1 + config.ESTIMATED_TAX_RATE) + config.SERVICE_FEE,
          paymentSource: source.data.response.id,
          stripeCustomerID,
          status: 'NEW',
        };
        const resp = await placeOrder(data);
        setLoading(false);
        if (resp.success) {
          fetchFromFirebaseFunctions(SEND_ORDER_NOTIFICATION_TO_VENDOR, {
            pushToken: cartVendor?.pushToken,
            payload: {
              title: 'New Order',
              body: 'You have a new order! Please review.',
            },
          });
          fetchFromFirebaseFunctions(SEND_ORDER_EMAIL_TO_VENDOR, {
            orderId: resp.data,
          });
          fetchFromFirebaseFunctions(SEND_ORDER_EMAIL_TO_USER, {
            orderId: resp.data,
            type: 'placed',
          });
          clearOrder();
          navigate(`/orders/${resp.data}`);
        } else {
          showSnackBar({
            title: 'Error',
            message: 'Placing order is failed',
            type: 'danger',
          });
        }
      }
    } catch (e: any) {
      setLoading(false);
      showSnackBar({
        title: 'Error',
        message: 'Placing order is failed',
        type: 'danger',
      });
    }
  };

  return (
    <div className="section">
      <div className="max-w-screen-lg mx-auto">
        <div className="flex flex-col-reverse md:flex-row px-0 sm:px-8 md:px-0">
          <div className="flex-1 mt-10 md:mt-0">
            <div>
              <div className="font-sans text-xl md:text-2xl font-semibold text-black-100 my-4 md:my-6">
                Sending From
              </div>
              <div>
                <div className="text-base md:text-lg font-sans text-primary-90">{`${
                  sender?.firstName || ''
                } ${sender?.lastName || ''}`}</div>
                <div className="text-base md:text-lg font-sans text-primary-90">
                  {sender?.email || ''}
                </div>
              </div>
              <div className="font-sans text-xl md:text-2xl font-semibold text-black-100 my-4 md:my-6">
                Delivering To
              </div>
              <div>
                <div className="text-base md:text-lg font-sans text-primary-90">{`${
                  receiver?.firstName || ''
                } ${receiver?.lastName || ''}`}</div>
                <div className="text-base md:text-lg font-sans text-primary-90">
                  {deliveryAddress?.description || ''}
                </div>
              </div>
            </div>
            <div className="mt-8 md:mt-12">
              {walletType === WalletType.applePay ? (
                <>
                  <button
                    className="flex justify-center items-center h-11 bg-black-900 text-white rounded-md w-full overflow-hidden"
                    type="button"
                  >
                    <FaApplePay size={44} />
                  </button>
                  <div className="font-sans font-semibold text-lg md:text-xl text-primary-90 text-center my-4">
                    or
                  </div>
                </>
              ) : null}
              <div>
                <Input
                  id="cardName"
                  caption="NAME ON CARD*"
                  fullWidth
                  value={cardName}
                  onChange={(e) => setCardName(e.target.value)}
                />
                <div className="mt-2">
                  <div className="font-sans text-sm text-primary-90">CARD*</div>
                  <div className="h-10 flex flex-col justify-center border border-gray-500 pl-4 rounded-lg">
                    <CardElement
                      options={CARD_OPTIONS}
                      onChange={(e) => {
                        setCardError(e.error);
                        setCardComplete(e.complete);
                      }}
                    />
                  </div>
                </div>
                <div className="mt-6 md:mt-10">
                  <Button
                    label="Pay"
                    type="button"
                    fullWidth
                    onClick={handlePayment}
                  />
                  <div className="font-sans text-base text-primary-90 mt-4 leading-5">
                    You will only be charged once the vendor approves your
                    order. You can cancel your order any time before that.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 md:ml-12 lg:ml-24">
            <div className="font-sans text-xl md:text-2xl font-semibold text-black-100 my-4 md:my-6">
              Your Cart
            </div>
            <CartMenu showPaymentOptions />
          </div>
        </div>
      </div>
      {loading && <LoadingView />}
    </div>
  );
};

const stripePromise = loadStripe(config.STRIPE_PUBLIC_KEY);

export const Payment: React.FC = () => (
  <Elements stripe={stripePromise}>
    <PaymentComponent />
  </Elements>
);
