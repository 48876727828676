import React from 'react';

interface Props {
  title: string;
  productImage?: string;
}

export const VendorCard: React.FC<Props> = ({ title, productImage }) => {
  return (
    <div>
      <div className="aspect-w-4 aspect-h-3">
        <img src={productImage} className="w-full h-full object-cover" alt="" />
      </div>
      <div className="font-sans text-lg md:text-xl mt-1">{title}</div>
    </div>
  );
};
