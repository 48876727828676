import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Input, LoadingView } from '@components';
import { ErrorCode } from '@models';
import {
  createAccountWithEmailAndPassword,
  loginOrSignupWithGoogle,
} from '@services/auth';
import { showSnackBar } from '@utils';
import { ReactComponent as GoogleIcon } from '@assets/icons/google.svg';
import LogoImage from '@assets/images/logo-image.png';

export const Register: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
      firstName: Yup.string().required('First Name is required'),
      lastName: Yup.string().required('Last Name is required'),
      password: Yup.string()
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .required('Password is required'),
      passwordConfirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Password is required'),
    }),
    onSubmit: (values) => {
      setLoading(true);
      createAccountWithEmailAndPassword(values).then((response) => {
        setLoading(false);
        if (response.success) {
          navigate('/');
        } else {
          let message = '';
          if (response.error === ErrorCode.emailInUse) {
            message = 'Email is already in use';
          } else {
            message = 'Something went wrong';
          }
          showSnackBar({
            title: 'Error',
            message,
            type: 'danger',
          });
        }
      });
    },
  });

  const handleGoogleSignup = () => {
    setLoading(true);
    loginOrSignupWithGoogle().then((response) => {
      setLoading(false);
      if (response.success) {
        navigate('/');
      } else {
        let message = 'Something went wrong';
        showSnackBar({
          title: 'Error',
          message,
          type: 'danger',
        });
      }
    });
  };

  return (
    <div className="section">
      <div className="max-w-lg mx-auto pt-10">
        <div className="flex flex-col items-center">
          <img className="h-10" src={LogoImage} alt="Cadoh" />
          <div className="font-sans font-semibold text-3xl text-black-100 text-center mt-4">
            Sign Up
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="mt-4">
            <Input
              id="email"
              caption="Email Address"
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="email"
              error={
                formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : null
              }
            />
          </div>
          <div className="mt-4">
            <Input
              id="firstName"
              caption="First Name"
              fullWidth
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.firstName && formik.errors.firstName
                  ? formik.errors.firstName
                  : null
              }
            />
          </div>
          <div className="mt-4">
            <Input
              id="lastName"
              caption="Last Name"
              fullWidth
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.lastName && formik.errors.lastName
                  ? formik.errors.lastName
                  : null
              }
            />
          </div>
          <div className="mt-4">
            <Input
              id="password"
              caption="Password"
              fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="password"
              error={
                formik.touched.password && formik.errors.password
                  ? formik.errors.password
                  : null
              }
            />
          </div>
          <div className="mt-4">
            <Input
              id="passwordConfirmation"
              caption="Password Confirm"
              fullWidth
              value={formik.values.passwordConfirmation}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="password"
              error={
                formik.touched.passwordConfirmation &&
                formik.errors.passwordConfirmation
                  ? formik.errors.passwordConfirmation
                  : null
              }
            />
          </div>
          <div className="flex">
            <label
              className="font-sans text-md text-primary-90"
              htmlFor="send-update"
            >
              <input
                className="font-sans text-md text-primary-90"
                id="send-update"
                type="checkbox"
              />
              &nbsp;&nbsp;Sign me up to receive occasional Cadoh updates
            </label>
          </div>
          <div className="px-10 md:px-20 py-10">
            <Button label="Sign Up" type="submit" fullWidth />
            <div className="font-sans font-semibold text-xl text-primary-90 text-center my-4">
              or
            </div>
            <Button
              onClick={handleGoogleSignup}
              label={
                <div className="flex justify-center items-center">
                  <GoogleIcon width={24} height={24} />
                  &nbsp;&nbsp;Sign Up with Google
                </div>
              }
              type="button"
              fullWidth
            />
            <div className="mt-10 flex justify-center">
              <Link className="link-text" to="/login">
                Already have an account? Sign In
              </Link>
            </div>
          </div>
        </form>
      </div>
      {loading ? <LoadingView /> : null}
    </div>
  );
};
