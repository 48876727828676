import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PrivateRoute } from '@routes/PrivateRoute';
import { getUserOrders } from '@services/order';
import { selectProfile } from '@selectors';
import { IOrder } from '@models';

export const OrderHistory: React.FC = () => {
  const navigate = useNavigate();
  const profile = useSelector(selectProfile);
  const [orders, setOrders] = useState<IOrder[]>([]);

  const onGetUserOrders = (userOrders: IOrder[]) => {
    setOrders(userOrders);
  };

  useEffect(() => {
    let unSubscribe;
    if (profile) {
      unSubscribe = getUserOrders(profile?.id, onGetUserOrders);
    }
    return unSubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PrivateRoute>
      <div className="section">
        <div className="section-title">Your orders</div>
        <div className="overflow-x-auto font-sans mt-6 md:mt-8">
          <div
            className="flex bg-primary-30 font-semibold text-lg py-1"
            style={{ minWidth: '500px' }}
          >
            <div className="flex-1 px-2 text-base md:text-lg">ORDER NUMBER</div>
            <div className="flex-1 px-2 text-base md:text-lg">ORDER DATE</div>
            <div className="flex-1 px-2 text-base md:text-lg">ITEMS</div>
          </div>
          {orders.map((order) => (
            <div
              key={order.id}
              className="flex cursor-pointer hover:bg-gray-200 py-2"
              style={{ minWidth: '500px' }}
              onClick={() => navigate(`/orders/${order.id}`)}
            >
              <div className="flex-1 px-2 text-base md:text-lg">{order.id}</div>
              <div className="flex-1 px-2 text-base md:text-lg">
                {order.deliveryTime.toDate().toLocaleDateString([], {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </div>
              <div className="flex-1 px-2 text-base md:text-lg">
                {order.products.length ? order.products[0].name : ''}
              </div>
            </div>
          ))}
        </div>
      </div>
    </PrivateRoute>
  );
};
