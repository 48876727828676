import React, { useEffect } from 'react';
import ReactNotification from 'react-notifications-component';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ScrollToTop } from '@components';
import { LandingLayout, SearchLayout, EmptyLayout } from '@layouts';
import {
  Home,
  AboutUs,
  Contact,
  FAQ,
  TermsOfUse,
  PrivacyPolicy,
  Vendor,
  Vendors,
  Product,
  Checkout,
  Login,
  Register,
  ForgotPassword,
  Payment,
  OrderHistory,
  Order,
  Profile,
} from '@pages';
import { setUser, setProfile } from '@actions/auth';
import { firebaseAuth } from '@services/firebase';
import { getUserProfile } from '@services/auth';
import { IUser } from '@models';
import { AuthRoute } from './AuthRoute';

export const MainRoutes: React.FC = () => {
  const dispatch = useDispatch();

  const onAuthStateChanged = (user: IUser | null) => {
    dispatch(setUser(user));
    if (user) {
      getUserProfile(user.uid)
        .then((response) => {
          if (response.success) {
            dispatch(setProfile({ ...response.data, id: user.uid }));
          }
        })
        .catch((e) => console.log(e));
    } else {
      dispatch(setProfile(null));
    }
  };

  useEffect(() => {
    const subscriber = firebaseAuth().onAuthStateChanged(onAuthStateChanged);
    return subscriber;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowserRouter>
      <ReactNotification />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<LandingLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/vendors" element={<SearchLayout />}>
            <Route index element={<Vendors />} />
            <Route path=":vendorId" element={<EmptyLayout />}>
              <Route index element={<Vendor />} />
              <Route path=":productId" element={<Product />} />
            </Route>
          </Route>
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/payment" element={<Payment />} />
          <Route
            path="/login"
            element={
              <AuthRoute>
                <Login />
              </AuthRoute>
            }
          />
          <Route
            path="/register"
            element={
              <AuthRoute>
                <Register />
              </AuthRoute>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <AuthRoute>
                <ForgotPassword />
              </AuthRoute>
            }
          />
          <Route path="/orders" element={<EmptyLayout />}>
            <Route index element={<OrderHistory />} />
            <Route path=":orderId" element={<Order />} />
          </Route>
          <Route path="/profile" element={<Profile />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
