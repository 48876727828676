import { IVendor, IVendorProduct } from '@models';
import { db } from './firebase';

export const getVendor = (
  vendorId: string,
  callback: (vendor: IVendor) => void
) => {
  const unSubscribe = db
    .collection('vendors')
    .doc(vendorId)
    .onSnapshot((snapshot) => {
      const vendor = snapshot.data() as IVendor;
      callback(vendor);
    });
  return unSubscribe;
};

export const getVendors = (callback: (vendors: IVendor[]) => void) => {
  const unSubscribe = db.collection('vendors').onSnapshot((snapshot) => {
    const vendors: IVendor[] = [];
    snapshot.forEach((doc) => vendors.push(doc.data() as IVendor));
    callback(vendors);
  });
  return unSubscribe;
};

export const getVendorProducts = (
  vendorId: string,
  callback: (products: IVendorProduct[]) => void
) => {
  const unSubscribe = db
    .collection('vendor_products')
    .where('vendorID', '==', vendorId)
    .onSnapshot((snapshot) => {
      const products: IVendorProduct[] = [];
      snapshot.forEach((doc) => products.push(doc.data() as IVendorProduct));
      callback(products);
    });
  return unSubscribe;
};

export const getVendorProduct = (
  productId: string,
  callback: (product: IVendorProduct) => void
) => {
  const unSubscribe = db
    .collection('vendor_products')
    .doc(productId)
    .onSnapshot((snapshot) => {
      const product = snapshot.data() as IVendorProduct;
      callback(product);
    });
  return unSubscribe;
};
