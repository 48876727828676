import React from 'react';

export const TermsOfUse: React.FC = () => {
  return (
    <div className="section pt-10 md:pt-20">
      <h1 className="section-title">Terms of use</h1>
      <div className="max-w-screen-md mx-auto">
        <div className="font-sans text-xl md:text-2xl text-black-90 my-6 md:my-8">
          Effective May 1, 2021
        </div>
        <p className="section-text">
          1. Acceptance of this Agreement
          <br /> The Company provides an online marketplace connection, using
          web-based technology that connects You and other consumers, florists,
          and/or other vendors and independent delivery Contractors. The
          Company’s software permits consumers to place orders for flowers
          and/or other goods from florists and businesses. Once such orders are
          made, the Company’s software notifies Contractors that a delivery
          opportunity is available and the software facilitates completion of
          the delivery to the consumer. The company is not a retailer, delivery
          service, or flower preparation business.
        </p>
        <p className="section-text">&nbsp;</p>
        <p className="section-text">
          If you access the website located at{' '}
          <a className="link-text" href="https://www.cadoh.ca/">
            https://www.cadoh.ca/
          </a>
          , install or use Cadoh’s mobile application, install or use any other
          software supplied by Cadoh, or access any information, function, or
          service available or enabled by Cadoh (each, a “Service” and
          collectively, the “Services”), or complete the Cadoh account
          registration process, you, your heirs, assigns, and successors
          (collectively, “you” or “your”) hereby represent and warrant that:
        </p>
        <p className="section-text">&nbsp;</p>
        <p className="section-text">
          (a) you have read, understand, and agree to be bound by this
          Agreement;
          <br />
          (b) you are of legal age in the jurisdiction in which you reside to
          form a binding contract with Cadoh (the “Minimum Age”); and
          <br />
          (c) you have the authority to enter into the Agreement personally and,
          if applicable, on behalf of any organization whose behalf you have
          created anccount and to bind such organization to the Agreement
        </p>
        <p className="section-text">&nbsp;</p>
        <p className="section-text">
          The terms “User” and “Users” refer to all individuals and other
          persons who access or use the Services, including, without limitation,
          any organizations that register accounts or otherwise access or use
          the Services through their respective representatives. Except as
          otherwise provided in this Agreement, if you do not agree to be bound
          by the Agreement, you may not access or use Cadoh’s services.
        </p>
        <p className="section-text">&nbsp;</p>
        <p className="section-text">
          2. Modifications
          <br />
          Subject to Section 12(h) of this Agreement, Cadoh reserves the right
          to modify the terms and conditions of this Agreement or its policies
          relating to the Software or Services at any time, effective upon
          posting of an updated version of this Agreement of the Services. You
          should regularly review this Agreement, as your continued use of the
          Services after any such changes constitutes your agreement to such
          changes.
        </p>
        <p className="section-text">&nbsp;</p>
        <p className="section-text">
          3. Additional Terms and Policies
          <br />
          By using the services, you agree to be bound by this Agreement and
          acknowledge and agree to the collection, use, and disclosure of your
          personal information in accordance with Cadoh’s Privacy Policy, which
          is incorporated in this Agreement by reference. Certain features of
          our Services may be subject to additional terms and conditions, which
          to the extent permitted by applicable law are incorporated herein by
          reference.
        </p>
        <p className="section-text">&nbsp;</p>
        <p className="section-text">
          4. Rules and Prohibitions
          <br />
          By using the Services, you agree that:
          <br />
          (a) You will only use the Services for lawful purposes; you wil not
          use the Services for sending or storing any unlawful material or for
          deceptive or fraudulent purposes; and you will not engage in conduct
          that harms other Users, Cadoh employees, or our community.
          <br />
          (b) You will only use the Services in accordance with all applicable
          laws, including copyrights, trade secrets, or other intellectual
          property rights or other rights of any third party, including privacy,
          personality or publicity.
          <br />
          (c) You will only access the Services uisng means explicitly
          authorized by Cadoh.
          <br />
          (d) You will not use another User’s account, impersonate any person or
          entity, or forge or manipulate headers or identifiers to disguise the
          origin of any content transmitted through the Services.
          <br />
          (e) You will not use the Services to cause nuisance, annoyance or
          inconvenience.
          <br />
          (f) You will not use the Services, or any content accessible through
          the Services, for any commercial purpose, including but not limited to
          contacting, advertising to, soliciting or selling to any Merchant,
          user or Contractor, unless Cadoh has given you prior permission to do
          so in writing.
          <br />
          (g) You will not copy or distribute the Software or any content
          displayed through the Services, including Merchant’s offering content
          and reviews, for republication in any format or media.
          <br />
          (h) You will not compile, directly or indirectly, any content
          displayed through the Services except for your personal, noncommercial
          use.
          <br />
          (i) The information you provide to us when you register an account or
          otherwise communicate with us is accurate, you will promptly notify us
          of any changes to such information, and you will provide us with
          whatever proof of identity we may reasonably request.
          <br />
          (j) You will keep secure and confidential your account password or any
          identification credentials we provide you which allows access to the
          Services.
          <br />
          (k) You will use the Software and Services only for your own use and
          will not directly or indirectly resell, license, or transfer the
          Software, Services or content displayed by the Services to a third
          party.
          <br />
          (l) You will not use the Services in any way that could damage,
          disable, overburden or impair any Cadoh server, or the networks
          connected to any Cadoh server.
          <br />
          (m) You will not attempt to gain unauthorized access to the Services
          and/or to any account, resource, computer system, and/or network
          connected to any Cadoh server.
          <br />
          (n) You will not probe, scan, or test the vulnerability of any system
          or network or breach or circumvent any security or authentication
          measures Cadoh may use to prevent or restrict access to the Services
          or use of the Services or the content therein.
          <br />
          (o) You will not deep-link to the Cadoh website or access the Cadoh
          website manually or with any robot, spider, web crawler, extraction
          software, automated process and/or device to scrape, copy, index,
          frame or monitor any portion of the Cadoh website or any content on
          the Cadoh website.
          <br />
          (p) You will not conduct any systematic retrieval of data or other
          content from the Services.
          <br />
          (q) You will not try to harm other Users, Cadoh, or the Services in
          any way whatsoever.
          <br />
          (r) You will report any errors, bugs, unauthorized access
          methodologies or any breach of our intellectual property rights that
          you uncover in your use of the Services.
          <br />
          (s) You will not abuse our promotional or credit code system,
          including by redeeming multiple coupons at once or by opening multiple
          accounts to benefit from offers available only to first time users.
          <br />
          (t) You will not attempt to undertake any of the foregoing.
        </p>
        <p className="section-text">&nbsp;</p>
        <p className="section-text">
          5. Contractors and Vendors Are Independent
          <br />
          You understand and agree that Cadoh provides a technology platform
          connecting you with independent florist providers and others that
          provide the products offered through the Services (“Merchants”) and
          independent third-party contractors who provide delivery services
          (“Contractors”). You acknowledge and agree that Cadoh does not itself
          prepare bouquets or offer delivery services, and has no responsibility
          or liability for the acts or omissions of any Merchant or any
          Contractor. Cadoh is not the retailer of any products offered by
          Merchants, nor is it in the delivery business or a common carrier.
          Cadoh provides a technology platform facilitating the transmission of
          orders by Users to Merchants for delivery by Contractors. Cadoh will
          not assess or guarantee that suitability, legality or ability of any
          Contractor or Merchant. You agree that Cadoh is not responsible for
          the Merchants’ flower preparation and does not verify Merchants’
          compliance with applicable laws or regulations. Cadoh has no
          responsibility or liability for acts or omissions by any Merchant or
          Contractor.
          <br />
          You agree that the goods that you purchase will be created by the
          Merchant you have selected, that title to the goods passes from the
          Merchant to you at the Vendor's location, and that the Contactor will
          be directed by your instructions to transport the products to your
          designated delivery location. You agree that neither the Contractor
          nor Cadoh hold title or acquired any ownership interest in any goods
          that you order through the services.
        </p>
        <p className="section-text">&nbsp;</p>
        <p className="section-text">
          6. User Account
          <br />
          You may be required to register for an account to use parts of the
          Services. You must provide accurate, current, and complete information
          during the registration process and at all other times when y ou use
          the Services, and to update the information to keep it accurate,
          current, and complete. You are the sole authorized user of any account
          you create through the Services. You are solely and fully responsible
          for all activities that occur under your password or account. You
          agree that you shall monitor your account to prevent use by minors,
          and you will accept full responsibility for any unauthorized use of
          your password or your account. You may not authorize others to use
          your User status, and you may not assign or otherwise transfer your
          User account to any other person or entity. Should you suspect that
          any unauthorized party may be using your password or account, you will
          notify Cadoh immediately. Cadoh will not be liable and you may be
          liable for losses, damages, liability, expenses, and fees incurred by
          Cadoh or a third party arising from someone else using your account,
          regardless of whether you have notified us of such unauthorized use.
          If you provide any information that is untrue, inaccurate, not
          current, or incomplete, or Cadoh has reasonable grounds to suspect
          that such information is untrue, inaccurate, not current, or
          incomplete, Cadoh has the right to suspend or terminate your account
          and refuse any and all current or future use of the Services (or any
          portion thereof). You agree not to create an account or use the
          Services if you have been previously removed by Cadoh, or if you have
          been previously banned from use of the Services.
        </p>
        <p className="section-text">&nbsp;</p>
        <p className="section-text">
          7. User Content
          <br />
          (a) User Content. Cadoh may provide you with interactive opportunities
          through the Services, including, by way of example, the ability to
          post User ratings and reviews (collectively, “User Content”). You
          represent and warrant that you are the owner of, or otherwise have the
          right to provide, all User Content that you submit, post and/or
          otherwise transmit through the Services. You hereby grant Cadoh a
          perpetual, irrevocable, transferable, fully paid, royalty-free,
          non-exclusive, worldwide, fully sublicenseable right and license to
          use, copy, display, publish, modify, remove, publicly perform,
          translate, create derivative works, distribute and/or otherwise use
          the User Content in connection with Cadoh’s business and in all forms
          now known or here after invented (“Uses”), without notification to
          and/or approval by you. You further grant Cadoh a license to use your
          username and/or other User profile information, including without
          limitation your ratings history, to attribute User Content to you in
          connection with such Uses, without notification or approval by you.
          You agree that this license includes the right for other Users to
          access and use your User Content in conjunction with participation in
          the Services and as permitted through the functionality of the
          Services. In the interest of clarity, the license granted to Cadoh
          herein shall survive termination of the Services or your account.
          Cadoh reserves the right in its sole discretion to remove or disable
          access to any User Content from the Services, suspend or terminate
          your account at any time, or pursue any other remedy or relief
          available under equity or law if you post any User Content that
          violates this Agreement or we consider to be objectionable for any
          reason. You agree that Cadoh may monitor and/or delete your User
          Content (but does not assume the obligation) for any reason in Cadoh’s
          sole discretion. Cadoh may also access, read, preserve, and disclose
          any information as Cadoh reasonably believes is necessary to (i)
          satisfy any applicable law, regulation, legal process, or governmental
          request, (ii) enforce this Agreement, including investigation of
          potential violations hereof, (iii) detect, prevent, or otherwise
          address fraud, security, or technical issues, (iv) respond to User
          support requests, or (v) protect the rights, property or safety of
          Cadoh, its users and the public.
          <br />
          (b) Feedback. You agree that any submission of any ideas, suggestions,
          and/or proposals to Cadoh through its suggestion, feedback, wiki,
          forum or similar pages (“Feedback”) is at your own risk and that Cadoh
          has no obligations (including without limitation, obligations of
          confidentiality) with respect to such Feedback. You represent and
          warrant that you have all rights necessary to submit the Feedback and
          you hereby grant to Cadoh a perpetual, irrevocable, transferable,
          fully paid, royalty-free, non-exclusive, worldwide, fully
          sublicenseable right and license to use, copy, display, publish,
          modify, remove, publicly perform, translate, create derivative works,
          distribute and/or otherwise use such Feedback.
          <br />
          (c) Ratings and Reviews. To the extent that you are asked to rate and
          post reviews of Merchants or other businesses (“Ratings” and
          “Reviews”), such Ratings and Reviews are considered User Content and
          are governed by this Agreement. Ratings and Reviews are not endorsed
          by Cadoh and do not represent the views of Cadoh or its affiliates.
          Cadoh shall have no liability for Ratings and Reviews or for any
          claims for economic loss resulting from such Ratings and Reviews.
          Because we strive to maintain a high level of integrity with respect
          to Ratings and Reviews posted or otherwise made available through the
          Services, you agree that: (i) you will base any Rating or Review on
          first-hand experience with the Merchant or business; (ii) you will not
          provide a Rating or Review for any Merchant or business for which you
          have an ownership interest, employment relationship or other
          affiliation or for any of that company’s competitors; (iii) you will
          not submit a Rating or Review in exchange for payment, free food
          items, or other benefits from a Merchant or business; and (iv) your
          review will comply with the terms of this Agreement. If we determine,
          in our sole discretion, that any Rating or Review could diminish the
          integrity of the Ratings and Reviews or otherwise violates this
          Agreement, we may remove such User Content without notice.
        </p>
        <p className="section-text">&nbsp;</p>
        <p className="section-text">
          8. Communications with Cadoh
          <br />
          By creating a Cadoh account, you electronically agree to accept and
          receive communications from Cadoh, Contractors, or third parties
          providing services to Cadoh including via email, text message, calls,
          and push notifications to the cellular telephone number you provided
          to Cadoh. You understand and agree that you may receive communications
          generated by automatic telephone dialing systems and/or which will
          deliver prerecorded messages sent by or on behalf of Cadoh, its
          affiliated companies and/or Contractor, including but not limited to
          communications concerning orders placed through your account on the
          Services. Message and data rates may apply. If you do not wish to
          receive promotional emails, text messages, or other communications,
          you may change your notification preferences by accessing the Settings
          in your account.
        </p>
        <p className="section-text">&nbsp;</p>
        <p className="section-text">
          9. Intellectual Property Ownership
          <br />
          Cadoh alone (and its licensors, where applicable) shall own all right,
          title and interest, including all related intellectual property
          rights, in and to the Software and the Services. This Agreement is not
          a sale and does not convey to you any rights of ownership in or
          related to the Software or the Services, or any intellectual property
          rights owned by Cadoh. Cadoh name, Cadoh logo, and the product names
          associated with the Software and Services are trademarks of Cadoh or
          third parties, and no right or license is granted to use them. You
          agree that you will not remove, alter or obscure any copyright,
          trademark, service mark or other proprietary rights notices
          incorporated in or accompanying the Software or the Services.
        </p>
        <p className="section-text">&nbsp;</p>
        <p className="section-text">
          10. Payment Terms
          <br />
          (a) Prices. You understand that: (a) the prices for menu items
          displayed through the Services may differ from the prices offered or
          published by Merchants for the same menu items and/or from prices
          available at third-party websites and that such prices may not be the
          lowest prices at which the menu items are sold; (b) Cadoh has no
          obligation to itemize its costs, profits or margins when publishing
          such prices; and (c) Cadoh reserves the right to change such prices at
          any time, at its discretion. You are liable for all transaction taxes,
          including goods and services/harmonized sales taxes, on the Services
          provided under this Agreement (other than taxes based on Cadoh’s
          income). Payment will be processed by Cadoh, using the preferred
          payment method designated in your account.
          <br />
          (b) No Refunds. Charges paid by you for completed and delivered orders
          are final and non-refundable. Cadoh has no obligation to provide
          refunds or credits, but may grant them, in each case in Cadoh’s sole
          discretion.
          <br />
          (c) Promotional Offers and Credits. Cadoh, at its sole discretion, may
          make promotional offers with different features and different rates to
          any User. These promotional offers are subject to the terms of this
          Agreement and may be valid only for certain Users as indicated in the
          offer. You agree that promotional offers: (i) may only be used by the
          intended audience, for the intended purpose, and in a lawful manner;
          (ii) may not be duplicated, sold or transferred in any manner, or made
          available to the general public, unless expressly permitted by Cadoh;
          (iii) are subject to the specific terms that Cadoh establishes for
          such promotional offer; (iv) cannot be redeemed for cash or cash
          equivalent; and (v) are not valid for use after the date indicated in
          the offer or in Cadoh’s Terms and Conditions for Promotional Offers
          and Credits. Cadoh reserves the right to withhold or deduct credits or
          benefits obtained through a promotion in the event that Cadoh
          determines or believes that the redemption of the promotion or receipt
          of the credit or benefit was in error, fraudulent, illegal, or in
          violation of the applicable promotion terms or this Agreement. Cadoh
          reserves the right to modify or cancel an offer at any time. Cadoh’s
          Terms and Conditions for Promotional Offers and Credits apply to all
          promotional offers. Cadoh may also offer gratuitous credits, which can
          be used for the Services. Any credit issued by Cadoh is valid for 6
          months from the date of issue except to the extent prohibited under
          applicable law and may not be redeemed for cash or cash equivalent.
          Upon expiration, credits will be removed from your account. Expired
          credits are no longer redeemable and cannot be used towards any order.
          <br />
          (d) Fees for Services. Cadoh may change the fees for our Services as
          we deem necessary or appropriate for our business, including but not
          limited to Delivery Fees, Service Fees, Small Order Fees, and Surge
          Fees.
        </p>
        <p className="section-text">&nbsp;</p>
        <p className="section-text">
          11. Dispute Resolution.
          <br />
          PLEASE READ THE FOLLOWING SECTION CAREFULLY. IT REQUIRES YOU TO
          ARBITRATE DISPUTES WITH THE COMPANY AND LIMITS THE MANNER IN WHICH YOU
          CAN SEEK RELIEF. THIS SECTION 12 OF THIS AGREEMENT SHALL BE REFERRED
          TO AS THE “ARBITRATION AGREEMENT”.
          <br />
          (a) Scope of Arbitration Agreement. You agree that any dispute or
          claim relating in any way to your access or use of the Services as a
          consumer of our Services, to any advertising or marketing
          communications regarding the Company or the Services, to any products
          or services sold or distributed through the Services that you received
          as a consumer of our Services, or to any aspect of your relationship
          or transactions with Company as a consumer of our Services will be
          resolved by binding arbitration, rather than in court, except that (1)
          you may assert claims in small claims court if your claims qualify, so
          long as the matter remains in such court and advances only on an
          individual (non-class, non-representative) basis; and (2) you or the
          Company may seek equitable relief in court for infringement or other
          misuse of intellectual property rights (such as trademarks, trade
          dress, domain names, trade secrets, copyrights, and patents). This
          Arbitration Agreement shall apply, without limitation, to all claims
          that arose or were asserted before the Effective Date of this
          Agreement.
          <br />
          (c) Arbitrator Powers. The arbitrator, and not any federal, state, or
          local court or agency, shall have exclusive authority to resolve any
          dispute relating to the interpretation, applicability, enforceability
          or formation of this Arbitration Agreement including, but not limited
          to any claim that all or any part of this Arbitration Agreement is
          void or voidable. The arbitration will decide the rights and
          liabilities, if any, of you and the Company. The arbitration
          proceeding will not be consolidated with any other matters or joined
          with any other proceedings or parties. The arbitrator will have the
          authority to grant motions dispositive of all or part of any claim or
          dispute. The arbitrator will have the authority to award monetary
          damages and to grant any non-monetary remedy or relief available to an
          individual under applicable law, the arbitral forum’s rules, and this
          Agreement (including this Arbitration Agreement). The arbitrator will
          issue a written statement of decision describing the essential
          findings and conclusions on which any award (or decision not to render
          an award) is based, including the calculation of any damages awarded.
          The arbitrator shall follow the applicable law. The arbitrator has the
          same authority to award relief on an individual basis that a judge in
          a court of law would have. The arbitrator’s decision is final and
          binding on you and the Company.
          <br />
          (d) Waiver of Jury Trial. YOU AND THE COMPANY WAIVE ANY CONSTITUTIONAL
          AND STATUTORY RIGHTS TO SUE IN COURT AND RECEIVE A JUDGE OR JURY
          TRIAL. You and the Company are instead electing to have claims and
          disputes resolved by arbitration, except as specified in section 12(a)
          above. There is no judge or jury in arbitration, and court review of
          an arbitration award is limited.
          <br />
          (e) Waiver of Class or Consolidated Actions. YOU AND THE COMPANY AGREE
          TO WAIVE ANY RIGHT TO RESOLVE CLAIMS WITHIN THE SCOPE OF THIS
          ARBITRATION AGREEMENT ON A CLASS, COLLECTIVE, OR REPRESENTATIVE BASIS.
          ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT
          MUST BE ARBITRATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS.
          CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR
          LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR
          USER. If, however, this waiver of class or consolidated actions is
          deemed invalid or unenforceable with respect to a particular claim or
          dispute, neither you nor the Company is entitled to arbitration of
          such claim or dispute. Instead, all such claims and disputes will then
          be resolved in a court as set forth in Section 20.
          <br />
          (f) Opt Out. You may opt out of this Arbitration Agreement. If you do
          so, neither you nor the Company can force the other to arbitrate as a
          result of this Agreement. To opt out, you must notify the Company in
          writing no later than 30 days after first becoming subject to this
          Arbitration Agreement. Your notice must include your name and address,
          your Cadoh username (if any), the email address you used to set up
          your Cadoh account (if you have one), and a CLEAR statement that you
          want to opt out of this Arbitration Agreement. If you opt out of this
          Arbitration Agreement, all other parts of this Agreement will continue
          to apply to you. Opting out of this Arbitration Agreement has no
          effect on any other arbitration agreements that you may have entered
          into with us or may enter into in the future with us. NOTWITHSTANDING
          ANYTHING TO THE CONTARY HEREIN, NOTHING IN THIS AGREEMENT SHALL
          SUPERSEDE, AMEND, OR MODIFY THE TERMS OF ANY SEPARATE AGREEMENT(S)
          BETWEEN YOU AND THE COMPANY RELATING TO YOUR WORK AS AN EMPLOYEE OR
          INDEPENDENT CONTRACTOR, INCLUDING WITHOUT LIMITATION, ANY INDEPENDENT
          CONTRACTOR AGREEMENT GOVERNING YOUR SERVICES AS A CONTRACTOR. FOR THE
          AVOIDANCE OF DOUBT, IF YOU ARE A CONTRACTOR, OPTING-OUT OF THE
          ARBITRATION AGREEMENT SET FORTH IN THIS SECTION 12 HAS NO AFFECT ON
          YOUR AGREEMENT TO ARBITRATE DISPUTES COVERED BY YOUR INDEPENDENT
          CONTRACTOR AGREEMENT WITH THE COMPANY.
          <br />
          (g) Survival. This Arbitration Agreement will survive any termination
          of your relationship with the Company.
          <br />
          (h) Modification. Notwithstanding any provision in the Agreement to
          the contrary, we agree that if the Company makes any future material
          change to this Arbitration Agreement, it will not apply to any
          individual claim(s) that you had already provided notice of to the
          Company.
        </p>
        <p className="section-text">&nbsp;</p>
        <p className="section-text">
          12. Third-Party Interactions
          <br />
          (a) Third-Party Websites, Applications and Advertisements. The
          Services may contain links to third-party websites (“Third-Party
          Websites”) and applications (“Third-Party Applications”) and
          advertisements (“Third-Party Advertisements”) (collectively,
          “Third-Party Websites & Advertisements”). When you click on a link to
          a Third-Party Website, Third-Party Application or Third-Party
          Advertisement, Cadoh will not warn you that you have left Cadoh’s
          Website or Services and will not warn you that you are subject to the
          terms and conditions (including privacy policies) of another website
          or destination. Such Third-Party Websites & Advertisements are not
          under the control of Cadoh. Cadoh is not responsible for any
          Third-Party Websites, Third-Party Applications or any Third-Party
          Advertisements. Cadoh does not review, approve, monitor, endorse,
          warrant, or make any condition or representations with respect to such
          Third-Party Websites & Advertisements, or their products or services.
          You use all links in Third-Party Websites & Advertisements at your own
          risk. You should review applicable terms and policies, including
          privacy and data gathering practices of any Third-Party Websites or
          Third-Party Applications, and make whatever investigation you feel
          necessary or appropriate before proceeding with any transaction with
          any third party.
          <br />
          (b) App Stores. You acknowledge and agree that the availability of the
          Application is dependent on the third party from which you received
          the Application license, e.g., the Apple iPhone or Android app stores
          (“App Store”). You acknowledge and agree that this Agreement is
          between you and Cadoh and not with the App Store. Cadoh, not the App
          Store, is solely responsible for the Software and the Services,
          including the Application and the Services, the content thereof,
          maintenance, support services and warranty therefor, and addressing
          any claims relating thereto (e.g., product liability, legal compliance
          or intellectual property infringement). In order to use the
          Application, you must have access to a wireless network, and you agree
          to pay all fees associated with such access. You also agree to pay all
          fees (if any) charged by the App Store in connection with the
          Application or the Services. You agree to comply with, and your
          license to use the Application is conditioned upon your compliance
          with, all applicable third-party terms of agreement (e.g., the App
          Store’s terms and policies) when using the Application. You represent
          and warrant that you are not located in a country that is subject to a
          Canadian Government embargo or similar laws of other countries where
          applicable, or that has been designated by the U.S. or Canadian
          Government as a “terrorist supporting” country, and you represent and
          warrant that you are not listed on any U.S. or Canadian Government
          list of prohibited or restricted parties. You acknowledge and agree
          that each App Store (and its affiliates) is an intended third-party
          beneficiary of this Agreement and has the right to enforce the terms
          and conditions of this Agreement.
        </p>
        <p className="section-text">&nbsp;</p>
        <p className="section-text">
          13. Social Media Guidelines.
          <br />
          Cadoh maintains certain social media pages for the benefit of the
          Cadoh community. By posting, commenting, or otherwise interacting with
          these pages, you agree to abide by our Social Media Community
          Guidelines.
        </p>
        <p className="section-text">&nbsp;</p>
        <p className="section-text">
          14. Indemnification
          <br />
          You agree to indemnify and hold harmless Cadoh and its officers,
          directors, employees, agents and affiliates (each, an “Indemnified
          Party”), from and against any losses, claims, actions, costs, damages,
          penalties, fines and expenses, including without limitation legal and
          attorneys’ fees and expenses, that may be incurred by an Indemnified
          Party arising out of, relating to or resulting from (a) your User
          Content; (b) your misuse of the Software or Services; (c) your breach
          of this Agreement or any representation, warranty, condition or
          covenant in this Agreement; or (d) your violation of any applicable
          laws, rules or regulations through or related to the use of the
          Software or Services. In the event of any claim, allegation, suit or
          proceeding alleging any matter potentially covered by the agreements
          in this section, you agree to pay for the defense of the Indemnified
          Party, including reasonable costs and legal and attorneys’ fees
          incurred by the Indemnified Party. Cadoh reserves the right, at its
          own cost, to assume the exclusive defense and control of any matter
          otherwise subject to indemnification by you, in which event you will
          fully cooperate with Cadoh in asserting any available defenses. This
          provision does not require you to indemnify any Indemnified Party for
          any unconscionable commercial practice by such party, or for such
          party’s negligence, fraud, deception, false promise, misrepresentation
          or concealment, suppression or omission of any material fact in
          connection with the Software or Services. You agree that the
          provisions in this section will survive any termination of your
          account, this Agreement, or your access to the Software and/or
          Services.
        </p>
        <p className="section-text">&nbsp;</p>
        <p className="section-text">
          15. Disclaimer of Warranties
          <br />
          YOU EXPRESSLY UNDERSTAND AND AGREE THAT TO THE FULLEST EXTENT
          PERMITTED BY APPLICABLE LAW, YOUR USE OF THE SOFTWARE AND SERVICES IS
          ENTIRELY AT YOUR OWN RISK. CHANGES ARE PERIODICALLY MADE TO THE
          SOFTWARE AND SERVICES AND MAY BE MADE AT ANY TIME WITHOUT NOTICE TO
          YOU. THE SOFTWARE AND SERVICES ARE PROVIDED ON AN “AS IS” BASIS
          WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, EITHER EXPRESS OR
          IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OR CONDITIONS OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR OR GENERAL PURPOSE AND
          NON-INFRINGEMENT. CADOH MAKES NO WARRANTIES, CONDITIONS OR
          REPRESENTATIONS ABOUT THE ACCURACY, RELIABILITY, COMPLETENESS OR
          TIMELINESS OF THE CONTENT MADE AVAILABLE THROUGH THE SOFTWARE OR
          SERVICES, OR THE SERVICES, SOFTWARE, TEXT, GRAPHICS OR LINKS.
          <br />
          CADOH DOES NOT WARRANT THAT THE SOFTWARE OR SERVICES WILL OPERATE
          ERROR-FREE OR THAT THE SOFTWARE OR SERVICES ARE FREE OF COMPUTER
          VIRUSES AND OTHER HARMFUL MALWARE. IF YOUR USE OF THE SOFTWARE OR
          SERVICES RESULTS IN THE NEED FOR SERVICING OR REPLACING EQUIPMENT OR
          DATA, CADOH SHALL NOT BE RESPONSIBLE FOR THOSE ECONOMIC COSTS.
        </p>
        <p className="section-text">&nbsp;</p>
        <p className="section-text">
          16. Internet Delays
          <br />
          The Software and Services may be subject to limitations, delays, and
          other problems inherent in the use of the Internet and electronic
          communications. Except as set forth in Cadoh’s privacy policy or as
          otherwise required by applicable law, Cadoh is not responsible for any
          delays, delivery failures, or damage, loss or injury resulting from
          such problems.
        </p>
        <p className="section-text">&nbsp;</p>
        <p className="section-text">
          17. Breach And Limitation of Liability
          <br />
          (a) General. You understand and agree that a key element of the
          Services and this Agreement is your and our mutual desire to keep the
          Services simple and efficient, and to provide the Software and
          Services at low cost. You understand and agree to the limitations on
          remedies and liabilities set forth in this Section 19 to keep the
          Software and Services simple and efficient, and costs low, for all
          users.
          <br />
          (b) Disclaimer of Certain Damages. TO THE FULLEST EXTENT PERMITTED BY
          APPLICABLE LAW CADOH SHALL NOT BE LIABLE TO YOU OR ANYONE ELSE FOR ANY
          INDIRECT, PUNITIVE, SPECIAL, EXEMPLARY, INCIDENTAL, CONSEQUENTIAL OR
          OTHER DAMAGES OF ANY TYPE OR KIND (INCLUDING PERSONAL INJURY, LOST
          PROFITS, PAIN AND SUFFERING, EMOTIONAL DISTRESS, AND LOSS OF DATA,
          REVENUE, USE AND ECONOMIC ADVANTAGE). THE FOREGOING DISCLAIMER OF
          PUNITIVE AND EXEMPLARY DAMAGES, AND THE ENTIRE DISCLAIMER OF DAMAGES
          FOR PERSONAL INJURY OR PROPERTY DAMAGE, OR FOR ANY INJURY CAUSED BY
          CADOH’S FRAUD OR FRAUDULENT MISREPRESENTATION.
        </p>
        <p className="section-text">&nbsp;</p>
        <p className="section-text">
          18. Exclusive Venue
          <br />
          Except where prohibited by applicable law (which may include the
          province of Quebec), to the extent the parties are permitted under
          this Agreement to initiate litigation in a court, both you and Cadoh
          agree that all claims and disputes arising out of or relating to the
          Agreement will be litigated exclusively in the province or courts
          located in Ontario if you are a Canadian citizen or resident.
        </p>
        <p className="section-text">&nbsp;</p>
        <p className="section-text">
          19. Termination
          <br />
          If you violate this Agreement, Cadoh may respond based on a number of
          factors including, but not limited to, the egregiousness of your
          actions and whether a pattern of harmful behavior exists. In addition,
          at its sole discretion, Cadoh may modify or discontinue the Software
          or Service, or may modify, suspend or terminate your access to the
          Software or the Services, for any reason, with or without notice to
          you and without liability to you or any third party. In addition to
          suspending or terminating your access to the Software or the Service,
          Cadoh reserves the right to take appropriate legal action, including
          without limitation pursuing civil, criminal or injunctive redress.
          Even after your right to use the Software or the Services is
          terminated, this Agreement will remain enforceable against you. All
          provisions which by their nature should survive to give effect to
          those provisions shall survive the termination of this Agreement.
        </p>
        <p className="section-text">&nbsp;</p>
        <p className="section-text">
          20. Procedure for Making Claims of Copyright Infringement.
          <br />
          It is Cadoh’s policy to terminate privileges of any User who
          repeatedly infringes copyright upon prompt notification to Cadoh by
          the copyright owner or the copyright owner’s legal agent. Without
          limiting the foregoing, if you believe that your work has been copied
          and posted on the Services in a way that constitutes copyright
          infringement, please provide our Copyright Agent with the following
          information: (a) an electronic or physical signature of the person
          authorized to act on behalf of the owner of the copyright interest;
          (b) a description of the copyrighted work that you claim has been
          infringed; (c) a description of the location on the Services of the
          material that you claim is infringing; (d) your address, telephone
          number and e-mail address; (e) a written statement by you that you
          have a good faith belief that the disputed use is not authorized by
          the copyright owner, its agent or the law; and (f) a statement by you,
          made under penalty of perjury, that the above information in your
          notice is accurate and that you are the copyright owner or authorized
          to act on the copyright owner’s behalf.
        </p>
        <p className="section-text">&nbsp;</p>
        <p className="section-text">
          21. General
          <br />
          (a) No Joint Venture or Partnership. No joint venture, partnership,
          employment, or agency relationship exists between you, Cadoh or any
          third party provider as a result of this Agreement or use of the
          Software or Services.
          <br />
          (b) Severability. Except as otherwise provided in this Agreement, if
          any provision of this Agreement is found to be invalid, the invalidity
          of such provision shall not affect the validity of the remaining
          provisions of this Agreement, which shall remain in full force and
          effect.
          <br />
          (c) Accessing and Downloading the Application from iTunes. The
          following applies to any Application accessed through or downloaded
          from the Apple App Store (an “App Store Sourced Application”):
          <br />
          (1) You acknowledge and agree that (i) the Agreement is concluded
          between you and Cadoh only, and not Apple, and (ii) Cadoh, not Apple,
          is solely responsible for the App Store Sourced Application and
          content thereof. Your use of the App Store Sourced Application must
          comply with the App Store Terms of Service.
          <br />
          (2) You acknowledge that Apple has no obligation whatsoever to furnish
          any maintenance and support services with respect to the App Store
          Sourced Application.
          <br />
          (3) In the event of any failure of the App Store Sourced Application
          to conform to any applicable warranty, you may notify Apple, and Apple
          will refund the purchase price, if any, for the App Store Sourced
          Application to you and to the fullest extent permitted by applicable
          law, Apple will have no other warranty obligation whatsoever with
          respect to the App Store Sourced Application. As between Cadoh and
          Apple, any other claims, losses, liabilities, damages, costs or
          expenses attributable to any failure to conform to any warranty or
          condition will be the sole responsibility of Cadoh.
          <br />
          (4) You and Cadoh acknowledge that, as between Cadoh and Apple, Apple
          is not responsible for addressing any claims you have or any claims of
          any third party relating to the App Store Sourced Application or your
          possession and use of the App Store Sourced Application, including,
          but not limited to: (i) product liability claims; (ii) any claim that
          the App Store Sourced Application fails to conform to any applicable
          legal or regulatory requirement; and (iii) claims arising under
          consumer protection or similar legislation.
          <br />
          (5) You and Cadoh acknowledge that, in the event of any third-party
          claim that the App Store Sourced Application or your possession and
          use of that App Store Sourced Application infringes that third party’s
          intellectual property rights, as between Cadoh and Apple, Cadoh, not
          Apple, will be solely responsible for the investigation, defense,
          settlement and discharge of any such intellectual property
          infringement claim to the extent required by this Agreement.
          <br />
          (6) You and Cadoh acknowledge and agree that Apple, and Apple’s
          subsidiaries, are third-party beneficiaries of this Agreement as
          related to your license of the App Store Sourced Application, and
          that, upon your acceptance of the terms and conditions of this
          Agreement, Apple will have the right (and will be deemed to have
          accepted the right) to enforce this Agreement as related to your
          license of the App Store Sourced Application against you as a
          third-party beneficiary thereof.
          <br />
          (7) Without limiting any other terms of this Agreement, you must
          comply with all applicable third-party terms of agreement when using
          the App Store Sourced Application.
          <br />
          (f) Notice. Where Cadoh requires that you provide an e-mail address,
          you are responsible for providing Cadoh with your most current e-mail
          address. In the event that the last e-mail address you provided to
          Cadoh is not valid, or for any reason is not capable of delivering to
          you any notices required or permitted by this Agreement, Cadoh’s
          dispatch of the e-mail containing such notice will nonetheless
          constitute effective notice.
          <br />
          (g) Electronic Communications. For contractual purposes, you (1)
          consent to receive communications from Cadoh in an electronic form;
          and (2) agree that all terms and conditions, agreements, notices,
          disclosures, and other communications that Cadoh provides to you
          electronically satisfy any legal requirement that such communications
          would satisfy if they were in writing. You agree to keep your contact
          information, including email address, current. This subparagraph does
          not affect your statutory rights.
          <br />
          (h) Transfer and Assignment. This Agreement, and any rights and
          licenses granted hereunder, may not be transferred or assigned by you,
          but may be assigned by Cadoh without restriction. Any attempted
          transfer or assignment in violation hereof shall be null and void.
          This Agreement binds and inures to the benefit of each party and the
          party’s successors and permitted assigns.
          <br />
          (i) Entire Agreement. This Agreement is the final, complete and
          exclusive agreement of the parties with respect to the subject matter
          hereof and supersedes and merges all prior discussions between the
          parties with respect to such subject matter. However, nothing in this
          Agreement shall supersede, amend, or modify the terms of any separate
          agreement(s) between you and Cadoh relating to your work as an
          employee or independent contractor, including, without limitation, any
          Independent Contractor Agreement governing your efforts as a
          Contractor.
        </p>
        <p className="section-text">&nbsp;</p>
        <p className="section-text">
          22. Contact Information
          <br />
          Cadoh welcomes your questions or comments regarding this Agreement:
          <br />
          Cadoh Delivery Inc.
          <br />
          <a className="link-text" href="mailto:support@cadoh.ca">
            support@cadoh.ca
          </a>
        </p>
      </div>
    </div>
  );
};
