import React from 'react';
import { Link } from 'react-router-dom';
// import Logo from '@assets/images/logo.png';

export const Footer: React.FC = () => {
  return (
    <div className="section py-12">
      {/* <div
        className="w-80 h-80 bg-cover bg-center mx-auto mb-6"
        style={{ backgroundImage: `url(${Logo})` }}
      /> */}
      <div className="flex justify-center mb-6 md:mb-10">
        <Link className="link-text p-2" to="/contact">
          Contact
        </Link>
        <Link className="link-text p-2" to="/terms-of-use">
          Terms of Use
        </Link>
        <Link className="link-text p-2" to="/privacy-policy">
          Privacy Policy
        </Link>
      </div>
      <h2 className="font-sans tracking-wider text-xs md:text-sm text-black uppercase font-bold text-center mb-6 md:mb-10">
        Cadoh - Toronto Flower Delivery And Marketplace App
      </h2>
      <h2 className="font-sans tracking-wider text-xs md:text-sm text-black uppercase text-center">
        Copyright © 2021 Cadoh - Toronto Flower Delivery and Marketplace App -
        All Rights Reserved.
      </h2>
    </div>
  );
};
