import { AxiosInstance } from './api-config';
import { IGooglePlace, IFormattedPlace, IApiResponse } from '@models';

export const getPlace = async (place: IGooglePlace): Promise<IApiResponse> => {
  try {
    const { data } = await AxiosInstance.post('/google-place-detail', {
      placeId: place.place_id,
    });
    const formattedPlace = formatPlaceData(place, data);
    return { success: true, data: formattedPlace } as IApiResponse;
  } catch (e) {
    return { success: false } as IApiResponse;
  }
};

const formatPlaceData = (
  place: IGooglePlace,
  details: any
): IFormattedPlace => {
  const formattedAddress: IFormattedPlace = {
    place_id: place.place_id,
    description: place.description,
    line1: '',
    city: '',
    state: '',
    country: '',
    postalCode: '',
    location: {
      latitude: details.geometry.location.lat,
      longitude: details.geometry.location.lng,
    },
  };

  details.address_components.forEach((item: any) => {
    if (item.types.indexOf('street_number') > -1) {
      formattedAddress.line1 = item.long_name;
    }
    if (item.types.indexOf('route') > -1) {
      formattedAddress.line1 = `${
        formattedAddress.line1 ? formattedAddress.line1 + ' ' : ''
      }${item.long_name}`;
    }
    if (item.types.indexOf('sublocality_level_1') > -1) {
      formattedAddress.city = item.short_name;
    }
    if (!formattedAddress.city.length) {
      if (item.types.indexOf('administrative_area_level_2') > -1) {
        formattedAddress.city = item.short_name;
      }
    }
    if (item.types.indexOf('administrative_area_level_1') > -1) {
      formattedAddress.state = item.short_name;
    }
    if (item.types.indexOf('country') > -1) {
      formattedAddress.country = item.long_name;
    }
    if (item.types.indexOf('postal_code') > -1) {
      formattedAddress.postalCode = item.short_name;
    }
  });

  return formattedAddress;
};
