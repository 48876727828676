import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Input, LoadingView } from '@components';
import { ErrorCode } from '@models';
import { sendPasswordResetEmail } from '@services/auth';
import { showSnackBar } from '@utils';
import LogoImage from '@assets/images/logo-image.png';

export const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    }),
    onSubmit: (values) => {
      setLoading(true);
      sendPasswordResetEmail(values.email).then((response) => {
        setLoading(false);
        if (response.success) {
          navigate('/login');
        } else {
          let message = '';
          if (response.error === ErrorCode.noUser) {
            message = 'User not exist';
          } else {
            message = 'Something went wrong';
          }
          showSnackBar({
            title: 'Error',
            message,
            type: 'danger',
          });
        }
      });
    },
  });

  return (
    <div className="section">
      <div className="max-w-lg mx-auto pt-10">
        <div className="flex flex-col items-center">
          <img className="h-10" src={LogoImage} alt="Cadoh" />
          <div className="font-sans font-semibold text-3xl text-black-100 text-center mt-4">
            Reset Password
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="mt-4">
            <Input
              id="email"
              caption="Email Address"
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="email"
              error={
                formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : null
              }
            />
          </div>
          <div className="px-10 md:px-20 py-6 md:py-10">
            <Button label="Send Link" type="submit" fullWidth />
            <div className="mt-6 md:mt-10 flex justify-center">
              <Link className="link-text" to="/login">
                Remember your password? Sign In
              </Link>
            </div>
          </div>
        </form>
      </div>
      {loading ? <LoadingView /> : null}
    </div>
  );
};
