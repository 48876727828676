import React from 'react';
import { GoTrashcan } from 'react-icons/go';
import { ICartItem } from '@models';
import { QuantitySelector } from '@components';

interface Props {
  cartItem: ICartItem;
  onQuantityUpdate: (cartItem: ICartItem) => void;
  onRemoveCartItem: (cartItem: ICartItem) => void;
}

export const CartItemCard: React.FC<Props> = ({
  cartItem,
  onQuantityUpdate,
  onRemoveCartItem,
}) => {
  const handleQuantityChange = (quantity: number) => {
    const newCartItem = { ...cartItem, quantity };
    onQuantityUpdate(newCartItem);
  };

  const handleRemoveCart = () => {
    onRemoveCartItem(cartItem);
  };

  return (
    <div className="flex mb-2">
      <div>
        <img
          className="w-24 md:w-32 h-24 md:h-32"
          src={cartItem.product.photo}
          alt=""
        />
      </div>
      <div className="flex-1 ml-4 py-2">
        <div className="flex justify-between">
          <div className="font-sans text-sm md:text-base text-black-90 flex-1">
            {cartItem.product.name}
          </div>
          <GoTrashcan
            className="ml-2 mt-1 text-lg md:text-xl text-black-90 cursor-pointer"
            onClick={handleRemoveCart}
          />
        </div>
        <div className="flex justify-between items-center mt-4 md:mt-6">
          <QuantitySelector
            value={cartItem.quantity}
            onChange={handleQuantityChange}
          />
          <div className="font-sans font-semibold text-base md:text-lg text-primary-90">{`$${(
            Number(cartItem.product.price) * cartItem.quantity
          ).toFixed(2)}`}</div>
        </div>
      </div>
    </div>
  );
};
