import { db } from './firebase';
import { IApiResponse, IOrder } from '@models';

export const placeOrder = async (data: any): Promise<IApiResponse> => {
  try {
    const response = await db.collection('orders').add(data);
    await db.collection('orders').doc(response.id).update({ id: response.id });
    return { success: true, data: response.id } as IApiResponse;
  } catch (e) {
    console.log(e);
    return {
      success: false,
    } as IApiResponse;
  }
};

export const getUserOrders = (
  userId: string,
  callback: (orders: IOrder[]) => void
) => {
  const unSubscribe = db
    .collection('orders')
    .where('senderID', '==', userId)
    .orderBy('createdAt', 'desc')
    .onSnapshot((snapshot) => {
      const orders: IOrder[] = [];
      snapshot.forEach((doc) => orders.push(doc.data() as IOrder));
      callback(orders);
    });
  return unSubscribe;
};

export const getOrder = (
  orderId: string,
  callback: (order: IOrder) => void
) => {
  const unSubscribe = db
    .collection('orders')
    .doc(orderId)
    .onSnapshot((snapshot) => {
      const order = snapshot.data() as IOrder;
      callback(order);
    });
  return unSubscribe;
};

export const deleteOrder = (orderId: string, callback: () => void) => {
  db.collection('orders')
    .doc(orderId)
    .delete()
    .then(() => callback());
};
