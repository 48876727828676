import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@components';
import { removeCartItem, updateCartItem } from '@actions/cart';
import { config } from '@configs';
import { ICartItem } from '@models';
import { selectCartItems, selectDeliveryTime } from '@selectors';
import { getDeliveryTimeString } from '@utils';

import { CartItemCard } from '../CartItemCard';
import LogoImage from '@assets/images/logo-image.png';
import { ReactComponent as VisaIcon } from '@assets/icons/visa.svg';
import { ReactComponent as MasterCardIcon } from '@assets/icons/mastercard.svg';
import { ReactComponent as AmericanExpressIcon } from '@assets/icons/american-express.svg';
import { ReactComponent as ApplePayIcon } from '@assets/icons/apple-pay.svg';

interface Props {
  onClose?: () => void;
  showCheckout?: boolean;
  showPaymentOptions?: boolean;
}

export const CartMenu: React.FC<Props> = ({
  onClose,
  showCheckout,
  showPaymentOptions,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const deliveryTime = useSelector(selectDeliveryTime);
  const cartItems = useSelector(selectCartItems);

  const onUpdateQuantity = (newCartItem: ICartItem) => {
    const existingItemIndex = cartItems.findIndex(
      (item) => item.product.id === newCartItem.product.id
    );
    dispatch(updateCartItem(newCartItem, existingItemIndex));
  };

  const onRemoveCartItem = (item: ICartItem) => {
    dispatch(removeCartItem(item));
  };

  const goToCheckout = () => {
    onClose && onClose();
    navigate('/checkout');
  };

  const subTotal = useMemo(() => {
    return cartItems.reduce(
      (prev, next) => prev + Number(next.product.price) * next.quantity,
      0
    );
  }, [cartItems]);

  const renderCart = () => {
    return (
      <div>
        <div className="flex justify-between items-center">
          <div className="text-base md:text-lg font-sans text-gray-900">
            Delivering On
          </div>
          <div className="text-base md:text-lg font-sans font-semibold text-gray-900 bg-primary-30 px-2 md:px-4 py-1 rounded-lg">
            {deliveryTime ? getDeliveryTimeString(deliveryTime) : ''}
          </div>
        </div>
        <div className="my-4 md:my-6">
          {cartItems.map((item) => (
            <CartItemCard
              key={item.product.id}
              cartItem={item}
              onQuantityUpdate={onUpdateQuantity}
              onRemoveCartItem={onRemoveCartItem}
            />
          ))}
        </div>
        <div className="flex justify-between align-items py-2 md:py-4 px-2 border-t border-primary-30">
          <div className="text-base md:text-lg font-sans text-gray-900">
            Service Fee
          </div>
          <div className="text-base md:text-lg font-sans font-semibold text-gray-900">
            {`$${config.SERVICE_FEE.toFixed(2)}`}
          </div>
        </div>
        <div className="py-2 md:py-4 border-t border-primary-30">
          <div className="bg-primary-30 p-2 mt-2 md:mt-4">
            <div className="flex justify-between align-items py-1">
              <div className="text-base md:text-lg font-sans text-gray-900">
                Subtotal
              </div>
              <div className="text-base md:text-lg font-sans font-semibold text-gray-900">
                {`$${subTotal.toFixed(2)}`}
              </div>
            </div>
            <div className="flex justify-between align-items py-1">
              <div className="text-base md:text-lg font-sans text-gray-900">
                Service Fee
              </div>
              <div className="text-base md:text-lg font-sans font-semibold text-gray-900">
                {`$${config.SERVICE_FEE.toFixed(2)}`}
              </div>
            </div>
            <div className="flex justify-between align-items py-1">
              <div className="text-base md:text-lg font-sans text-gray-900">
                Estimated Tax
              </div>
              <div className="text-base md:text-lg font-sans font-semibold text-gray-900">
                {`$${(subTotal * config.ESTIMATED_TAX_RATE).toFixed(2)}`}
              </div>
            </div>
          </div>
          <div className="flex justify-between align-items p-2 mt-2 md:mt-4">
            <div className="text-base md:text-lg font-sans text-gray-900">
              Order Total
            </div>
            <div className="text-base md:text-lg font-sans font-semibold text-gray-900">
              {`$${(
                subTotal * (1 + config.ESTIMATED_TAX_RATE) +
                config.SERVICE_FEE
              ).toFixed(2)}`}
            </div>
          </div>
        </div>
        {showCheckout ? (
          <div className="flex justify-center mt-2">
            <Button label="Checkout" type="button" onClick={goToCheckout} />
          </div>
        ) : null}
        {showPaymentOptions ? (
          <div className="px-2 mt-6">
            <div className="text-md font-sans text-gray-900">
              Accepted Payment
            </div>
            <div className="flex items-center mt-2">
              <VisaIcon width={80} />
              <MasterCardIcon width={80} />
              <AmericanExpressIcon width={80} />
              <ApplePayIcon width={80} />
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  const renderEmptyCart = () => {
    return (
      <div className="mt-20 flex flex-col items-center">
        <img className="h-10" src={LogoImage} alt="Cadoh" />
        <div className="text-lg font-sans text-gray-900 mt-2">
          Your cart is empty
        </div>
      </div>
    );
  };

  return <div>{cartItems.length ? renderCart() : renderEmptyCart()}</div>;
};
