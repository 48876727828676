import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { IFormattedPlace, IVendor } from '@models';
import { getVendors } from '@services/vendor';
import { getRoadDistance, sortVendorByDistance } from '@services/distance';
import { selectDeliveryAddress, selectDeliveryTime } from '@selectors';
import { VendorItem } from './components/VendorItem';

const weekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const Vendors: React.FC = () => {
  const deliveryAddress = useSelector(selectDeliveryAddress);
  const deliveryTime = useSelector(selectDeliveryTime);
  const [vendors, setVendors] = useState<IVendor[]>([]);
  const [sortedVendors, setSortedVendors] = useState<IVendor[]>([]);

  const onGetVendors = (vendors: IVendor[]) => {
    setVendors(vendors);
  };

  useEffect(() => {
    const unSubscribe = getVendors(onGetVendors);
    return unSubscribe;
  }, []);

  useEffect(() => {
    getSortedVendors(vendors, deliveryAddress, deliveryTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendors, deliveryAddress, deliveryTime]);

  const getSortedVendors = async (
    vendors: IVendor[],
    deliveryAddress?: IFormattedPlace | null,
    deliveryTime?: Date | null
  ) => {
    if (!deliveryAddress || !deliveryTime) {
      return;
    }
    setSortedVendors([]);
    const newVendors: IVendor[] = [];
    const acceptingVendors = vendors
      .filter((vendor) => vendor.accepting)
      .filter(
        (vendor) => vendor.operatingDays[weekDays[deliveryTime?.getDay()]]
      );
    for (let i = 0; i < acceptingVendors.length; i++) {
      const distance = await getRoadDistance(
        deliveryAddress.location,
        acceptingVendors[i].location
      );
      newVendors.push({ ...acceptingVendors[i], distance });
    }
    newVendors.sort(sortVendorByDistance);
    setSortedVendors(newVendors);
  };

  return (
    <div>
      <div className="section">
        <h1 className="section-title">Shops Nearby</h1>
        <div className="pb-10">
          {sortedVendors.map((vendor) => (
            <VendorItem key={vendor.id} vendor={vendor} />
          ))}
        </div>
      </div>
      <Outlet />
    </div>
  );
};
