import firebase from 'firebase/compat';
import { firebaseAuth, db, authProviders } from './firebase';
import { IProfile, ErrorCode, IUser, IApiResponse } from '@models';

export const getUserProfile = async (userId: string): Promise<IApiResponse> => {
  try {
    const data = await db.collection('users').doc(userId).get();
    return { success: true, data: data.data() as IProfile } as IApiResponse;
  } catch (e) {
    console.log(e);
    return {
      success: false,
    } as IApiResponse;
  }
};

export const updateUserProfile = async (
  userId: string,
  userData: any
): Promise<IApiResponse> => {
  try {
    await db
      .collection('users')
      .doc(userId)
      .update({
        ...userData,
      });
    return { success: true } as IApiResponse;
  } catch (e) {
    console.log(e);
    return { success: false } as IApiResponse;
  }
};

export const createAccountWithEmailAndPassword = (
  userDetails: any
): Promise<IApiResponse> => {
  return new Promise(function (resolve, reject) {
    const { email, firstName, lastName, password } = userDetails;
    firebaseAuth()
      .createUserWithEmailAndPassword(email, password)
      .then((response) => {
        if (response.user) {
          const uid = response.user.uid;
          let userData: any = {
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            id: uid,
            email: email,
            phone: '',
            firstName,
            lastName,
          };
          db.collection('users')
            .doc(uid)
            .set(userData)
            .then(() => {
              resolve({
                success: true,
                data: response.user as IUser,
              } as IApiResponse);
            })
            .catch(() => {
              resolve({
                success: false,
                error: ErrorCode.serverError,
              } as IApiResponse);
            });
        } else {
          resolve({
            success: false,
            error: ErrorCode.serverError,
          } as IApiResponse);
        }
      })
      .catch((error) => {
        let errorCode = ErrorCode.serverError;
        if (error.code === 'auth/email-already-in-use') {
          errorCode = ErrorCode.emailInUse;
        }
        resolve({ success: false, error: errorCode } as IApiResponse);
      });
  });
};

export const loginWithEmailAndPassword = (
  email: string,
  password: string
): Promise<IApiResponse> => {
  return new Promise(function (resolve, reject) {
    firebaseAuth()
      .signInWithEmailAndPassword(email, password)
      .then((response) => {
        if (response.user) {
          resolve({
            success: true,
            user: response.user as IUser,
          } as IApiResponse);
        } else {
          resolve({
            success: false,
            error: ErrorCode.serverError,
          } as IApiResponse);
        }
      })
      .catch((error: any) => {
        let errorCode: ErrorCode = ErrorCode.serverError;
        switch (error.code) {
          case 'auth/wrong-password':
            errorCode = ErrorCode.invalidPassword;
            break;
          case 'auth/network-request-failed':
            errorCode = ErrorCode.serverError;
            break;
          case 'auth/user-not-found':
            errorCode = ErrorCode.noUser;
            break;
          default:
            errorCode = ErrorCode.serverError;
            break;
        }
        resolve({ success: false, error: errorCode } as IApiResponse);
      });
  });
};

export const loginOrSignupWithGoogle = (): Promise<IApiResponse> => {
  return new Promise(function (resolve, reject) {
    firebaseAuth()
      .signInWithPopup(authProviders.google)
      .then((response) => {
        if (response.user) {
          const isNewUser = response.additionalUserInfo?.isNewUser;
          if (isNewUser) {
            const { uid, email, phoneNumber } = response.user;
            const profile = response.additionalUserInfo?.profile;
            let userData: any = {
              createdAt: firebase.firestore.FieldValue.serverTimestamp(),
              id: uid,
              email: email || '',
              phone: phoneNumber || '',
              firstName: '',
              lastName: '',
            };
            if (profile) {
              userData.firstName =
                /* @ts-ignore */
                profile['given_name'] || profile['first_name'];
              userData.lastName =
                /* @ts-ignore */
                profile['family_name'] || profile['last_name'];
            }
            db.collection('users')
              .doc(uid)
              .set(userData)
              .then(() => {
                resolve({
                  success: true,
                  data: response.user as IUser,
                } as IApiResponse);
              })
              .catch(() => {
                resolve({
                  success: false,
                  error: ErrorCode.serverError,
                } as IApiResponse);
              });
          } else {
            resolve({
              success: true,
              data: response.user as IUser,
            } as IApiResponse);
          }
        } else {
          resolve({
            success: false,
            error: ErrorCode.serverError,
          } as IApiResponse);
        }
      })
      .catch((error: any) => {
        resolve({
          success: false,
          error: ErrorCode.googleAuthFailed,
        } as IApiResponse);
      });
  });
};

export const sendPasswordResetEmail = (
  email: string
): Promise<IApiResponse> => {
  return new Promise(function (resolve, reject) {
    firebaseAuth()
      .sendPasswordResetEmail(email)
      .then(() => {
        resolve({ success: true });
      })
      .catch((error: any) => {
        let errorCode: ErrorCode = ErrorCode.serverError;
        if (error.code === 'auth/user-not-found') {
          errorCode = ErrorCode.noUser;
        }
        resolve({ success: false, error: errorCode } as IApiResponse);
      });
  });
};

export const signOut = () => {
  firebaseAuth().signOut();
};
