import React from 'react';

export const PrivacyPolicy: React.FC = () => {
  return (
    <div className="section pt-10 md:pt-20">
      <h1 className="section-title">PRIVACY POLICY - CANADA CADOH CONSUMERS</h1>
      <div className="max-w-screen-md mx-auto">
        <div className="font-sans text-xl md:text-2xl text-black-90 my-6 md:my-8">
          Effective May 1, 2021
        </div>
        <p className="section-text">
          Cadoh Delivery, Inc. (“Cadoh,” “we,” “us,” or “our”) is committed to
          protecting your privacy. This Privacy Policy (“Policy”) explains how
          we collect, use, disclose and protect Personal Information (as it is
          defined below) of our customers and users. It applies to all Personal
          Information collected by us on www.cadoh.ca (the “Site”), the Cadoh
          mobile application, and any other website or mobile application that
          links to this Policy, as well as during any written, electronic, and
          oral communications (collectively, the “Services”). Cadoh may collect
          Personal Information to help you place and receive orders and to
          improve the Cadoh service.
          <br />
          Your access to and use of our Services are subject to our{' '}
          <span className="font-bold">Terms of Use</span>, which define some of
          the terms used throughout this Policy. Please make sure that you have
          carefully read and understand the Terms of Use before you use our
          Services. By using our Services, you accept the Terms of Use, and
          accept our privacy practices described in this Policy. If you do not
          feel comfortable with any part of this Policy or our Terms of Use, you
          must not use or access our Services.
          <br />
          The Company uses a network of independent third-party contractors
          (“Drivers”) who provide delivery services to our users and floral
          service providers. Drivers should refer to the{' '}
          <span className="font-bold">Driver Privacy Statement</span> for
          information on how Cadoh collects and uses Driver’s Personal
          Information.
          <br />
          Our Services may change from time to time. As a result, we reserve the
          right to update or modify this Policy at any time and from time to
          time without prior notice. If we make any material changes to the way
          we process your Personal Information, we will notify you before such
          changes are effective. If you object to any changes you must cease
          using our Services. We recommend you review this Policy periodically.
          This Policy became effective on the date indicated at the top of this
          page. Your continued use of our Services after any changes or
          revisions to this Policy indicates your agreement to the terms of the
          revised Policy.
        </p>
        <p className="section-text">&nbsp;</p>
        <p className="section-text">
          <span className="font-bold">
            1. Collection of Personal Information
          </span>
          <br />
          When you use our Services, we may collect or receive Personal
          Information about you to provide our services and operate our
          business. The types of Personal Information that we may collect about
          you are:
          <br />
          a.{' '}
          <span className="italic underline">
            Information You Provide to Us Directly
          </span>
          <br />
          You may provide your Personal Information to us when you use our
          Services, including when you register with Cadoh, search within or
          place orders through our Services, contact us with inquiries, respond
          to surveys, or otherwise access our Services. This information may
          include:
        </p>
        <ul className="section-text pl-8" style={{ listStyle: 'disc' }}>
          <li>
            <span className="italic">User Profile</span> – We collect profile
            associated with your Customer Cadoh account. This includes
            information such as name, email address, delivery address, and phone
            number.
          </li>
          <li>
            <span className="italic">Order Details</span> – When you place an
            order through our Services we collect information related to that
            order. This may include information such as items purchased, special
            instructions, date and time of order, and subtotal.
          </li>
          <li>
            <span className="italic">Payment Information</span> – Payment
            information is collected such as bank name and payment method.
          </li>
          <li>
            <span className="italic">Surveys</span> – From time-to-time, we
            invite our customers and users to participate in surveys or
            contests. When a customer or a user participates, we request certain
            Personal Information such as name and email address.{' '}
          </li>
        </ul>
        <p className="section-text">
          b.{' '}
          <span className="italic underline">
            Information Received From You as Part of Your Communications
          </span>
          <br />
          When you use our Services (for example on our Site), complete
          electronic forms, communicate with Drivers or contact us, by online
          chat, email, phone or text, we may automatically collect and store
          certain information about you and the activity you engaged in, for
          example: your name and contact information; your order details;
          information that you voluntarily provide to us; the nature of your
          communication; the purpose of the interaction, and the action we took
          in response to your inquiry or request.
        </p>
        <p className="section-text">
          c.{' '}
          <span className="italic underline">
            Information Collected Automatically
          </span>
          <br />
          We also may receive and store certain information about you and your
          device(s) automatically when you access or use our Site and Services.
          This information may include:
        </p>
        <p className="section-text">
          d. <span className="italic underline">Location Information</span>
          <br />
          When you use the Services, we may collect general location information
          (such as IP address). If you permit the Services to access location
          services through the permission system used by your mobile operating
          system (“Platform”) or browser, we may also collect the precise
          location of your device when the Cadoh app is running in the
          foreground or background of your device. This information may be used
          to help you select your delivery address, to provide more accurate
          deliveries of your orders, to provide recommendations to you, and to
          enhance your user experience and improve the services. You can choose
          whether to enable the location tracking feature through the settings
          on your device or Platform or when prompted by the Cadoh mobile app.
          If you choose to disable the location feature, the Driver will not
          receive precise location information from your device, which may
          compromise the accuracy of deliveries in some situations, for
          instance, if you are located in a large area, such as a park.
        </p>
        <p className="section-text">
          e.{' '}
          <span className="italic underline">
            Personal Information from Publicly Available Sources and Third
            Parties
          </span>
          <br />
          We may collect or receive Personal Information about you from publicly
          available sources, social network providers, marketing partners,
          and/or third parties. This may include:
        </p>
        <ul className="section-text pl-8" style={{ listStyle: 'disc' }}>
          <li>
            <span className="italic">Demographic Information</span> – This
            characteristic information could include information such as income,
            marital status, spending habits, and other information pertaining to
            customers.
          </li>
        </ul>
        <p className="section-text">&nbsp;</p>
        <p className="section-text">
          <span className="font-bold">
            2. Use of Cookies and Other Tracking Technologies
          </span>
          <br />
          We use cookies, web beacons, pixels, and similar technologies to
          collect information and personalize your experience with our Services.
          <br />
          a. <span className="italic underline">Cookies</span>
          <br />
          Cookies are small web files that a site or its provider transfers to
          your device’s hard drive through your web browser that enables the
          site’s or provider’s system to recognize your browser and remember
          certain information.
          <br />
          <span className="italic underline">How We Use Cookies</span> – We use
          first-party and third-party cookies for the following purposes:
        </p>
        <ul className="section-text pl-8" style={{ listStyle: 'disc' }}>
          <li>to make our Services function properly;</li>
          <li>to improve our Services;</li>
          <li>
            to recognize you when you return to Cadoh and to remember
            information you have already provided, such as items already in your
            order cart;
          </li>
          <li>
            to collect information about your activities over time and across
            third party websites or other online services in order to deliver
            content and advertising tailored to your interests; and
          </li>
          <li>
            to provide a secure browsing experience during your use of our
            Services.
          </li>
        </ul>
        <p className="section-text">
          <span className="italic underline">How to Manage Cookies</span> – You
          may disable the use of cookies by modifying your browser settings. If
          you choose to disable cookies you may not be able to fully experience
          the interactive features of our Services.
        </p>
        <p className="section-text">
          b. <span className="italic underline">Web Beacons</span>
          <br />
          Web beacons, also known as web bugs, pixel tags or clear GIFs, are
          tiny graphics with a unique identifier that may be included on our
          Site to deliver or communicate with cookies, in order to track and
          measure the performance of our Site and Services, monitor how many web
          visitors we have, and to monitor the effectiveness of our advertising.
          Unlike cookies, which are stored on the user’s hard drive, web beacons
          are typically embedded invisibly on webpages (or in an email).
        </p>
        <p className="section-text">
          c.{' '}
          <span className="italic underline">
            Online Analytics and Advertising Technologies
          </span>
          <br />
          We and our third-party vendors may use automated technologies
          (including cookie identifiers on our Site), along with other collected
          information, to tailor ads or deliver content when you are on our
          Services or on other devices, apps or websites.
        </p>
        <p className="section-text">
          d.{' '}
          <span className="italic underline">Interest-Based Advertising</span>
          <br />
          We (or our service providers) may use the information we collect, for
          instance, IP addresses and unique mobile device identifiers, to locate
          or try to locate the same unique users across multiple browsers or
          devices (such as smartphones, tablets, or computers), or work with
          providers that do this, in order to better tailor content and features
          and provide you with a seamless experience across devices. If you wish
          to opt out of cross device tracking for purposes of interest-based
          advertising, you may do so through your device settings. We may also
          use cross-device targeting to help identify our users and serve
          advertising. This type of advertising is often called “interest-based”
          or “personalized” advertising—and when it occurs on mobile apps,
          “cross-app” advertising.
          <br />
          You can learn more about interest-based advertising and how to opt-out
          of receiving tailored advertising by visiting (i) the Network
          Advertising Initiative’s Consumer{' '}
          <a
            className="link-text underline"
            href="https://optout.networkadvertising.org/?c=1"
            target="_blank"
            rel="noreferrer"
          >
            Opt-Out link
          </a>{' '}
          or (ii) the Digital Advertising Alliance’s Consumer{' '}
          <a
            className="link-text underline"
            href="https://optout.networkadvertising.org/?c=1"
            target="_blank"
            rel="noreferrer"
          >
            Opt-Out link
          </a>
          . To opt out of Google Analytics for display advertising or customize
          Google display network ads, you can visit the{' '}
          <a
            className="link-text underline"
            href="https://adssettings.google.com/authenticated"
            target="_blank"
            rel="noreferrer"
          >
            Google Ads Settings page
          </a>
          .<br />
          Please note that even if you opt-out, you may still receive
          advertisements from us. However, in that case, the advertising will
          not be tailored to your interests. Also, we do not control any of the
          above opt-out links or whether any particular company chooses to
          participate in these opt-out programs.
        </p>
        <p className="section-text">
          e. <span className="italic underline">Mobile Applications</span>
          <br />
          Depending on your permissions, we may receive your Personal
          Information from your Internet service and mobile device providers.
          Users of mobile device who do not want to receive interest-based
          advertising may opt-out in several ways. Learn more about your choices
          for mobile devices by visiting{' '}
          <a
            className="link-text underline"
            href="http://www.aboutads.info/appchoices"
            target="_blank"
            rel="noreferrer"
          >
            http://www.aboutads.info/appchoices
          </a>
          . Each operating system, (iOS for Apple phones, Android for Android
          devices, and Windows for Microsoft devices) provides its own
          instructions on how to prevent the delivery of tailored in-application
          advertisements. You should view your device or system “Settings” to
          determine how you can opt out of use of your device ID for “cross-app”
          personalized advertising.
        </p>
        <p className="section-text">&nbsp;</p>
        <p className="section-text">
          <span className="font-bold">3. Use of Your Personal Information</span>
          <br />
          We may use the information we collect or receive about you for various
          purposes.
          <br />
          a.{' '}
          <span className="italic underline">
            To Provide You With Our Services
          </span>
          <br />
          We use your information to provide you the Services. For example, we
          use credit card information to complete a transaction or an address to
          allow the Driver to fulfill your delivery. If the applicable
          information is to be provided or Service is to be performed by a third
          party, then we will disclose the applicable Personal Information to
          the third party providing the information or performing applicable
          Services. Your information may be available or provided to third-party
          service providers and that are contractually obligated to protect your
          information as disclosed in this Policy.
          <br />
          b.{' '}
          <span className="italic underline">
            To Maintain, Improve, and Personalize the Services
          </span>
          <br />
          We use your information for our everyday business operations such as
          auditing, administration of the Services, forum management,
          fulfillment, and analytics. Your information may also be used to
          improve the content and/or functionality of the Services. For example,
          it may be used to help us and/or our Vendors improve our offerings. We
          also use your information to personalize your experience. For example,
          we may personalize the content and features you see when visiting our
          Services. In addition, we may personalize advertisements, marketing
          communications, and recommendations to make them more tailored to your
          interests.
          <br />
          c. <span className="italic underline">To Communicate with You</span>
          <br />
          We use your information to communicate with you. For example, we may
          send you text messages or other notifications about the status of your
          orders and deliveries. We may also contact you with promotional
          offerings or other communications that may be of interest to you. If
          we send you marketing emails about services that may interest you,
          each email will contain instructions permitting you to “opt-out” of
          receiving future marketing or other communications.
          <br />
          In the event you contact us, we use certain information to respond to
          your questions and assist you with any issues that may arise in your
          use of the Services. If you send text messages to a Driver, using the
          telephone number for that Driver available on the Services, we may use
          a third-party service provider to track these text messages. We track
          these text messages for fraud prevention, to ensure appropriate
          charging of fees, to enforce our Terms of Use, and for quality and
          training purposes. As part of this process, Cadoh and its service
          provider will receive in real time and store data about your text
          message, including the date and time of the text message, your phone
          number, and the content of the text message.
          <br />
          d.{' '}
          <span className="italic underline">
            For Account and Network Security Purposes
          </span>
          <br />
          We care about keeping you secure and safe while using our Services.
          Keeping you safe requires us to process your Personal Information,
          such as your device information, activity information and other
          relevant information. We use such information to combat spam, malware,
          malicious activities or security risks; improve and enforce our
          security measures; and to monitor and verify your identity so that
          unauthorized users do not gain access to your information.
          <br />
          e.{' '}
          <span className="italic underline">
            To Maintain Legal and Regulatory Compliance
          </span>
          <br />
          Our Services are subject to certain laws and regulations which may
          require us to process your Personal Information. For example, we
          process your Personal Information to pay our taxes, to fulfill our
          business obligations, ensure compliance with employment and
          recruitment laws or as necessary to manage risk as required under
          applicable law.
          <br />
          f.{' '}
          <span className="italic underline">
            To Enforce Compliance with Our Terms of Use and Policies
          </span>
          <br />
          When you access or use our Services, you are bound to our Terms of Use
          and this Policy. To ensure you comply with them, we process your
          Personal Information by actively monitoring, investigating, preventing
          and mitigating any alleged or actual prohibited, illicit or illegal
          activities on our Services. We also process your Personal Information
          to: investigate, prevent or mitigate violations of our internal terms,
          agreements or policies; enforce our agreements with third parties and
          business partners.
          <br />
          g.{' '}
          <span className="italic underline">
            To Protect You, Others, and Our Business
          </span>
          <br />
          We use your information to protect you, others, and our business,
          including, without limitation, using information for fraud prevention,
          for enforcement of our Terms of Service, to comply with the law, and
          to protect against security risks.
          <br />
          h. <span className="italic underline">For Our Business Purposes</span>
          <br />
          We may use your information for any other purpose disclosed to you at
          the time we collect or receive the information, or otherwise with your
          consent.
        </p>
        <p className="section-text">&nbsp;</p>
        <p className="section-text">
          <span className="font-bold">
            4. Sharing of Personal Information with Third Parties
          </span>
          <br />
          Your Personal Information is not shared with third parties without
          your permission, except as described below.
          <br />
          a. <span className="italic underline">Service Providers</span>
          <br />
          We may share your information with our third-party service providers
          for certain business purposes. This information is provided in order
          for them to provide us services such as payment processing,
          advertising services, marketing partners, web analytics, data
          processing, IT services, customer support and other services. These
          third-party service providers have access to your Personal Information
          only for the purpose of performing services on our behalf and are
          expressly obligated not to disclose or use your Personal Information
          for any other purpose.
          <br />
          b. <span className="italic underline">Drivers and Vendors</span>
          <br />
          To help provide you with a high-quality delivery we also may share
          your information, including your Personal Information and order
          details, with the Drivers who complete your requested deliveries.
          Similarly, we may share your name, phone number, order information,
          customer feedback and other information related to your orders with
          Cadoh vendors.
          <br />
          c. <span className="italic underline">Related Entities</span>
          <br />
          We may share your information with our affiliates (entities that
          control or are under the control of Cadoh) for business purposes. We
          will require these entities to comply with the terms of this Policy
          with regard to their use of your information.
          <br />
          d. <span className="italic underline">When Required by Law</span>
          <br />
          We recognize that information related to your orders could contain
          private information. However, we may be required to disclose your
          information to third parties including law enforcement agencies when
          required to protect and defend our legal rights, protect the safety
          and security of users of our Services, prevent fraud, comply with the
          law, respond to legal process, or a request for cooperation by a
          government entity.
          <br />
          e. <span className="italic underline">Corporate Transactions</span>
          <br />
          In the event of sale, transfer, merger, reorganization, dissolution,
          or similar event we may transfer your information to one or more third
          parties as part of that transaction.
          <br />
          f. <span className="italic underline">With Your Consent</span>
          <br />
          We also may share or disclose your information with your consent or at
          your direction.
        </p>
        <p className="section-text">&nbsp;</p>
        <p className="section-text">
          <span className="font-bold">5. Security</span>
          <br />
          Cadoh has implemented administrative, technical, and physical security
          controls that are designed to safeguard Personal Information. However,
          no online activity is ever fully secure or error-free. While we strive
          to protect your information, we cannot guarantee that your Personal
          Information is absolutely secure. Please keep this in mind when
          disclosing any information to Cadoh.
          <br />
          Please recognize that protecting your Personal Information is also
          your responsibility. We urge you to take every precaution to protect
          your information when you are on the Internet, or when you communicate
          with us and with other through the Internet. Change your passwords
          often, use a combination of letters and numbers, and make sure you use
          a secure browser. If you have reason to believe that your interaction
          with us is no longer secure (for example, if you feel that the
          security of your account might have been compromised), or if you
          suspect someone else is using your account, please let us know
          immediately by contacting us.
        </p>
        <p className="section-text">&nbsp;</p>
        <p className="section-text">
          <span className="font-bold">6. Data Retention</span>
          <br />
          We will retain your Personal Information for as long as your account
          is active or as needed to provide you services and to maintain a
          record of your transactions for financial reporting purposes. We will
          also retain and use your Personal Information as necessary to comply
          with our legal obligations, resolve disputes, and enforce our
          agreements.
        </p>
        <p className="section-text">&nbsp;</p>
        <p className="section-text">
          <span className="font-bold">7. Third-Party Sites</span>
          <br />
          Some of the functionality may be provided by third parties and the
          Services may link to other third-party websites that are not
          controlled by Cadoh. These third parties are not under our control and
          Cadoh is not responsible for their privacy policies or practices. If
          you provide any Personal Information to any third party or through any
          such third-party website, we recommend that you familiarize yourself
          with the privacy policies and practices of that third party.
        </p>
        <p className="section-text">&nbsp;</p>
        <p className="section-text">
          <span className="font-bold">8. Social Media Usage</span>
          <br />
          You may choose to enable or log in to our Services via various online
          services, including social networking services like Google. Our
          Services also may enable you to access social networking services such
          as Facebook, Twitter, or Instagram (collectively, “Social Network”)
          directly or indirectly through our Services. When you link a Social
          Network account to Cadoh or log into our Services using your Social
          Network account, we may collect relevant Personal Information
          necessary to enable our Services to access that Social Network and
          your data contained within that Social Network. We also may share your
          information with the operator of that Social Network to facilitate or
          enhance delivery of that Social Network or other services to you. A
          Social Network may provide us with access to certain information that
          you have provided to them, and we will use, store, and disclose such
          information in accordance with this Policy. Additionally, a Social
          Network may collect Personal Information and information about your
          use of our Site or Services automatically. The manner in which a
          Social Network collects, uses, stores, and discloses your information
          is governed by the policies of such third parties and Cadoh shall have
          no liability or responsibility for the privacy practices or other
          actions of any Social Network that may be enabled within our Services.
          You may also have the option of posting your activities on our
          Services and other content to a Social Network when you access content
          through our Services. For example, you may post to Facebook that you
          placed an order with Cadoh. Keep in mind that your usage of Social
          Network features is governed by applicable Social Networks and not by
          Cadoh, and may be visible or accessible to the public.
        </p>
        <p className="section-text">&nbsp;</p>
        <p className="section-text">
          <span className="font-bold">9. Children’s Information</span>
          <br />
          Our Services are not intended for children under 13 years of age and
          we do not knowingly collect Personal Information from children under
          the age of 13. If you are a parent or guardian of a child under the
          age of 13 and believe he or she has disclosed personally identifiable
          information to us please contact us at{' '}
          <a className="link-text underline" href="mailto:support@cadoh.ca">
            support@cadoh.ca
          </a>
          . A parent or guardian of a child under age 13 may review and request
          deletion of the child’s Personal Information.
        </p>
        <p className="section-text">&nbsp;</p>
        <p className="section-text">
          <span className="font-bold">10. Your Choices</span>
          <br />
          We provide our customers and their authorized agents with the ability
          to access and delete Personal Information. In order to exercise these
          rights you must login to your account to confirm your identity, which
          helps us ensure that Personal Information is only made accessible to
          appropriate parties. Customers will not receive discriminatory
          treatment for exercising their rights and can return to the service
          after deleting their information.
          <br />
          a. <span className="italic underline">Your Rights</span>
          <br />
          This section lists the privacy-related rights (“Rights”) we extend to
          all Cadoh customers. Your right to know and right to deletion are not
          absolute and are subject to certain exceptions. For instance, we
          cannot disclose specific pieces of Personal Information if the
          disclosure would create a substantial, articulable, and unreasonable
          risk to the security of the Personal Information, your account with us
          or the security of our systems.
        </p>
        <ul className="section-text pl-8" style={{ listStyle: 'disc' }}>
          <li>
            <span className="italic underline">Right to Know</span> – You have
            the right to know the Personal Information we collect, use,
            disclose, and sell about you.
          </li>
          <li>
            <span className="italic underline">Right to Deletion</span> – You
            have the right to request that we delete any Personal Information we
            have collected from you or maintain about you. We may save Personal
            Information when permitted by applicable law including, without
            limitation, when the information is needed for a legal purpose.
          </li>
          <li>
            <span className="italic underline">Right to Rectification</span> –
            If you are a registered Cadoh user you may modify certain of your
            Personal Information by logging into our Site using your username
            and password and visiting the “Account” page.
          </li>
          <li>
            <span className="italic underline">
              Right to Opt-Out of the Sale of Your Personal Information
            </span>{' '}
            – You have the right to opt-out of having your Personal Information
            sold. Cadoh does not sell or rent Personal Information to third
            parties.
          </li>
          <li>
            <span className="italic underline">
              Right to Non-Discrimination
            </span>{' '}
            – Cadoh will not discriminate against those who exercise their
            Rights. Specifically, if you exercise your Rights, we will not deny
            you goods or Services, charge you different prices or rates for
            goods or Services or provide you a different level or quality of
            goods or Services.
          </li>
        </ul>
        <p className="section-text">
          b. <span className="italic underline">Asserting Your Rights</span>
          <br />
          You may exercise your right to know or your right to deletion by{' '}
          <a
            className="link-text underline"
            href="https://www.doordash.com/consumer/privacy/manage_account"
          >
            clicking here
          </a>
          . To verify your identity, you must log-in to your account prior to
          accessing or deleting your information. You may also designate an
          authorized agent to make a request to know or a request to delete. In
          order to be able to act, authorized agents have to submit proof that
          they are authorized to act on your behalf, or have a power of
          attorney. We may deny requests from authorized agents who do not
          submit proof that they have been authorized by you to act on their
          behalf.
        </p>
        <p className="section-text">&nbsp;</p>
        <p className="section-text">
          <span className="font-bold">11. International Users</span>
          <br />
          Cadoh is based in Canada and, regardless of where you use our
          Services, the information collected as part of that use will be
          transferred to and maintained on servers located in Canada. Any
          information we obtain about you will be stored in accordance with
          Canada privacy laws, regulations, and standards, which may not be
          equivalent to the laws in your country of residence. By using our
          Services, you consent to this collection, transfer, storage, and
          processing of information to and in Canada.
        </p>
        <p className="section-text">&nbsp;</p>
        <p className="section-text">
          <span className="font-bold">12. Contact Us</span>
          <br />
          If you have any questions or concerns relating to this Policy or our
          privacy practices please contact us at:{' '}
          <a className="link-text underline" href="mailto:support@cadoh.ca">
            support@cadoh.ca
          </a>
        </p>
      </div>
    </div>
  );
};
