import React from 'react';
import { OrderStepCard } from '@components';
import OrderStep1 from '@assets/images/order-step1.png';
import OrderStep2 from '@assets/images/order-step2.png';
import OrderStep3 from '@assets/images/order-step3.png';

export const OrderSteps: React.FC = () => {
  return (
    <div className="section">
      <h1 className="section-title text-center mb-4 md:mb-6">
        How to send flowers in 3 easy steps
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10 md:gap-12">
        <OrderStepCard
          title="Enter an address"
          subTitle="Add the details of your desired delivery address and preferred date
            and time."
          image={OrderStep1}
        />
        <OrderStepCard
          title="Shop local florists"
          subTitle="Explore local flower shops around the address and the unique styles
          of each florist."
          image={OrderStep2}
        />
        <OrderStepCard
          title="Live track your order"
          subTitle="Once an order is placed with a card note and instructions, track
          your flowers as they are delivered!"
          image={OrderStep3}
        />
      </div>
    </div>
  );
};
