import React from 'react';
import LogoImage from '@assets/images/logo-image.png';

export const LoadingView: React.FC = () => {
  return (
    <div
      className="fixed top-0 left-0 bottom-0 right-0 flex justify-center items-center bg-gray-500 bg-opacity-40"
      style={{ zIndex: '9999' }}
    >
      <img className="h-8" src={LogoImage} alt="Cadoh" />
    </div>
  );
};
