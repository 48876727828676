import { ActionTypes, Actions } from '../../actions/types';
import { CheckoutState } from './interface';

const intialState: CheckoutState = {
  deliveryAddress: null,
  deliveryTime: null,
  sender: null,
  receiver: null,
  deliveryInstructions: null,
  specialInstructions: null,
  cardNote: null,
};

const checkoutReducer = (
  state: CheckoutState = intialState,
  action: Actions
): CheckoutState => {
  switch (action.type) {
    case ActionTypes.SET_DELIVERY_ADDRESS:
      return {
        ...state,
        deliveryAddress: action.payload,
      };
    case ActionTypes.SET_DELIVERY_TIME:
      return {
        ...state,
        deliveryTime: action.payload,
      };
    case ActionTypes.SET_SENDER:
      return {
        ...state,
        sender: action.payload,
      };
    case ActionTypes.SET_RECEIVER:
      return {
        ...state,
        receiver: action.payload,
      };
    case ActionTypes.SET_DELIVERY_INSTRUCTIONS:
      return {
        ...state,
        deliveryInstructions: action.payload,
      };
    case ActionTypes.SET_CARD_NOTE:
      return {
        ...state,
        cardNote: action.payload,
      };
    case ActionTypes.SET_SPECIAL_INSTRUCTIONS:
      return {
        ...state,
        specialInstructions: action.payload,
      };
    case ActionTypes.ADD_UNIT_NUMBER:
      return {
        ...state,
        deliveryAddress: state.deliveryAddress
          ? {
              ...state.deliveryAddress,
              line2: action.payload,
            }
          : state.deliveryAddress,
      };
    default:
      return state;
  }
};

export default checkoutReducer;
export * from './interface';
