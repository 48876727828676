import { IApiResponse } from '@models';
import { AxiosInstance } from './api-config';

export const createStripeCustomer = async (
  email: string
): Promise<IApiResponse> => {
  try {
    const response = await AxiosInstance.post('/create-stripe-customer', {
      email,
    });
    if (response.data) {
      return { success: true, data: response.data.customer.id } as IApiResponse;
    }
    return { success: false } as IApiResponse;
  } catch (e) {
    console.log(e);
    return { success: false } as IApiResponse;
  }
};

export const addNewPaymentSource = async (
  customerId: string,
  token: any
): Promise<IApiResponse> => {
  try {
    const response = await AxiosInstance.post('/add-payment-source', {
      customerId,
      token,
    });
    return { success: true, data: response.data } as IApiResponse;
  } catch (e) {
    console.log(e);
    return { success: false } as IApiResponse;
  }
};

export const chargeStripeCustomer = async (data: {
  customerId?: string;
  currency: 'cad' | 'usd';
  amount: number;
  source: string;
  uuid: string;
}): Promise<IApiResponse> => {
  try {
    const response = await AxiosInstance.post('/charge-stripe-customer', data);
    console.log('response ', response);
    return { success: true } as IApiResponse;
  } catch (e) {
    console.log(e);
    return { success: false } as IApiResponse;
  }
};
