import { IFormattedPlace, IReceiver, ISender } from '@models';
import {
  SetDeliveryAddressAction,
  SetDeliveryTimeAction,
  SetSenderAction,
  SetReceiverAction,
  SetCardNoteAction,
  SetDeliveryInstructionsAction,
  SetSpecialInstructionsAction,
  AddUnitNumberAction,
} from './interface';
import { ActionTypes } from '../types';

export const setDeliveryAddress = (
  address: IFormattedPlace | null | undefined
): SetDeliveryAddressAction => {
  return {
    type: ActionTypes.SET_DELIVERY_ADDRESS,
    payload: address,
  };
};

export const setDeliveryTime = (
  deliveryTime: Date | null | undefined
): SetDeliveryTimeAction => {
  return {
    type: ActionTypes.SET_DELIVERY_TIME,
    payload: deliveryTime,
  };
};

export const setSender = (sender: ISender): SetSenderAction => {
  return {
    type: ActionTypes.SET_SENDER,
    payload: sender,
  };
};

export const setReceiver = (receiver: IReceiver): SetReceiverAction => {
  return {
    type: ActionTypes.SET_RECEIVER,
    payload: receiver,
  };
};

export const setDeliveryInstructions = (
  deliveryInstructions: string
): SetDeliveryInstructionsAction => {
  return {
    type: ActionTypes.SET_DELIVERY_INSTRUCTIONS,
    payload: deliveryInstructions,
  };
};

export const setCardNote = (cardNote: string): SetCardNoteAction => {
  return {
    type: ActionTypes.SET_CARD_NOTE,
    payload: cardNote,
  };
};

export const setSpecialInstrunctions = (
  specialInstructions: string
): SetSpecialInstructionsAction => {
  return {
    type: ActionTypes.SET_SPECIAL_INSTRUCTIONS,
    payload: specialInstructions,
  };
};

export const addUnitNumber = (unitNumber: string): AddUnitNumberAction => {
  return {
    type: ActionTypes.ADD_UNIT_NUMBER,
    payload: unitNumber,
  };
};

export * from './interface';
