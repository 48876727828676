import { store } from 'react-notifications-component';
import { INotification } from '@models';

export * from './deliveryTime';

export const showSnackBar = (param: INotification) => {
  store.addNotification({
    title: param.title || '',
    message: param.message || ' ',
    type: param.type || 'success',
    insert: 'top',
    container: 'top-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 4000,
      showIcon: true,
      onScreen: true,
    },
  });
};
