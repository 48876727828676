import React from 'react';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
import { IVendor } from '@models';

interface Props {
  vendors: IVendor[];
}

export const Partners: React.FC<Props> = ({ vendors }) => {
  const navigate = useNavigate();

  const settings = {
    dots: false,
    infinite: vendors.length > 2,
    slidesToShow: 6,
    slidesToScroll: 6,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 4000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 319,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="section">
      <h1 className="section-title text-center mb-10">Florist Partners</h1>
      <div className="px-8">
        <Slider {...settings}>
          {vendors
            .filter((vendor) => vendor.logoImage)
            .map((vendor) => (
              <img
                key={vendor.id}
                onClick={() => navigate(`/vendors/${vendor.id}`)}
                className="w-24 h-24 cursor-pointer"
                src={vendor.logoImage}
                alt=""
              />
            ))}
        </Slider>
      </div>
    </div>
  );
};
