import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  fetchFromFirebaseFunctions,
  SEND_CONTACT_US_EMAIL,
} from '@services/functions';

export const Contact: React.FC = () => {
  const [messageSent, setMessageSent] = useState<boolean>(false);
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      message: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    }),
    onSubmit: (values) => {
      fetchFromFirebaseFunctions(SEND_CONTACT_US_EMAIL, {
        name: values.name,
        email: values.email,
        message: values.message,
      }).then(() => {
        setMessageSent(true);
      });
    },
  });

  return (
    <div className="section pt-10 md:pt-20">
      <div className="max-w-screen-md mx-auto">
        <h1 className="section-title text-center">
          Want to become a flower partner? A driver? Have a question about Cadoh
          and want to chat?
        </h1>
        <div className="mt-10">
          {!messageSent ? (
            <form onSubmit={formik.handleSubmit}>
              <div className="my-8">
                <input
                  className="w-full p-2 focus:outline-none font-sans text-base md:text-xl text-black-100 border-b border-primary-30"
                  placeholder="Name"
                  id="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="my-8">
                <input
                  className="w-full p-2 focus:outline-none font-sans text-base md:text-xl border-b border-primary-30"
                  placeholder="Email*"
                  id="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="font-sans text-sm text-red-600">
                    {formik.errors.email}
                  </div>
                ) : null}
              </div>
              <div className="my-8">
                <textarea
                  className="w-full h-40 p-2 focus:outline-none font-sans text-base md:text-xl border border-primary-30 rounded"
                  placeholder="Message"
                  id="message"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="flex justify-center mt-4">
                <button
                  className="px-4 md:px-10 py-2 md:py-3 font-sans text-sm md:text-md font-bold uppercase text-primary-90 hover:text-black-100 tracking-widest rounded-full border border-primary-90 bg-white hover:bg-primary-90 duration-200"
                  type="submit"
                >
                  SEND
                </button>
              </div>
            </form>
          ) : (
            <div className="font-sans text-2xl text-primary-90 text-center">
              Thank you for contacting Cadoh! We will get back to you as quickly
              as possible.
            </div>
          )}
          <div className="font-sans text-xs text-black-90 text-center mt-10">
            This site is protected by reCAPTCHA and the Google{' '}
            <Link
              className="link-text text-xs"
              to="https://policies.google.com/privacy"
            >
              Privacy Policy{' '}
            </Link>
            and{' '}
            <Link
              className="link-text text-xs"
              to="https://policies.google.com/terms"
            >
              Terms of Service
            </Link>{' '}
            apply.
          </div>
        </div>
      </div>
    </div>
  );
};
