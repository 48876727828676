import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Input, LoadingView } from '@components';
import { ErrorCode } from '@models';
import {
  loginWithEmailAndPassword,
  loginOrSignupWithGoogle,
} from '@services/auth';
import { showSnackBar } from '@utils';
import { ReactComponent as GoogleIcon } from '@assets/icons/google.svg';
import LogoImage from '@assets/images/logo-image.png';

export const Login: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
      password: Yup.string().required('Password is required'),
    }),
    onSubmit: (values) => {
      setLoading(true);
      loginWithEmailAndPassword(values.email, values.password).then(
        (response) => {
          setLoading(false);
          if (response.success) {
            navigate(from, { replace: true });
          } else {
            let message = '';
            if (response.error === ErrorCode.invalidPassword) {
              message = 'Invalid password';
            } else if (response.error === ErrorCode.noUser) {
              message = 'User not exist';
            } else {
              message = 'Something went wrong';
            }
            showSnackBar({
              title: 'Error',
              message,
              type: 'danger',
            });
          }
        }
      );
    },
  });

  const handleGoogleSignin = () => {
    setLoading(true);
    loginOrSignupWithGoogle().then((response) => {
      setLoading(false);
      if (response.success) {
        navigate(from, { replace: true });
      } else {
        let message = 'Something went wrong';
        showSnackBar({
          title: 'Error',
          message,
          type: 'danger',
        });
      }
    });
  };

  return (
    <div className="section">
      <div className="max-w-lg mx-auto pt-10">
        <div className="flex flex-col items-center">
          <img className="h-10" src={LogoImage} alt="Cadoh" />
          <div className="font-sans font-semibold text-3xl text-black-100 text-center mt-4">
            Sign In
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="mt-4">
            <Input
              id="email"
              caption="Email Address"
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="email"
              error={
                formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : null
              }
            />
          </div>
          <div className="mt-4">
            <Input
              id="password"
              caption="Password"
              fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="password"
              error={
                formik.touched.password && formik.errors.password
                  ? formik.errors.password
                  : null
              }
            />
          </div>
          <div className="flex justify-end items-center">
            <div className="link-text">
              <Link style={{ fontSize: '16px' }} to="/forgot-password">
                Forgot Password?
              </Link>
            </div>
          </div>
          <div className="px-10 md:px-20 py-10">
            <Button label="Sign In" type="submit" fullWidth />
            <div className="font-sans font-semibold text-xl text-primary-90 text-center my-4">
              or
            </div>
            <Button
              onClick={handleGoogleSignin}
              label={
                <div className="flex justify-center items-center">
                  <GoogleIcon width={24} height={24} />
                  &nbsp;&nbsp;Sign In with Google
                </div>
              }
              type="button"
              fullWidth
            />
            <div className="mt-10 flex justify-center">
              <Link className="link-text" to="/register">
                Don't have an account yet? Sign Up
              </Link>
            </div>
          </div>
        </form>
      </div>
      {loading ? <LoadingView /> : null}
    </div>
  );
};
