import { ICartItem, IVendor } from '@models';
import { ActionTypes } from '../types';
import {
  SetCartVendorAction,
  AddToCartAction,
  OverrideCartAction,
  UpdateCartItemAcion,
  RemoveCartItemAction,
} from './interface';

export const setCartVendor = (vendor?: IVendor | null): SetCartVendorAction => {
  return {
    type: ActionTypes.SET_CART_VENDOR,
    payload: vendor,
  };
};

export const addToCart = (cartItem: ICartItem): AddToCartAction => {
  return {
    type: ActionTypes.ADD_TO_CART,
    payload: cartItem,
  };
};

export const overrideCart = (cartItems: ICartItem[]): OverrideCartAction => {
  return {
    type: ActionTypes.OVERRIDE_CART,
    payload: cartItems,
  };
};

export const updateCartItem = (
  cartItem: ICartItem,
  cartIndex: number
): UpdateCartItemAcion => {
  return {
    type: ActionTypes.UPDATE_CART_ITEM,
    payload: {
      cartItem,
      cartIndex,
    },
  };
};

export const removeCartItem = (cartItem: ICartItem): RemoveCartItemAction => {
  return {
    type: ActionTypes.REMOVE_CART_ITEM,
    payload: cartItem,
  };
};

export * from './interface';
