import { ActionTypes, Actions } from '../../actions/types';
import { CartState } from './interface';

const intialState: CartState = {
  cartVendor: null,
  cartItems: [],
};

const cartReducer = (
  state: CartState = intialState,
  action: Actions
): CartState => {
  switch (action.type) {
    case ActionTypes.ADD_TO_CART:
      return {
        ...state,
        cartItems: [...state.cartItems, action.payload],
      };
    case ActionTypes.SET_CART_VENDOR:
      return {
        ...state,
        cartVendor: action.payload,
      };
    case ActionTypes.OVERRIDE_CART:
      return {
        ...state,
        cartItems: action.payload,
      };
    case ActionTypes.UPDATE_CART_ITEM:
      const tempItems = [...state.cartItems];
      tempItems[action.payload.cartIndex] = { ...action.payload.cartItem };
      return {
        ...state,
        cartItems: tempItems,
      };
    case ActionTypes.REMOVE_CART_ITEM:
      const newItems = state.cartItems.filter(
        (item) => item.product.id !== action.payload.product.id
      );
      return {
        ...state,
        cartItems: [...newItems],
      };
    default:
      return state;
  }
};

export default cartReducer;
export * from './interface';
