import React from 'react';
import HttpsRedirect from 'react-https-redirect';
import { Provider } from 'react-redux';
import { MainRoutes } from '@routes/MainRoutes';
import store from './store/store';
import 'react-notifications-component/dist/theme.css';

const App: React.FC = () => {
  return (
    <HttpsRedirect>
      <Provider store={store}>
        <MainRoutes />
      </Provider>
    </HttpsRedirect>
  );
};

export default App;
