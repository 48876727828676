import React from 'react';

interface Props {
  id: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  placeholder?: string;
  fullWidth?: boolean;
  caption?: string;
  required?: boolean;
  type?: string;
  error?: string | null;
  disabled?: boolean;
}

export const Input: React.FC<Props> = ({
  id,
  value,
  onChange,
  onBlur,
  placeholder,
  fullWidth,
  caption,
  required,
  type = 'text',
  error,
  disabled = false,
}) => {
  return (
    <div className="relative pb-5">
      {caption ? (
        <div className="font-sans text-sm text-primary-90">{caption}</div>
      ) : null}
      <input
        id={id}
        required={required}
        placeholder={placeholder}
        type={type}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        className={`${
          fullWidth ? 'w-full' : ''
        } h-10 font-sans text-base text-black-90 outline-none border border-gray-500 px-4 rounded-lg`}
      />
      {error ? (
        <div className="absolute font-sans text-sm text-red-600 left-0 bottom-0">
          {error}
        </div>
      ) : null}
    </div>
  );
};
