import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FlowerCard } from '@components';
import { IVendorProduct } from '@models';

interface Props {
  product: IVendorProduct;
}

export const ProductCard: React.FC<Props> = ({ product }) => {
  const navigate = useNavigate();

  const goToProduct = () => {
    navigate(`/vendors/${product.vendorID}/${product.id}`);
  };

  return (
    <div
      className="flex p-2 md:p-4 rounded-lg cursor-pointer border border-transparent hover:shadow-lg hover:border-gray-300"
      onClick={goToProduct}
    >
      <div className="flex-1">
        <div className="font-sans font-semibold text-base md:text-lg text-black-100">
          {product.name}
        </div>
        <div className="font-sans text-base md:text-lg text-black-100 mt-2 md:mt-4 leading-6">
          {product.description}
        </div>
        <div className="font-sans font-semibold text-base md:text-lg text-black-100 mt-2 md:mt-4">{`$${product.price}`}</div>
      </div>
      <div className="w-32 lg:w-48 ml-2 md:ml-4">
        <FlowerCard productImage={product.photo} noMargin />
      </div>
    </div>
  );
};
