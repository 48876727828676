import { createSelector } from 'reselect';
import { RootState } from '@reducers/interface';
import { AuthState } from '@reducers/auth';

const authSelector = (state: RootState) => state.auth;

export const selectUser = createSelector(
  authSelector,
  (authState: AuthState) => authState.user
);

export const selectProfile = createSelector(
  authSelector,
  (authState: AuthState) => authState.profile
);
