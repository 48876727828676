import React from 'react';
import { EmailFormFields } from 'react-mailchimp-subscribe';
import { useFormik } from 'formik';
import * as Yup from 'yup';

interface Props {
  status: 'error' | 'success' | 'sending' | null;
  message: string | Error | null;
  subscribe: (data: EmailFormFields) => void;
}

export const Form: React.FC<Props> = ({ status, message, subscribe }) => {
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    }),
    onSubmit: (values) => {
      console.log('values ', values);
      subscribe({
        EMAIL: values.email,
      });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      {status === 'success' ? (
        <div className="mt-8 max-w-screen-md	mx-auto">
          <div
            className="font-sans text-2xl text-primary-90 text-center"
            /* @ts-ignore */
            dangerouslySetInnerHTML={{ __html: message }}
          ></div>
        </div>
      ) : (
        <div>
          <div className="flex mt-8 max-w-screen-md	mx-auto">
            <input
              className="flex-1 p-2 focus:outline-none font-sans text-base md:text-xl border-b border-primary-30"
              id="email"
              placeholder="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <button
              className="px-4 md:px-10 py-2 md:py-3 font-sans text-sm md:text-md font-bold uppercase text-white bg-primary-100 hover:text-black-100 tracking-widest rounded-full border border-primary-90 bg-white hover:bg-primary-90 ml-6 duration-200"
              type="submit"
              disabled={status === 'sending'}
            >
              {status === 'sending' ? 'Subscribing' : 'Subscribe'}
            </button>
          </div>
          <div className="max-w-screen-md	mx-auto">
            {formik.touched.email && formik.errors.email ? (
              <div className="font-sans text-sm text-red-600">
                {formik.errors.email}
              </div>
            ) : null}
            {status === 'error' ? (
              <div
                className="font-sans text-sm text-red-600"
                /* @ts-ignore */
                dangerouslySetInnerHTML={{ __html: message }}
              ></div>
            ) : null}
          </div>
        </div>
      )}
    </form>
  );
};
