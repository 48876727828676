import { devConfigs } from './dev';
import { prodConfigs } from './prod';

const getConfig = () => {
  const env = process.env.REACT_APP_NODE_ENV;
  console.log('env', env);
  return env === 'development' ? prodConfigs : prodConfigs;
};

const config = getConfig();

export { config };
