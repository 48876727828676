export const prodConfigs = {
  firebaseConfig: {
    apiKey: 'AIzaSyBeUXP-MSHe2p0gOlLJtO8a7q7AQUP_Uvw',
    authDomain: 'cadoh---production.firebaseapp.com',
    projectId: 'cadoh---production',
    storageBucket: 'cadoh---production.appspot.com',
    messagingSenderId: '170085268724',
    appId: '1:170085268724:web:66dad9e51a2f576721f2a1',
    measurementId: 'G-16CFBMCPF6',
  },
  GOOGLE_MAPS_API_KEY: 'AIzaSyDV21G3hfV8GpRtcRgUs8Oh8zx4ppPciEI',
  API_BASE_URL: 'https://cadoh-stripe-server.herokuapp.com/',
  SERVICE_FEE: 10,
  ESTIMATED_TAX_RATE: 0.13,
  STRIPE_PUBLIC_KEY:
    'pk_live_51IOP0jLBnrrfgyczNRSP75PXI8ViPV9Y1Fun2iSVRTWhcattBKg2bI3jmxOxViIDLllYLKMWbl3ZhPVDlSSEET2T00qN19LfrR',
};
