import React, { useMemo } from 'react';
import moment from 'moment';

interface Props {
  index: number;
  onClickDate: (dateIndex: number) => void;
}

export const DeliveryDateItem: React.FC<Props> = ({ index, onClickDate }) => {
  const currentHours = useMemo(() => {
    const now = new Date();
    return now.getHours();
  }, []);

  const handleClick = () => {
    onClickDate(index);
  };

  if (index === 0 && currentHours >= 11) {
    return null;
  }

  return (
    <div
      className="w-full text-center text-sm md:text-lg p-1 cursor-default select-none bg-primary-100 active"
      onClick={handleClick}
    >
      {index === 0
        ? 'Today'
        : `${moment().add(index, 'days').format('ddd').toUpperCase()} ${moment()
            .add(index, 'days')
            .format('MMM DD')}`}
    </div>
  );
};
