import React, { useEffect, useState } from 'react';
import { SearchHeader } from '@containers';
import { IVendor } from '@models';
import { getVendors } from '@services/vendor';

import {
  VendorLists,
  OrderSteps,
  Partners,
  OrderWay,
  Download,
  SubscribeForm,
} from './components';

export const Home: React.FC = () => {
  const [vendors, setVendors] = useState<IVendor[]>([]);

  const onGetVendors = (vendors: IVendor[]) => {
    setVendors(vendors);
  };

  useEffect(() => {
    const unSubscribe = getVendors(onGetVendors);
    return unSubscribe;
  }, []);

  return (
    <div>
      <SearchHeader />
      <OrderSteps />
      <VendorLists vendors={vendors} />
      <Partners vendors={vendors} />
      <OrderWay />
      <Download />
      <SubscribeForm />
    </div>
  );
};
