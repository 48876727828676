import { createSelector } from 'reselect';
import { RootState } from '@reducers/interface';
import { CheckoutState } from '@reducers/checkout';

const checkoutSelector = (state: RootState) => state.checkout;

export const selectDeliveryAddress = createSelector(
  checkoutSelector,
  (checkoutState: CheckoutState) => checkoutState.deliveryAddress
);

export const selectDeliveryTime = createSelector(
  checkoutSelector,
  (checkoutState: CheckoutState) => checkoutState.deliveryTime
);

export const selectSender = createSelector(
  checkoutSelector,
  (checkoutState: CheckoutState) => checkoutState.sender
);

export const selectReceiver = createSelector(
  checkoutSelector,
  (checkoutState: CheckoutState) => checkoutState.receiver
);

export const selectDeliveryInstructions = createSelector(
  checkoutSelector,
  (checkoutState: CheckoutState) => checkoutState.deliveryInstructions
);

export const selectCardNote = createSelector(
  checkoutSelector,
  (checkoutState: CheckoutState) => checkoutState.cardNote
);

export const selectSpecialInstructions = createSelector(
  checkoutSelector,
  (checkoutState: CheckoutState) => checkoutState.specialInstructions
);
