import { ActionTypes, Actions } from '../../actions/types';
import { AuthState } from './interface';

const intialState: AuthState = {
  user: null,
  profile: null,
};

const authReducer = (
  state: AuthState = intialState,
  action: Actions
): AuthState => {
  switch (action.type) {
    case ActionTypes.SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case ActionTypes.SET_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
    case ActionTypes.SET_STRIPE_CUSTOMER_ID: {
      return {
        ...state,
        profile: state.profile
          ? {
              ...state.profile,
              stripeCustomerID: action.payload,
            }
          : state.profile,
      };
    }
    default:
      return state;
  }
};

export default authReducer;
export * from './interface';
