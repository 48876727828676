import React from 'react';
import { Dialog } from '@headlessui/react';

interface Props {
  visible: boolean;
  title: string;
  description?: string;
  onClose: () => void;
  showOverlay?: boolean;
  showOk?: boolean;
  okText?: string;
  onClickOk?: () => void;
  showCancel?: boolean;
  cancelText?: string;
  onCancelClick?: () => void;
}

export const Alert: React.FC<Props> = ({
  visible,
  title,
  description,
  onClose,
  showOverlay,
  showOk = true,
  okText = 'Ok',
  onClickOk,
  showCancel = false,
  cancelText = 'Cancel',
  onCancelClick,
}) => {
  return (
    <Dialog
      open={visible}
      className="fixed inset-0 overflow-y-auto"
      onClose={onClose}
      style={{ zIndex: '9999' }}
    >
      <div className="min-h-screen px-4 text-center">
        {showOverlay && (
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-40" />
        )}
        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="inline-block h-screen align-middle" aria-hidden="true">
          &#8203;
        </span>
        <div className="inline-block w-full max-w-md p-4 md:p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
          <Dialog.Title
            as="h3"
            className="font-sans text-xl md:text-2xl font-bold leading-6 text-gray-900"
          >
            {title}
          </Dialog.Title>
          <div className="mt-2">
            <p className="font-sans text-sm text-gray-500">
              {description || ''}
            </p>
          </div>
          <div className="mt-6 flex justify-center">
            {showCancel && (
              <button
                type="button"
                className="inline-flex justify-center px-6 md:px-8 py-2 font-sans text-sm font-medium text-black-100 bg-white border border-transparent rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                onClick={onCancelClick}
              >
                {cancelText}
              </button>
            )}
            {showOk && (
              <button
                type="button"
                className="inline-flex justify-center px-6 md:px-8 py-2 font-sans text-sm font-medium text-white bg-primary-90 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                onClick={onClickOk}
              >
                {okText}
              </button>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};
