import React, { useEffect, useMemo, useState } from 'react';
import Slider from 'react-slick';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, QuantitySelector } from '@components';
import {
  addToCart,
  setCartVendor,
  overrideCart,
  updateCartItem,
} from '@actions/cart';
import { ICartItem, IVendor, IVendorProduct } from '@models';
import { getVendor, getVendorProduct } from '@services/vendor';
import { selectCartItems, selectCartVendor } from '@selectors';

export const Product: React.FC = () => {
  const { vendorId, productId } = useParams<'vendorId' | 'productId'>();
  const dispatch = useDispatch();
  const cartItems = useSelector(selectCartItems);
  const cartVendor = useSelector(selectCartVendor);
  const [vendor, setVendor] = useState<IVendor | null>(null);
  const [vendorProduct, setVendorProduct] = useState<IVendorProduct | null>(
    null
  );
  const [selectedPhoto, setSelectedPhoto] = useState<string | null>(null);
  const [quantity, setQuantity] = useState<number>(1);
  const [showResetOrderAlert, setShowResetOrderAlert] =
    useState<boolean>(false);

  const onGetVendor = (vendor: IVendor) => {
    setVendor(vendor);
  };

  const onGetVendorProduct = (vendorProduct: IVendorProduct) => {
    setVendorProduct(vendorProduct);
  };

  useEffect(() => {
    let unSubscribe;
    if (vendorId) {
      unSubscribe = getVendor(vendorId, onGetVendor);
    }
    return unSubscribe;
  }, [vendorId]);

  useEffect(() => {
    let unSubscribe;
    if (productId) {
      unSubscribe = getVendorProduct(productId, onGetVendorProduct);
    }
    return unSubscribe;
  }, [productId]);

  useEffect(() => {
    if (vendorProduct) {
      if (vendorProduct.photos.length) {
        setSelectedPhoto(vendorProduct.photos[0]);
      } else {
        setSelectedPhoto(vendorProduct.photo);
      }
    }
  }, [vendorProduct]);

  const sliderSettings = useMemo(() => {
    return {
      dots: false,
      infinite: (vendorProduct?.photos.length || 0) > 2,
      slidesToShow: 3,
      slidesToScroll: 3,
      cssEase: 'linear',
    };
  }, [vendorProduct]);

  const handleQuantityChange = (quantity: number) => {
    setQuantity(quantity);
  };

  const onAddToCart = () => {
    if (!vendor || !vendorProduct) {
      return;
    }
    if (cartVendor && cartVendor.id !== vendor.id) {
      setShowResetOrderAlert(true);
      return;
    }
    const existingItemIndex = cartItems.findIndex(
      (item) => item.product.id === vendorProduct.id
    );
    if (existingItemIndex !== -1) {
      let tempCart: ICartItem = { ...cartItems[existingItemIndex] };
      tempCart.quantity = tempCart.quantity + quantity;
      dispatch(updateCartItem(tempCart, existingItemIndex));
      dispatch(setCartVendor(vendor));
    } else {
      const item: ICartItem = {
        product: vendorProduct,
        quantity,
      };
      dispatch(addToCart(item));
      dispatch(setCartVendor(vendor));
    }
  };

  const closeResetOrderAlert = () => {
    setShowResetOrderAlert(false);
  };

  const resetOrder = () => {
    dispatch(setCartVendor(null));
    dispatch(overrideCart([]));
    setShowResetOrderAlert(false);
  };

  const renderVendorProduct = (product: IVendorProduct) => {
    return (
      <div className="pb-10 grid grid-cols-1 sm:grid-cols-2 gap-4 md:gap-8 lg:gap-12">
        <div>
          <div className="aspect-w-4 aspect-h-3">
            <img className="object-cover" src={selectedPhoto || ''} alt="" />
          </div>
          <div className="px-2 py-4">
            <Slider {...sliderSettings}>
              {product.photos.map((productImage) => (
                <div
                  key={productImage}
                  onClick={() => setSelectedPhoto(productImage)}
                >
                  <div className="mx-2">
                    <div className="aspect-w-4 aspect-h-4">
                      <img
                        className="cursor-pointer object-cover"
                        src={productImage}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <div className="py-2 md:py-4">
          <div className="font-sans font-bold text-xl md:text-2xl text-black-100">
            {product.name}
          </div>
          <div className="mt-2 md:mt-4">
            <div className="font-sans text-base md:text-lg text-black-90">
              {product.description}
            </div>
            <div className="font-sans text-base md:text-lg text-black-90 mt-4 md:mt-6">
              {product.subDescription}
            </div>
          </div>
          <div className="px-6 md:px-10 mt-8 md:mt-12">
            <div className="font-sans text-base md:text-lg text-black-90 text-center border-t border-primary-90 pt-4">
              You will be able to add a card note and special instructions when
              checking out.
            </div>
            <div className="flex justify-center mt-6">
              <QuantitySelector onChange={handleQuantityChange} />
            </div>
            <div className="flex justify-center mt-8">
              <Button
                onClick={onAddToCart}
                label={
                  <>
                    Add to Cart &nbsp;&nbsp;&nbsp;&nbsp; $
                    {(Number(product.price) * quantity).toFixed(2)}
                  </>
                }
                type="button"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="section">
      {vendor?.logoImage && (
        <div className="mb-10">
          <div className="w-60 mx-auto">
            <div className="aspect-w-4 aspect-h-4">
              <img className="object-contain" src={vendor.logoImage} alt="" />
            </div>
          </div>
        </div>
      )}
      {vendorProduct ? renderVendorProduct(vendorProduct) : null}
      <Alert
        visible={showResetOrderAlert}
        showOverlay
        title="Start new order?"
        description="You currently have an item from another vendor in your cart. Would you
          like to clear your existing cart and start a new one?"
        onClose={closeResetOrderAlert}
        showOk
        onClickOk={resetOrder}
        showCancel
        onCancelClick={closeResetOrderAlert}
      />
    </div>
  );
};
