import Polyline from '@mapbox/polyline';
import { AxiosInstance } from './api-config';
import { IDistance, ILocation, IVendor } from '@models';

export const getRoadDistance = async (
  start: ILocation,
  end: ILocation
): Promise<IDistance> => {
  return new Promise(async function (resolve, reject) {
    try {
      const { data } = await AxiosInstance.post('/get-road-distance', {
        origin: start,
        destination: end,
      });
      const rows = data.rows;
      if (!rows || rows.length < 1) {
        reject();
      }
      const elements = rows[0].elements;
      if (!elements || elements.length < 1) {
        reject();
      }
      resolve(elements[0]?.distance);
    } catch (error) {
      console.log(error);
      reject();
    }
  });
};

export const sortVendorByDistance = (vendor1: IVendor, vendor2: IVendor) => {
  if (vendor1.distance?.value > vendor2.distance?.value) {
    return 1;
  } else if (vendor1.distance?.value < vendor2.distance?.value) {
    return -1;
  } else {
    return 0;
  }
};

export const getETA = async (
  start: ILocation,
  end: ILocation
): Promise<number> => {
  return new Promise(async function (resolve, reject) {
    try {
      const { data } = await AxiosInstance.post('/get-road-distance', {
        origin: start,
        destination: end,
      });
      const rows = data.rows;
      if (!rows || rows.length < 1) {
        reject();
      }
      const elements = rows[0].elements;
      if (!elements || elements.length < 1) {
        reject();
      }
      resolve(elements[0]?.duration?.value);
    } catch (error) {
      console.log(error);
      reject();
    }
  });
};

export const getDirection = async (
  start: ILocation,
  end: ILocation
): Promise<any> => {
  return new Promise(async function (resolve, reject) {
    try {
      const { data } = await AxiosInstance.post('/get-direction', {
        origin: start,
        destination: end,
      });
      if (!data || data.length < 1) {
        reject();
      }
      if (!data.routes || !data.routes.length) {
        reject();
      }
      let points = Polyline.decode(data.routes[0]?.overview_polyline?.points);
      let coords: ILocation[] = points.map((point, index) => {
        return {
          latitude: point[0],
          longitude: point[1],
        } as ILocation;
      });
      resolve(coords);
    } catch (error) {
      console.log(error);
      reject();
    }
  });
};
