import { createSelector } from 'reselect';
import { RootState } from '@reducers/interface';
import { CartState } from '@reducers/cart';

const cartSelector = (state: RootState) => state.cart;

export const selectCartVendor = createSelector(
  cartSelector,
  (cartState: CartState) => cartState.cartVendor
);

export const selectCartItems = createSelector(
  cartSelector,
  (cartState: CartState) => cartState.cartItems
);
