import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AiOutlineMenu } from 'react-icons/ai';
import { Menu } from '@headlessui/react';
import { CartButton, SideMenu } from '@containers';
import { selectUser, selectProfile } from '@selectors';
import { signOut } from '@services/auth';
import { ReactComponent as UserIcon } from '@assets/icons/user.svg';
import LogoImage from '@assets/images/logo-image.png';
import LogoTitle from '@assets/images/logo-title.png';

export const Header: React.FC = () => {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const profile = useSelector(selectProfile);
  const [sideMenuVisible, setSideMenuVisible] = useState<boolean>(false);
  const [collapseMenuVisible, setCollapseMenuVisible] =
    useState<boolean>(false);

  const openSideMenu = () => {
    setSideMenuVisible(true);
  };

  const closeSideMenu = () => {
    setSideMenuVisible(false);
  };

  const handleSignOut = () => {
    signOut();
  };

  const goToOrderHistory = () => {
    navigate('/orders');
  };

  const goToProfile = () => {
    navigate('/profile');
  };

  const toggleMenu = () => {
    setCollapseMenuVisible((visible) => !visible);
  };

  return (
    <div className="fixed w-full bg-white z-30">
      <div className="relative shadow-md h-20 md:h-24">
        <div className="flex justify-center items-center px-4 h-full">
          <Link className="flex justify-center items-center" to="/">
            <img className="h-5 md:h-10" src={LogoImage} alt="Cadoh" />
            <img className="h-5 md:h-9 ml-4" src={LogoTitle} alt="Cadoh" />
          </Link>
        </div>
        <div
          className="absolute top-0 left-0 py-2 md:py-3 px-4 text-2xl block md:hidden cursor-pointer"
          onClick={toggleMenu}
        >
          <AiOutlineMenu />
        </div>
        <div
          className={`absolute top-20 left-0 right-0 bg-white p-4 font-sans text-md uppercase tracking-wider md:hidden ${
            collapseMenuVisible ? 'block' : 'hidden'
          }`}
        >
          <Link
            className="block p-2 bg-white hover:bg-primary-30 cursor-pointer"
            to="/about-us"
          >
            About Us
          </Link>
          <Link
            className="block p-2 bg-white hover:bg-primary-30 cursor-pointer"
            to="/contact"
          >
            Contact Us
          </Link>
          <Link
            className="block p-2 bg-white hover:bg-primary-30 cursor-pointer"
            to="/faq"
          >
            FAQ
          </Link>
        </div>
        <nav className="absolute top-0 right-0 py-2 md:py-3 pr-2 flex items-center">
          <Link
            to="/about-us"
            className="font-sans text-md uppercase tracking-wider mx-2 hidden md:block"
          >
            About Us
          </Link>
          <Link
            to="/contact"
            className="font-sans text-md uppercase tracking-wider mx-2 hidden md:block"
          >
            Contact Us
          </Link>
          <Link
            to="/faq"
            className="font-sans text-md uppercase tracking-wider mx-2 hidden md:block"
          >
            FAQ
          </Link>
          {user && profile ? (
            <Menu as="div" className="relative mx-2">
              <Menu.Button>
                <div className="w-6 h-6 bg-primary-100 rounded-full flex justify-center items-center font-sans text-white font-semibold">
                  {profile.firstName.length ? profile.firstName[0] : ''}
                </div>
              </Menu.Button>
              <Menu.Items className="absolute right-0 w-48 bg-white shadow-lg border border-primary-90 rounded-md px-4 mt-1">
                <Menu.Item>
                  <div
                    className="w-full font-sans text-base md:text-lg block my-2 cursor-pointer"
                    onClick={goToOrderHistory}
                  >
                    Order History
                  </div>
                </Menu.Item>
                <Menu.Item>
                  <div
                    className="w-full font-sans text-base md:text-lg block my-2 cursor-pointer"
                    onClick={goToProfile}
                  >
                    Profile
                  </div>
                </Menu.Item>
                <Menu.Item>
                  <div
                    className="w-full font-sans text-base md:text-lg block my-2 cursor-pointer"
                    onClick={handleSignOut}
                  >
                    Logout
                  </div>
                </Menu.Item>
              </Menu.Items>
            </Menu>
          ) : (
            <Link to="/login" className="mx-2">
              <UserIcon className="w-6 h-6" />
            </Link>
          )}
          <CartButton onClick={openSideMenu} />
        </nav>
        <SideMenu visible={sideMenuVisible} onClose={closeSideMenu} />
      </div>
    </div>
  );
};
