import React, { useEffect, useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';

interface Props {
  value?: number;
  minValue?: number;
  onChange: (quantity: number) => void;
}

export const QuantitySelector: React.FC<Props> = ({
  value = 1,
  minValue = 1,
  onChange,
}) => {
  const [quantity, setQuantity] = useState<number>(value);

  const decreaseQuantity = () => {
    setQuantity((prev) => Math.max(prev - 1, minValue));
  };

  const increaseQuantity = () => {
    setQuantity((prev) => prev + 1);
  };

  useEffect(() => {
    onChange(quantity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity]);

  return (
    <div className="w-20 md:w-24">
      <div className="flex justify-between items-center border border-primary-90 py-0 md:py-1 px-2 md:px-3 rounded-full">
        <FaMinus
          className="text-xs text-primary-90 cursor-pointer"
          onClick={decreaseQuantity}
        />
        <div className="text-base md:text-lg font-sans font-bold text-primary-90 mx-2">
          {quantity}
        </div>
        <FaPlus
          className="text-xs text-primary-90 cursor-pointer"
          onClick={increaseQuantity}
        />
      </div>
    </div>
  );
};
