import React from 'react';
import OrderWayImage from '@assets/images/order-way.png';
import OrderAreaImage from '@assets/images/order-area.png';

export const OrderWay: React.FC = () => {
  return (
    <div className="section grid grid-cols-1 sm:grid-cols-2 gap-10 md:gap-12">
      <div className="aspect-w-9 aspect-h-9">
        <img className="w-full h-full" src={OrderWayImage} alt="" />
      </div>
      <div className="flex flex-col justify-center">
        <h1 className="section-title text-center mb-4 md:mb-6">
          The easiest way to send flowers from local florists
        </h1>
        <div className="px-4">
          <div className="section-text text-center mb-4 md:mb-6">
            We are on a mission to connect people through local florists. Place
            your order for same day, next day, or some day in the future.
          </div>
          <div className="section-text text-center">
            Same day orders are available before 11 AM, to provide the florist
            adequate time to prepare the arrangement.
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center">
        <h1 className="section-title text-center mb-4 md:mb-6">
          Delivery Area
        </h1>
        <div className="px-4">
          <div className="section-text text-center mb-4 md:mb-6">
            We are currently offering flower delivery services in the city of
            Toronto, with any postal code that starts with an "M".
          </div>
          <div className="section-text text-center">
            Stay tuned for delivery expansion!
          </div>
        </div>
      </div>
      <div className="aspect-w-9 aspect-h-9">
        <img className="w-full h-full" src={OrderAreaImage} alt="" />
      </div>
    </div>
  );
};
