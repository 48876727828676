import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Input, LoadingView } from '@components';
import { PrivateRoute } from '@routes/PrivateRoute';
import { selectProfile } from '@selectors';
import { updateUserProfile } from '@services/auth';
import { showSnackBar } from '@utils';

export const Profile: React.FC = () => {
  const profile = useSelector(selectProfile);
  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('First Name is required'),
      lastName: Yup.string().required('Last Name is required'),
    }),
    onSubmit: (values) => {
      setLoading(true);
      if (profile) {
        updateUserProfile(profile?.id, formik.values).then((response) => {
          if (response.success) {
            showSnackBar({
              title: 'Success',
              message: 'Your profile is updated successfully!',
              type: 'success',
            });
          } else {
            showSnackBar({
              title: 'Error',
              message: 'Your profile is not updated.',
              type: 'danger',
            });
          }
          setLoading(false);
        });
      }
    },
  });

  useEffect(() => {
    if (profile) {
      formik.setFieldValue('firstName', profile.firstName);
      formik.setFieldValue('lastName', profile.lastName);
      formik.setFieldValue('phone', profile.phone);
      formik.setFieldValue('email', profile.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  return (
    <PrivateRoute>
      <div className="section">
        <div className="max-w-lg mx-auto pt-4 md:pt-10">
          <div className="section-title text-center">Account Information</div>
          <form onSubmit={formik.handleSubmit}>
            <div className="mt-4">
              <Input
                id="firstName"
                caption="First Name"
                fullWidth
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.firstName && formik.errors.firstName
                    ? formik.errors.firstName
                    : null
                }
              />
            </div>
            <div className="mt-4">
              <Input
                id="lastName"
                caption="Last Name"
                fullWidth
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.lastName && formik.errors.lastName
                    ? formik.errors.lastName
                    : null
                }
              />
            </div>
            <div className="mt-4">
              <Input
                id="phone"
                caption="Phone Number"
                fullWidth
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
            <div className="mt-4">
              <Input
                id="email"
                caption="Email Address"
                fullWidth
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="email"
                disabled
              />
            </div>
            <div className="px-20 py-6 md:py-10">
              <Button label="Save" type="submit" fullWidth />
            </div>
          </form>
        </div>
        {loading && <LoadingView />}
      </div>
    </PrivateRoute>
  );
};
