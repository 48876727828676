import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import 'firebase/compat/storage';
import 'firebase/compat/remote-config';
import { config } from '@configs';

const app = firebase.initializeApp(config.firebaseConfig);

export const db = firebase.firestore();

export const firebaseStorage = firebase.storage();

export const firebaseRemote = firebase.remoteConfig();
firebaseRemote.settings = {
  minimumFetchIntervalMillis: 3600000,
  fetchTimeoutMillis: 60000,
};
firebaseRemote.defaultConfig = {
  awesome_new_feature: 'enable',
};

export const firebaseAuth = firebase.auth;
export const authProviders = {
  google: new firebase.auth.GoogleAuthProvider(),
  apple: new firebase.auth.OAuthProvider('apple.com'),
};
export const firebaseFunctions = firebase.app().functions('us-central1');
