import React from 'react';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
import { IVendor } from '@models';
import { FlowerCard } from '@components';

interface Props {
  vendor: IVendor;
}

export const VendorItem: React.FC<Props> = ({ vendor }) => {
  const navigate = useNavigate();

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 6,
    slidesToScroll: 6,
    autoplay: false,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 319,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const goToVendor = () => {
    navigate(`/vendors/${vendor.id}`);
  };

  return (
    <div
      className="my-6 md:my-10 p-4 rounded-lg cursor-pointer border border-transparent hover:shadow-lg hover:border-gray-300"
      onClick={goToVendor}
    >
      <Slider {...settings}>
        {vendor.productPhotos?.map((img) => (
          <FlowerCard key={img} productImage={img} />
        ))}
      </Slider>
      <div className="mt-2 pl-2">
        <div className="font-sans font-bold text-lg md:text-xl text-black-100">
          {vendor.title}
        </div>
        <div className="font-sans text-base md:text-lg text-black-90">
          {vendor.distance.text}
        </div>
        <div className="font-sans text-base md:text-lg text-black-90">
          {vendor.subTitle}
        </div>
      </div>
    </div>
  );
};
