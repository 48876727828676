import React from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { Form } from './Form';

export const SubscribeForm: React.FC = () => {
  const url =
    'https://cadoh.us20.list-manage.com/subscribe/post?u=8a1606331d5f09216e12b0c2c&id=5d7fb43544';
  return (
    <div className="section">
      <h1 className="section-title text-center mb-4 md:mb-6">
        Subscribe to Cadoh
      </h1>
      <div className="font-sans text-base md:text-lg text-center">
        Want to stay up to date with Cadoh? Hear about delivery zone expansion?
        Subscribe to our mailing list!
      </div>
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <Form status={status} message={message} subscribe={subscribe} />
        )}
      />
    </div>
  );
};
