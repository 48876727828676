import { firebaseFunctions } from './firebase';

export const SEND_CONTACT_US_EMAIL = 'sendContactUsEmail';
export const SEND_ORDER_NOTIFICATION_TO_VENDOR = 'sendOrderNotification';
export const SEND_ORDER_EMAIL_TO_VENDOR = 'sendOrderEmailToVendor';
export const SEND_ORDER_EMAIL_TO_USER = 'sendOrderEmailToUser';

export const fetchFromFirebaseFunctions = async (
  method: string,
  payload: any
) => {
  const instance = firebaseFunctions.httpsCallable(method);
  try {
    const response = await instance(payload);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
};
