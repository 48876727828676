import React from 'react';

interface Props {
  time: string;
  onClickTime: () => void;
}

export const DeliveryTimeItem: React.FC<Props> = ({ time, onClickTime }) => {
  return (
    <div
      className="w-full text-center text-sm md:text-lg p-1 cursor-default select-none bg-primary-100 active"
      onClick={onClickTime}
    >
      {time}
    </div>
  );
};
