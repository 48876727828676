import React, { Fragment, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Popover, Transition } from '@headlessui/react';
import { useDispatch } from 'react-redux';
import { setDeliveryTime } from '@actions/checkout';
import { getASAPTime } from '@utils';
import { DeliveryDateItem } from '../DeliveryDateItem';
import { DeliveryTimeItem } from '../DeliveryTimeItem';

const availableDates = new Array(28).fill(0);
const timeOptions = [
  { label: '10AM - 12PM', value: 10 },
  { label: '12PM - 2PM', value: 12 },
  { label: '2PM - 4PM', value: 14 },
  { label: '4PM - 6PM', value: 16 },
];

export const DeliveryTimeContainer: React.FC = () => {
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState<number>(0);
  const [selectedTime, setSelectedTime] = useState<number | null>(null);
  const [dateSelected, setDateSelected] = useState<boolean>(false);

  const handleSelectDate = (dateIndex: number) => {
    setSelectedDate(dateIndex);
    setDateSelected(true);
  };

  const deliveryTimeSlots = useMemo(() => {
    const asapTime = getASAPTime();
    return timeOptions.filter((option) => {
      const newDeliveryTime = moment().add(selectedDate, 'days');
      newDeliveryTime.set('hour', option.value);
      newDeliveryTime.set('minute', 0);
      newDeliveryTime.set('second', 0);
      newDeliveryTime.set('millisecond', 0);
      if (newDeliveryTime.toDate() >= asapTime) {
        return true;
      } else {
        return false;
      }
    });
  }, [selectedDate]);

  const renderDates = () => {
    return availableDates.map((item, index) => (
      <DeliveryDateItem
        key={index}
        index={index}
        onClickDate={handleSelectDate}
      />
    ));
  };

  useEffect(() => {
    if (!selectedTime) {
      return;
    }
    const newDeliveryTime = moment().add(selectedDate, 'days');
    newDeliveryTime.set('hour', selectedTime);
    newDeliveryTime.set('minute', 0);
    newDeliveryTime.set('second', 0);
    newDeliveryTime.set('millisecond', 0);
    dispatch(setDeliveryTime(newDeliveryTime.toDate()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, selectedTime]);

  return (
    <Popover.Panel className="w-full absolute z-50">
      {({ close }) => (
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="absolute w-full mt-1 max-h-96 overflow-auto bg-primary-100 font-sans text-xl text-white rounded-md shadow-lg max-h-100 ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
            {!dateSelected
              ? renderDates()
              : deliveryTimeSlots.map((item, index) => (
                  <DeliveryTimeItem
                    key={index}
                    time={item.label}
                    onClickTime={() => {
                      setSelectedTime(item.value);
                      setDateSelected(false);
                      close();
                    }}
                  />
                ))}
          </div>
        </Transition>
      )}
    </Popover.Panel>
  );
};
