import { combineReducers } from 'redux';
import { RootState } from './interface';
import authReducer from './auth';
import cartReducer from './cart';
import checkoutReducer from './checkout';

export default combineReducers<RootState>({
  auth: authReducer,
  cart: cartReducer,
  checkout: checkoutReducer,
});

export * from './interface';
