import { IUser, IProfile } from '@models';
import { ActionTypes } from '../types';
import {
  SetUserAction,
  SetProfileAction,
  SetStripeCustomerIdAction,
} from './interface';

export const setUser = (user: IUser | null): SetUserAction => {
  return {
    type: ActionTypes.SET_USER,
    payload: user,
  };
};

export const setProfile = (profile: IProfile | null): SetProfileAction => {
  return {
    type: ActionTypes.SET_PROFILE,
    payload: profile,
  };
};

export const setStripeCustomerId = (
  stripeCustomerId: string
): SetStripeCustomerIdAction => {
  return {
    type: ActionTypes.SET_STRIPE_CUSTOMER_ID,
    payload: stripeCustomerId,
  };
};

export * from './interface';
