import React from 'react';
import { Outlet } from 'react-router-dom';

import { Header, Footer } from '@components';

export const LandingLayout: React.FC = () => {
  return (
    <div className="bg-background">
      <Header />
      <div className="z-20 pt-20 md:pt-24">
        <Outlet />
        <Footer />
      </div>
    </div>
  );
};
