import React from 'react';
import AboutCadohImage from '@assets/images/about-cadoh.jpg';
import Founder from '@assets/images/founder.jpg';

export const AboutUs: React.FC = () => {
  return (
    <div className="section pt-10 md:pt-20">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-10 md:gap-12">
        <img className="w-full" src={AboutCadohImage} alt="" />
        <div className="flex flex-col justify-center">
          <h1 className="section-title text-center mb-4 md:mb-6">
            Supporting local florists
          </h1>
          <div className="px-4">
            <div className="section-text text-center">
              It is important for us to work closely with our floral partners so
              that every Cadoh order placed is bringing positive value to them.
              We keep our partner service fee to a minimal and follow standard
              order cutoff protocols so florists are not overwhelmed. Further,
              we encourage florists to be creative with their flower
              arrangements - we celebrate each unique style that a florist
              brings to Cadoh. Our goal is to help take some of the load off by
              showcasing their store and providing an easy delivery and ordering
              experience for their customers.
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center">
          <h1 className="section-title text-center mb-4 md:mb-6">
            Why a flower delivery app
          </h1>
          <div className="px-4">
            <div className="section-text text-center">
              In March 2020, I set out to send my mom flowers for IWD. I wanted
              to order from a florist that was close to her address but found
              the experience tedious and unenjoyable. With the rise of big box
              flower retailers harming our local florists, I decided to create a
              platform that would improve the flower ordering experience while
              building a community that supports local and independent florists.
            </div>
            <div className="section-text text-center mt-4">
              Cadoh was born in 2021 with a mission to its customers to improve
              their flower delivery experience, all while supporting local.
              Sending flowers from a florist closest to the recipient should be
              simple, reliable, and enjoyable. We have streamlined the process
              for you by easily connecting you to local florists, having the
              ability to explore different styles and price points, and provide
              you the ability to track an order live. All on a platform built
              for florists that are serving our neighborhoods.
            </div>
          </div>
        </div>
        <div>
          <img className="w-full" src={Founder} alt="" />
          <div className="section-text text-center mt-2 text-black-900">
            Natalie, Cadoh's Founder
          </div>
        </div>
      </div>
    </div>
  );
};
