import React from 'react';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import AppStore from '@assets/images/app-store.png';

export const Download: React.FC = () => {
  return (
    <div className="bg-primary-100">
      <div className="section">
        <h1 className="section-title text-white text-center px-2">
          Find Cadoh on the App Store! (Google Play coming soon...)
        </h1>
        <a
          href="https://apps.apple.com/ca/app/cadoh/id1564193093"
          target="_blank"
          rel="noreferrer"
        >
          <img className="w-96 mx-auto" src={AppStore} alt="" />
        </a>
        <div className="flex justify-center mt-4 md:mt-6">
          <a
            href="https://www.facebook.com/cadohofficial-108278004882966"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebook className="mx-6" size={32} />
          </a>
          <a
            href="https://www.instagram.com/cadohofficial"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram className="mx-6" size={32} />
          </a>
        </div>
      </div>
    </div>
  );
};
