import * as authActions from './auth';
import * as cartActions from './cart';
import * as checkoutActions from './checkout';

export enum ActionTypes {
  SET_USER = 'SET_USER',
  SET_PROFILE = 'SET_PROFILE',
  SET_STRIPE_CUSTOMER_ID = 'SET_STRIPE_CUSTOMER_ID',
  SET_DELIVERY_ADDRESS = 'SET_DELIVERY_ADDRESS',
  SET_DELIVERY_TIME = 'SET_DELIVERY_TIME',
  SET_SENDER = 'SET_SENDER',
  SET_RECEIVER = 'SET_RECEIVER',
  SET_DELIVERY_INSTRUCTIONS = 'SET_DELIVERY_INSTRUCTIONS',
  SET_CARD_NOTE = 'SET_CARD_NOTE',
  SET_SPECIAL_INSTRUCTIONS = 'SET_SPECIAL_INSTRUCTIONS',
  ADD_UNIT_NUMBER = 'ADD_UNIT_NUMBER',
  SET_CART_VENDOR = 'SET_CART_VENDOR',
  ADD_TO_CART = 'ADD_TO_CART',
  OVERRIDE_CART = 'OVERRIDE_CART',
  UPDATE_CART_ITEM = 'UPDATE_CART_ITEM',
  REMOVE_CART_ITEM = 'REMOVE_CART_ITEM',
}

export type Actions =
  | authActions.SetUserAction
  | authActions.SetProfileAction
  | authActions.SetStripeCustomerIdAction
  | cartActions.SetCartVendorAction
  | cartActions.AddToCartAction
  | cartActions.OverrideCartAction
  | cartActions.UpdateCartItemAcion
  | cartActions.RemoveCartItemAction
  | checkoutActions.SetDeliveryAddressAction
  | checkoutActions.SetDeliveryTimeAction
  | checkoutActions.SetSenderAction
  | checkoutActions.SetReceiverAction
  | checkoutActions.SetDeliveryInstructionsAction
  | checkoutActions.SetCardNoteAction
  | checkoutActions.SetSpecialInstructionsAction
  | checkoutActions.AddUnitNumberAction;
