import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IDistance, IVendor, IVendorProduct } from '@models';
import { selectDeliveryAddress } from '@selectors';
import { getRoadDistance } from '@services/distance';
import { getVendor, getVendorProducts } from '@services/vendor';
import { ProductCard } from './components/ProductCard';

export const Vendor: React.FC = () => {
  const { vendorId } = useParams<'vendorId'>();
  const deliveryAddress = useSelector(selectDeliveryAddress);
  const [vendor, setVendor] = useState<IVendor | null>(null);
  const [products, setProducts] = useState<IVendorProduct[]>([]);
  const [distance, setDistance] = useState<IDistance | null>(null);

  const onGetVendor = (vendor: IVendor) => {
    setVendor(vendor);
  };

  const onGetProducts = (products: IVendorProduct[]) => {
    setProducts(products);
  };

  useEffect(() => {
    let unSubscribe;
    if (vendorId) {
      unSubscribe = getVendor(vendorId, onGetVendor);
    }
    return unSubscribe;
  }, [vendorId]);

  useEffect(() => {
    let unSubscribe;
    if (vendorId) {
      unSubscribe = getVendorProducts(vendorId, onGetProducts);
    }
    return unSubscribe;
  }, [vendorId]);

  useEffect(() => {
    if (vendor && deliveryAddress) {
      getRoadDistance(vendor.location, deliveryAddress.location).then(
        (distance) => setDistance(distance)
      );
    }
  }, [vendor, deliveryAddress]);

  return (
    <div className="section">
      {vendor && (
        <div>
          {vendor.logoImage && (
            <div className="w-60 mx-auto mb-10">
              <div className="aspect-w-4 aspect-h-4">
                <img className="object-contain" src={vendor.logoImage} alt="" />
              </div>
            </div>
          )}
          <div className="mb-2">
            <div className="font-sans font-bold text-xl md:text-2xl text-black-100">
              {vendor.title}
            </div>
            {distance && (
              <div className="font-sans text-base md:text-lg text-black-90">
                {distance.text}
              </div>
            )}
            <div className="font-sans text-base md:text-lg text-black-90">
              {vendor.subTitle}
            </div>
          </div>
        </div>
      )}
      <div className="py-2 grid grid-cols-1 sm:grid-cols-2 gap-4 md:gap-8 lg:gap-12">
        {products.map((product) => (
          <ProductCard key={product.id} product={product} />
        ))}
      </div>
    </div>
  );
};
