import React from 'react';

interface Props {
  productImage: string;
  noMargin?: boolean;
}

export const FlowerCard: React.FC<Props> = ({ productImage, noMargin }) => {
  return (
    <div className={noMargin ? '' : 'mr-2'}>
      <div className="aspect-w-9 aspect-h-9">
        <img src={productImage} className="w-full h-full object-cover" alt="" />
      </div>
    </div>
  );
};
