import React from 'react';
import FAQImage from '@assets/images/faq.jpg';

export const FAQ: React.FC = () => {
  return (
    <div className="section flex flex-col md:flex-row pt-10 md:pt-20">
      <div className="w-96 max-w-full p-4 md:p-6">
        <img src={FAQImage} alt="" />
      </div>
      <div className="flex-1 p-4 md:p-6">
        <div className="mb-6 md:mb-8">
          <h1 className="section-title mb-4 md:mb-7">
            How much is the delivery fee?
          </h1>
          <div className="section-text">
            Cadoh’s delivery fee (also known as a “Service Fee”) is a flat $10
            fee that is added to your total order. This fee helps us cover the
            cost of operating and servicing your order. Furthermore, it helps us
            reduce the charges on our partners so that each order is beneficial
            to their business.
          </div>
        </div>
        <div className="mb-6 md:mb-8">
          <h1 className="section-title mb-4 md:mb-7">
            What happens once I place my order?
          </h1>
          <div className="section-text">
            Once your order is placed, the florist is notified and asked to
            review the order in a timely manner. Only when they accept the order
            your card is charged by the order total. You are able to cancel your
            order before the florists accepts it.
          </div>
        </div>
        <div className="mb-6 md:mb-8">
          <h1 className="section-title mb-4 md:mb-7">
            Will I receive a notification when the order is delivered?
          </h1>
          <div className="section-text">
            Yes! You will receive an email and/or a notification when you have
            submitted the order, when the florist has approved your order, and
            when the order is delivered to the recipient.
          </div>
        </div>
        <div className="mb-6 md:mb-8">
          <h1 className="section-title mb-4 md:mb-7">
            How much does Cadoh charge the vendor?
          </h1>
          <div className="section-text">
            Cadoh charges 10% from the vendor. This helps cover the cost of
            connecting them to new customers and covering their delivery
            logistcs.
          </div>
        </div>
        <div className="mb-6 md:mb-8">
          <h1 className="section-title mb-4 md:mb-7">
            I made a mistake with my order details - what should I do?
          </h1>
          <div className="section-text">
            Let us know right away and we will most likely be able to change
            your details. Please email support@cadoh.ca or DM us on Instagram.
          </div>
        </div>
        <div className="mb-6 md:mb-8">
          <h1 className="section-title mb-4 md:mb-7">
            What area is Cadoh servicing right now?
          </h1>
          <div className="section-text">
            We are currently delivering flowers to any address that has a postal
            code which starts with the letter “M”. We hope that we can expand
            this soon - stay tuned!
          </div>
        </div>
        <div className="mb-6 md:mb-8">
          <h1 className="section-title mb-4 md:mb-7">
            When is the earliest that Cadoh can deliver?
          </h1>
          <div className="section-text">
            We work closely with vendors to ensure that your order can be
            fulfilled as early as possible while also not overloading them.
            Often times, vendors will accept same day orders if they are placed
            before 11 AM. If you place an order after 11AM, the next possible
            time for delivery is usually the next day at 10AM. Some vendors
            require 24-48 hours to process your order.
          </div>
        </div>
        <div className="mb-6 md:mb-8">
          <h1 className="section-title mb-4 md:mb-7">
            Am I able to enclose a note with my order?
          </h1>
          <div className="section-text">
            Of course! You’ll be able to add a standard card note at check-out.
            Please try to keep this note as concise as possible. Also, don’t
            forget to include your name so that the receiver knows who you are!
          </div>
        </div>
        <div className="mb-6 md:mb-8">
          <h1 className="section-title mb-4 md:mb-7">
            How do you enure safe delivery?
          </h1>
          <div className="section-text">
            We train our drivers to handle each order with the highest level of
            care. Further, we ask that you provide as much detail as possible
            when adding an address so that drivers are able to deliver items to
            the best of your or the recipients convenience.
          </div>
        </div>
        <div className="mb-6 md:mb-8">
          <h1 className="section-title mb-4 md:mb-7">
            Why can’t I tip the flower couriers?
          </h1>
          <div className="section-text">
            We used to have a tipping option but after much thought, we took
            this out as an option. We don’t want to rely on tips to pay our
            couriers a fair wage.
          </div>
        </div>
        <div className="mb-6 md:mb-8">
          <h1 className="section-title mb-4 md:mb-7">
            Is there something we didn't cover?
          </h1>
          <div className="section-text">
            Want to learn more about Cadoh's flower delivery and marketplace
            services? Do you have feedback on your Cadoh experience that you
            would like to share? Please feel free to reach out at{' '}
            <a className="link-text" href="mailto:support@cadoh.ca">
              support@cadoh.ca
            </a>{' '}
            and we’ll make sure to get back to you!
          </div>
        </div>
        <div className="section-text mt-10 md:mt-20">
          Photo Credits belong to j_aime on Instagram
        </div>
      </div>
    </div>
  );
};
