import React, { useEffect, useState } from 'react';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { Popover } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Alert, Button } from '@components';
import { config } from '@configs';
import { getPlace } from '@services/place';
import { IFormattedPlace, IGooglePlace } from '@models';
import { selectDeliveryAddress, selectDeliveryTime } from '@selectors';
import { setDeliveryAddress, setDeliveryTime } from '@actions/checkout';
import { getASAPTime, getDeliveryTimeString } from '@utils';

import { DeliveryTimeContainer } from './components/DeliveryTimeContainer';

interface Props {
  lite?: boolean;
}

export const SearchHeader: React.FC<Props> = ({ lite = false }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const deliveryAddress = useSelector(selectDeliveryAddress);
  const deliveryTime = useSelector(selectDeliveryTime);
  const [formattedPlace, setFormattedPlace] = useState<IFormattedPlace | null>(
    null
  );
  const [address, setAddress] = useState<string>(
    deliveryAddress?.description || ''
  );
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);
  const [predicts, setPredicts] = useState<any>([]);
  const { placePredictions, getPlacePredictions } = useGoogle({
    apiKey: config.GOOGLE_MAPS_API_KEY,
    debounce: 1000,
    /* @ts-ignore */
    options: {
      types: ['address'],
      componentRestrictions: { country: 'ca' },
    },
  });

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    getPlacePredictions({ input: e.target.value });
    setAddress(e.target.value);
  };

  useEffect(() => {
    setPredicts(placePredictions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placePredictions.length]);

  const handleSelectPlace = async (place: IGooglePlace) => {
    setAddress(place.description);
    setPredicts([]);
    const formattedPlaceResponse = await getPlace(place);
    if (formattedPlaceResponse.success) {
      if (formattedPlaceResponse.data.postalCode[0] !== 'M') {
        setDialogVisible(true);
        return;
      }
      setFormattedPlace(formattedPlaceResponse.data);
    }
  };

  const resetPlace = () => {
    setAddress('');
    setDialogVisible(false);
    setFormattedPlace(null);
  };

  const handleGo = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (deliveryTime && formattedPlace) {
      dispatch(setDeliveryAddress(formattedPlace));
      navigate('/vendors');
    }
  };

  useEffect(() => {
    if (!deliveryTime) {
      dispatch(setDeliveryTime(getASAPTime()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryTime]);

  useEffect(() => {
    if (deliveryAddress) {
      setFormattedPlace(deliveryAddress);
    }
  }, [deliveryAddress]);

  return (
    <div
      className={`flex flex-col px-4 ${
        lite ? 'h-28' : 'h-52 md:h-60'
      } bg-primary-30 justify-center items-center`}
    >
      {!lite && (
        <div>
          <div className="font-sans text-xl md:text-2xl text-center">
            Send one of a kind flower arrangements
          </div>
          <div className="font-sans text-xl md:text-2xl text-center">
            through local, independent florists in Toronto.
          </div>
        </div>
      )}
      <form onSubmit={handleGo}>
        <div className={`flex bg-white rounded-full ${lite ? '' : 'mt-5'}`}>
          <div className="flex-1 md:w-96 relative">
            <input
              className="w-full h-full focus:outline-none font-sans text-sm md:text-lg pl-3 md:pl-7 rounded-full"
              required
              placeholder="Enter an address"
              value={address}
              onChange={handleAddressChange}
            />
            {predicts.length ? (
              <div className="w-72 md:w-96 absolute mt-1 overflow-auto bg-white font-sans text-sm md:text-base text-black rounded-md shadow-lg max-h-100 ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                {placePredictions.map((place) => (
                  <div
                    key={place.description}
                    className="cursor-default p-1 pl-2 md:pl-7 hover:bg-gray-300"
                    onClick={() => handleSelectPlace(place)}
                  >
                    {place.description}
                  </div>
                ))}
              </div>
            ) : null}
          </div>
          <div className="flex-none mx-1 md:mx-4">
            <Popover className="relative h-full">
              <Popover.Button className="w-full h-full flex justify-center items-center font-sans text-sm md:text-lg text-black">
                {deliveryTime ? getDeliveryTimeString(deliveryTime) : ''}
              </Popover.Button>
              <DeliveryTimeContainer />
            </Popover>
          </div>
          <Button label="Go" type="submit" fullRounded />
        </div>
      </form>

      <Alert
        visible={dialogVisible}
        onClose={resetPlace}
        showOverlay
        title="Coming soon..."
        description="Unfortunately, it looks like Cadoh isn’t available in your area yet. We’ll make sure you’re the first to know when we launch near you."
        onClickOk={resetPlace}
      />
    </div>
  );
};
