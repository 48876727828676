import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import {
  GoogleMap,
  Marker,
  Polyline,
  useJsApiLoader,
} from '@react-google-maps/api';
import { Line } from 'rc-progress';
import { MdDirectionsCar, MdLocationOn } from 'react-icons/md';
import { Button } from '@components';
import { config } from '@configs';
import { ILocation, IOrder, IVendorProductMini } from '@models';
import { getOrder, deleteOrder } from '@services/order';
import { getDeliveryTimeString } from '@utils';
import OrderPrepareImage from '@assets/images/prepare.png';
import { getDirection, getETA } from '@services/distance';
import CarIcon from '@assets/icons/car-icon.png';
import DestinationIcon from '@assets/icons/destination-icon.png';

export const Order: React.FC = () => {
  const navigate = useNavigate();
  const { orderId } = useParams<'orderId'>();
  const [order, setOrder] = useState<IOrder | null>(null);
  const [eta, setEta] = useState<number>(0);
  const [routeCoordinates, setRouteCoordinates] = useState<ILocation[]>([
    { latitude: 43.66853316880996, longitude: -79.33693767198046 },
    { latitude: 43.66868644175851, longitude: -79.33606595409204 },
    { latitude: 43.66879703085809, longitude: -79.33565825834114 },
  ]);
  const [region, setRegion] = useState<any>({
    lat: 43.6580767015294,
    lng: -79.3464809712021,
  });

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: config.GOOGLE_MAPS_API_KEY,
  });

  const onGetOrder = (trackedOrder: IOrder) => {
    if (!_.isEqual(order, trackedOrder)) {
      setOrder(trackedOrder);
    }
  };

  useEffect(() => {
    let unSubscribe;
    if (orderId) {
      unSubscribe = getOrder(orderId, onGetOrder);
    }
    return unSubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const cancelOrder = (order: IOrder) => {
    deleteOrder(order.id, () => {
      goBack();
    });
  };

  const goBack = () => {
    navigate('/orders');
  };

  const renderProducts = (products: IVendorProductMini[]) => {
    return (
      <div className="mt-6 md:mt-10">
        {products.map((product) => (
          <div key={product.id} className="flex items-center mb-4 md:mb-6">
            <img
              className="w-48 md:w-60 h-32 md:h-40 object-cover"
              src={product.photo}
              alt=""
            />
            <div className="ml-6 md:ml-10 font-sans text-base text-black-900">{`${product.quantity} x ${product.name}`}</div>
            <div className="ml-6 md:ml-10 font-sans font-semibold text-base text-primary-90">{`$${product.price}`}</div>
          </div>
        ))}
      </div>
    );
  };

  const renderPriceTable = (order: IOrder) => {
    return (
      <div>
        <div className="flex justify-between align-items py-2 md:py-4 px-2 border-t border-primary-30">
          <div className="text-base md:text-lg font-sans text-black-900">
            Service Fee
          </div>
          <div className="text-base md:text-lg font-sans font-semibold text-black-900">
            {`$${order.serviceFee.toFixed(2)}`}
          </div>
        </div>
        <div className="py-4 border-t border-primary-30">
          <div className="bg-primary-30 p-2 mt-2 md:mt-4">
            <div className="flex justify-between align-items py-1">
              <div className="text-base md:text-lg font-sans text-black-900">
                Subtotal
              </div>
              <div className="text-base md:text-lg font-sans font-semibold text-black-900">
                {`$${order.subTotal.toFixed(2)}`}
              </div>
            </div>
            <div className="flex justify-between align-items py-1">
              <div className="text-base md:text-lg font-sans text-black-900">
                Service Fee
              </div>
              <div className="text-base md:text-lg font-sans font-semibold text-black-900">
                {`$${order.serviceFee.toFixed(2)}`}
              </div>
            </div>
            <div className="flex justify-between align-items py-1">
              <div className="text-base md:text-lg font-sans text-black-900">
                Estimated Tax
              </div>
              <div className="text-base md:text-lg font-sans font-semibold text-black-900">
                {`$${order.estimatedTax.toFixed(2)}`}
              </div>
            </div>
          </div>
          <div className="flex justify-between align-items p-2 mt-2 md:mt-4">
            <div className="text-base md:text-lg font-sans text-black-900">
              Order Total
            </div>
            <div className="text-base md:text-lg font-sans font-semibold text-black-900">
              {`$${order.totalPrice.toFixed(2)}`}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderOrderContent = (
    order: IOrder,
    showPrepareImage: boolean,
    showDeliveryTime: boolean = true,
    showOrderId: boolean = true
  ) => {
    return (
      <div className="py-4 md:py-8">
        {showDeliveryTime ? (
          <div className="font-sans font-semibold text-lg md:text-xl text-black-900">
            {getDeliveryTimeString(order.deliveryTime.toDate())}
          </div>
        ) : null}
        {showOrderId ? (
          <div className="font-sans text-base md:text-lg text-black-900">{`Order #${order.id}`}</div>
        ) : null}
        {showPrepareImage ? (
          <div className="flex justify-center py-4">
            <img
              className="w-48 md:w-60 w-48 md:h-60 object-contain"
              src={OrderPrepareImage}
              alt=""
            />
          </div>
        ) : null}
        <div>
          <div className="flex my-2 md:my-4">
            <div className="flex-1 font-sans text-base md:text-lg text-black-900">
              Address:
            </div>
            <div className="flex-1 font-sans text-base md:text-lg text-black-900 text-right">
              {order.deliveryAddress.description || order.deliveryAddress.line1}
            </div>
          </div>
          <div className="flex my-2 md:my-4">
            <div className="flex-1 font-sans text-base md:text-lg text-black-900">
              Unit Number:
            </div>
            <div className="flex-1 font-sans text-base md:text-lg text-black-900 text-right">
              {order.deliveryAddress.line2 || ''}
            </div>
          </div>
          <div className="flex my-2 md:my-4">
            <div className="flex-1 font-sans text-base md:text-lg text-black-900">
              Delivery Instructions:
            </div>
            <div className="flex-1 font-sans text-base md:text-lg text-black-900 text-right">
              {order.deliveryInstructions}
            </div>
          </div>
          <div className="flex my-2 md:my-4">
            <div className="flex-1 font-sans text-base md:text-lg text-black-900">
              Card Note:
            </div>
            <div className="flex-1 font-sans text-base md:text-lg text-black-900 text-right">
              {order.cardNote}
            </div>
          </div>
          <div className="flex my-2 md:my-4">
            <div className="flex-1 font-sans text-base md:text-lg text-black-900">
              Special Instructions:
            </div>
            <div className="flex-1 font-sans text-base md:text-lg text-black-900 text-right">
              {order.specialInstructions}
            </div>
          </div>
        </div>
        <div>{renderProducts(order.products)}</div>
        <div>{renderPriceTable(order)}</div>
      </div>
    );
  };

  const renderNewOrder = (order: IOrder) => {
    return (
      <div>
        <div className="px-4 py-4">
          <div className="font-sans font-semibold text-xl md:text-2xl text-black-900 text-center">
            Thank you for your order!
          </div>
          <div className="font-sans font-semibold text-xl md:text-2xl text-black-900 text-center mt-2 md:mt-4">
            Your order is now awaiting approval from the vendor...
          </div>
          <div className="font-sans text-base md:text-lg text-center mt-2 md:mt-4">
            You'll receive an email once your order is approved. This may take
            some time as the vendor reviews your order. You are able to cancel
            your order any time before.
          </div>
        </div>
        <div>{renderOrderContent(order, true)}</div>
        <div className="flex justify-center py-4">
          <Button label="Back" variant="text" type="button" onClick={goBack} />
          <div className="ml-8">
            <Button
              label="Cancel Order"
              type="button"
              onClick={() => cancelOrder(order)}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderVendorRejectedOrder = (order: IOrder) => {
    return (
      <div>
        <div className="px-4 py-4">
          <div className="font-sans font-semibold text-xl md:text-2xl text-black-900 text-center">
            Unfortunately, your order was not approved.
          </div>
          <div className="font-sans font-semibold text-xl md:text-2xl text-black-900 text-center mt-2 md:mt-4">
            The florist was unable to accept your order. We apologize for the
            inconvenience. Feel free to select a new vendor or modify your
            request. If you see a charge on your card, you will be refunded.
          </div>
        </div>
        <div>{renderOrderContent(order, false)}</div>
        <div className="flex justify-center py-4">
          <Button label="Back" type="button" onClick={goBack} />
        </div>
      </div>
    );
  };

  const renderPaymentFailureOrder = (order: IOrder) => {
    return (
      <div>
        <div className="px-4 py-4">
          <div className="font-sans font-semibold text-xl md:text-2xl text-black-900 text-center">
            Your payment was unsuccessful.
          </div>
          <div className="font-sans font-semibold text-xl md:text-2xl text-black-900 text-center mt-2 md:mt-4">
            We were unable to process your payment option. Please update with an
            alternative form of payment and place your order again. We apologize
            for the inconvenience.
          </div>
        </div>
        <div>{renderOrderContent(order, false)}</div>
        <div className="flex justify-center py-4">
          <Button label="Back" type="button" onClick={goBack} />
        </div>
      </div>
    );
  };

  const renderVendorAcceptedOrder = (order: IOrder) => {
    return (
      <div>
        <div className="px-4 py-4">
          <div className="font-sans font-semibold text-xl md:text-2xl text-black-900 text-center">
            Thank you for your order!
          </div>
          <div className="font-sans font-semibold text-xl md:text-2xl text-black-900 text-center mt-2 md:mt-4">
            Your order has been approved.
          </div>
        </div>
        <div>{renderOrderContent(order, true)}</div>
        <div className="flex justify-center py-4">
          <Button label="Back" type="button" onClick={goBack} />
        </div>
      </div>
    );
  };

  const containerStyle = {
    width: '340px',
    height: '340px',
  };

  const etaString = useMemo(() => {
    const pickedUpTime = order?.pickedUpTime?.toDate() || new Date();
    if (eta > 0) {
      pickedUpTime.setSeconds(pickedUpTime.getSeconds() + eta);
    }
    return pickedUpTime.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.pickedUpTime, eta]);

  const computeETA = async () => {
    if (order && order.driver && order.vendor && order.deliveryAddress) {
      const eta = await getETA(
        order.driver.location,
        order.deliveryAddress.location
      );
      setEta(eta);
      return;
    }
    setEta(0);
  };

  const centerMap = (pointOfInterests: ILocation[]) => {
    var maxLatitude = -400;
    var minLatitude = 400;
    var maxLongitude = -400;
    var minLongitude = 400;
    pointOfInterests.forEach((coordinate) => {
      if (maxLatitude < coordinate.latitude) {
        maxLatitude = coordinate.latitude;
      }
      if (minLatitude > coordinate.latitude) {
        minLatitude = coordinate.latitude;
      }
      if (maxLongitude < coordinate.longitude) {
        maxLongitude = coordinate.longitude;
      }
      if (minLongitude > coordinate.longitude) {
        minLongitude = coordinate.longitude;
      }
    });

    setRegion({
      lat: (maxLatitude + minLatitude) / 2,
      lng: (maxLongitude + minLongitude) / 2,
    });
  };

  const computePolylineCoordinates = async () => {
    if (!order) {
      // invalid order
      return;
    }
    const driver = order.driver;
    const address = order.deliveryAddress;

    if (
      (order.status === 'DRIVER_PENDING' ||
        order.status === 'DRIVER_ARRIVED') &&
      driver
    ) {
      // Driver has picked up the order from the vendor, and now they're delivering it to the shipping address
      const sourceCoordinate = {
        latitude: driver.location?.latitude,
        longitude: driver.location?.longitude,
      };
      const destLocation = address.location;
      const destCoordinate = {
        latitude: destLocation.latitude,
        longitude: destLocation.longitude,
      };
      const coordinates = await getDirection(sourceCoordinate, destCoordinate);
      const pointOfInterests = [
        sourceCoordinate,
        ...coordinates,
        destCoordinate,
      ];
      setRouteCoordinates(coordinates);
      centerMap(pointOfInterests);
    }
  };

  useEffect(() => {
    if (order?.status === 'DRIVER_PENDING') {
      computeETA();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.status]);

  useEffect(() => {
    if (
      order?.status === 'DRIVER_PENDING' ||
      order?.status === 'DRIVER_ARRIVED'
    ) {
      computePolylineCoordinates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    let secTimer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(secTimer);
  }, []);

  const progress = useMemo(() => {
    if (!eta || !order?.pickedUpTime) {
      return 0;
    }
    if (order?.pickedUpTime.toDate().getTime() > currentTime.getTime()) {
      return 0;
    }
    if (
      currentTime.getTime() >
      order?.pickedUpTime.toDate().getTime() + eta * 1000
    ) {
      return 1;
    }
    // const remainingSeconds =
    //   eta -
    //   (currentTime.getTime() - order?.pickedUpTime.toDate().getTime()) / 1000;
    const remainingSeconds =
      (currentTime.getTime() - order?.pickedUpTime.toDate().getTime()) / 1000;
    return remainingSeconds / eta;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTime, eta, order?.pickedUpTime]);

  const renderDriverPendingOrder = (order: IOrder) => {
    return (
      <div>
        <div className="px-4 py-4">
          <div className="font-sans font-semibold text-xl md:text-2xl text-black-900 text-center">
            Your order is on its way.
          </div>
          <div className="font-sans font-semibold text-xl md:text-2xl text-black-900 text-center mt-2 md:mt-4">
            {`Order #${order.id}`}
          </div>
        </div>
        <div className="mt-6 md:mt-8">
          <div className="flex justify-between">
            <div className="font-sans font-semibold text-lg md:text-xl text-black-900">
              {etaString}
            </div>
            <div className="font-sans font-semibold text-lg md:text-xl text-black-900">
              Estimated Arrival Time
            </div>
          </div>
          <div className="py-2 md:py-4">
            <Line percent={progress * 100} strokeColor="#5A78A1" />
          </div>
          <div className="mt-6 md:mt-8 flex justify-center">
            {isLoaded ? (
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={region}
                zoom={13}
                options={{
                  fullscreenControl: false,
                  mapTypeControl: false,
                  streetViewControl: false,
                }}
              >
                <>
                  <Polyline
                    path={routeCoordinates.map((route) => ({
                      lat: route.latitude,
                      lng: route.longitude,
                    }))}
                    options={{
                      strokeColor: '#5A78A1',
                      strokeWeight: 4,
                    }}
                  />
                  {order.driver !== undefined ? (
                    <Marker
                      icon={CarIcon}
                      position={{
                        lat: order.driver?.location?.latitude,
                        lng: order.driver?.location?.longitude,
                      }}
                      // label={order.driver.firstName}
                    ></Marker>
                  ) : null}
                  <Marker
                    icon={DestinationIcon}
                    position={{
                      lat: order.deliveryAddress.location.latitude,
                      lng: order.deliveryAddress.location.longitude,
                    }}
                    // label={order.deliveryAddress.place_id}
                  />
                </>
              </GoogleMap>
            ) : null}
          </div>
          <div className="flex justify-between mt-6 md:mt-8">
            <div className="font-sans font-semibold text-lg md:text-xl text-black-900">{`${
              order.driver?.firstName || 'Driver'
            } will be droping off your order`}</div>
            <div>
              <div className="flex-1 font-sans text-base md:text-lg text-black-900">
                Need to contact your driver?
              </div>
              <div className="flex-1 font-sans text-base md:text-lg text-black-900">
                {order.driver?.phone}
              </div>
            </div>
          </div>
        </div>
        <div>{renderOrderContent(order, false, false, false)}</div>
        <div className="flex justify-center py-4">
          <Button label="Back" type="button" onClick={goBack} />
        </div>
      </div>
    );
  };

  const renderCompletedOrder = (order: IOrder) => {
    return (
      <div>
        <div className="px-4 py-4">
          <div className="font-sans font-semibold text-2xl text-black-900 text-center">
            Your order is completed.
          </div>
        </div>
        <div>{renderOrderContent(order, false, false)}</div>
        <div className="mb-8">
          <div className="font-sans text-lg text-black-900 text-center">
            We hope you (or the recipient) enjoyed your order!
          </div>
          <div className="font-sans text-lg text-black-900 text-center">
            Have feedback on your experience? Feel free to email{' '}
            <a className="link-text" href="mailto:support@cadoh.ca">
              support@cadoh.ca
            </a>
          </div>
        </div>
        <div className="flex justify-center py-4">
          <Button label="Back" type="button" onClick={goBack} />
        </div>
      </div>
    );
  };

  if (!order) {
    return null;
  }

  return (
    <div className="section">
      <div className="max-w-screen-md mx-auto">
        {order.status === 'NEW'
          ? renderNewOrder(order)
          : order.status === 'VENDOR_REJECTED'
          ? renderVendorRejectedOrder(order)
          : order.status === 'PAYMENT_FAILURE'
          ? renderPaymentFailureOrder(order)
          : order.status === 'VENDOR_ACCEPTED' ||
            order.status === 'VENDOR_READY' ||
            order.status === 'DRIVER_ACCEPTED'
          ? renderVendorAcceptedOrder(order)
          : order.status === 'DRIVER_PENDING' ||
            order.status === 'DRIVER_ARRIVED'
          ? renderDriverPendingOrder(order)
          : order.status === 'ORDER_COMPLETED'
          ? renderCompletedOrder(order)
          : null}
      </div>
      <div className="section-text text-center mt-8">
        Need help with your order? Please contact{' '}
        <a className="link-text" href="mailto:support@cadoh.ca">
          support@cadoh.ca
        </a>{' '}
        and we’ll get back to you ASAP.
      </div>
    </div>
  );
};
