import React, { ReactNode } from 'react';

interface Props {
  label: string | ReactNode;
  variant?: 'fill' | 'text';
  type: 'button' | 'submit';
  onClick?: () => void;
  fullWidth?: boolean;
  disabled?: boolean;
  fullRounded?: boolean;
}

export const Button: React.FC<Props> = ({
  label,
  type,
  onClick,
  fullWidth,
  disabled = false,
  variant = 'fill',
  fullRounded = false,
}) => {
  return (
    <button
      className={`px-4 md:px-8 py-2 font-sans ${
        variant === 'fill' ? 'bg-primary-100' : ''
      } text-base md:text-xl ${
        variant === 'fill' ? 'text-white' : 'text-black-90'
      } ${fullRounded ? 'rounded-full' : 'rounded-xl'} hover:opacity-70 ${
        fullWidth ? 'w-full' : ''
      }`}
      disabled={disabled}
      type={type}
      onClick={onClick}
    >
      {label}
    </button>
  );
};
