import React from 'react';

interface Props {
  title: string;
  subTitle: string;
  image: string;
}

export const OrderStepCard: React.FC<Props> = ({ title, subTitle, image }) => {
  return (
    <div className="bg-primary-10 pt-3 md:pt-6 pb-6 md:pb-8">
      <div className="aspect-w-9 aspect-h-5">
        <img className="w-full h-full object-contain" src={image} alt="" />
      </div>
      <h2 className="px-4 my-2 md:my-6 font-sans text-xl md:text-2xl text-black-90 text-center">
        {title}
      </h2>
      <div className="section-text text-base md:text-lg px-4 text-center">
        {subTitle}
      </div>
    </div>
  );
};
