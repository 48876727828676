import React from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as CartIcon } from '@assets/icons/cart.svg';
import { selectCartItems } from '@selectors';

interface Props {
  onClick: () => void;
}

export const CartButton: React.FC<Props> = ({ onClick }) => {
  const cartItems = useSelector(selectCartItems);
  return (
    <button className="mx-2 relative" onClick={onClick}>
      <CartIcon className="w-6 h-6" />
      {cartItems.length ? (
        <div className="absolute bottom-0 w-full">
          <div className="w-5 h-5 flex justify-center items-center bg-primary-100 rounded-full mx-auto">
            <span className="font-sans font-semibold text-sm text-white">
              {cartItems.reduce((prev, next) => prev + next.quantity, 0)}
            </span>
          </div>
        </div>
      ) : null}
    </button>
  );
};
