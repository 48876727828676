import firebase from 'firebase/compat';
import { IVendor, IVendorProductMini } from './vendor';
import { ISender, IReceiver } from './checkout';
import { IDriver } from './driver';

export * from './cart';
export * from './checkout';
export * from './vendor';

export interface ILocation {
  latitude: number;
  longitude: number;
}

export interface IDistance {
  text: string;
  value: number;
}

export interface IGooglePlace {
  place_id: string;
  description: string;
}

export interface IGoogleAddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

export interface IGooglePlaceDetail {
  address_components: IGoogleAddressComponent[];
  geometry: {
    location: ILocation;
  };
}

export interface IFormattedPlace extends IGooglePlace {
  line1: string;
  line2?: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  location: ILocation;
}

export interface IUser extends firebase.User {}

export interface IProfile {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  location?: ILocation;
  phone: string;
  // profilePictureURL: string;
  referralCode?: string;
  referralLink?: string;
  createdAt: firebase.firestore.Timestamp;
  pushToken: string;
  stripeCustomerID?: string;
  userID: string;
}

export interface IOrder {
  cardNote: string;
  createdAt: firebase.firestore.Timestamp;
  deliveryAddress: IFormattedPlace;
  deliveryInstructions: string;
  deliveryTime: firebase.firestore.Timestamp;
  estimatedTax: number;
  id: string;
  paymentSource: string;
  stripeCustomerID: string;
  products: IVendorProductMini[];
  receiver: IReceiver;
  sender: IProfile | ISender;
  senderID: string;
  serviceFee: number;
  specialInstructions: string;
  subTotal: number;
  totalPrice: number;
  vendorID: string;
  vendor: IVendor;
  pickedUpTime?: firebase.firestore.Timestamp;
  driverID?: string;
  driver?: IDriver;
  status:
    | 'NEW'
    | 'VENDOR_REJECTED'
    | 'PAYMENT_FAILURE'
    | 'VENDOR_ACCEPTED'
    | 'VENDOR_READY'
    | 'DRIVER_ACCEPTED'
    | 'DRIVER_PENDING'
    | 'DRIVER_ARRIVED'
    | 'ORDER_COMPLETED';
}

export enum ErrorCode {
  passwordInUse = 'passwordInUse',
  badEmailFormat = 'badEmailFormat',
  emailInUse = 'emailInUse',
  invalidPassword = 'invalidPassword',
  noUser = 'noUser',
  rateLimited = 'rateLimited',
  serverError = 'serverError',
  appleAuthFailed = 'appleAuthFailed',
  googleAuthFailed = 'googleAuthFailed',
}

export interface INotification {
  title?: string;
  message?: string;
  type?: 'success' | 'danger' | 'info' | 'default' | 'warning';
}

export interface IApiResponse {
  success: boolean;
  data?: any;
  error?: ErrorCode;
}

export enum WalletType {
  applePay = 'APPLE_PAY',
  googlePay = 'GOOGLE_PAY',
}
